import { Image, Row, Col } from 'antd';
import { MobileView, BrowserView } from 'react-device-detect';

const BurnTreatment = () => {
  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu5/burn000.png)',
          }}
        >
          <Row align="middle">
            <Col span={12}></Col>
            <Col span={12}>
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>화상</b>클리닉
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>화상</b>의 치료
                </h2>
              </Col>
              <Col style={{ width: '400px' }}>
                <p className="subDesc">
                  <font>시흥드림항유외과</font>는{' '}
                  <font>화상 상처를 최소화</font>할 수 있도록 고민합니다.
                </p>
                <p className="subDesc">
                  화상 응급처치의 가장 기본은 <font>쿨링</font>입니다. 상온의
                  흐르는 물로 20분간 상처부위를 식힌 후 더이상 아무것도
                  하지마시고 내원하시기 바랍니다
                </p>
              </Col>
              <Col style={{ width: '600px' }}>
                <Image
                  preview={false}
                  src="/images/menu5/cont1_burn_circle02.png"
                />
              </Col>
            </Row>
          </div>
          <div className="cont_step">
            <Row justify="center">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng_w.png"
                />
                <h2>
                  효과적인 <b>화상치료</b>
                </h2>
              </Col>
              <Col style={{ display: 'flex', gap: '20px' }}>
                <div className="stepRec wide">
                  <span>1단계</span>
                  <Image
                    preview={false}
                    src="/images/menu5/burn_cont_005.png"
                  />
                  <p>
                    <b>쿨링치료</b>
                    화상 응급처지의 기본은 쿨링입니다. 화상 후 흐르는 상온의
                    물로 20분간 식히는 것으로 쿨링의 실행여부에 따라 치료결과의
                    차이가 극명하게 다릅니다.
                  </p>
                </div>
                <div className="stepRec wide">
                  <span>2단계</span>
                  <Image
                    preview={false}
                    src="/images/menu5/burn_cont_006.png"
                  />
                  <p>
                    <b>염증치료</b>
                    염증 반응을 최소화 하며 죽은 조직(=가피)이 있으면 변연절제술
                    및 가피절제술을 시행하여 새로운 조직이 잘 살아 날수 있게
                    환경을 조성하는 것이 중요합니다. 이를 위해 항생제와 항염증
                    작용을 하는 다양한 드레싱 제품을 사용하여 화상드레싱을 해야
                    합니다.
                  </p>
                </div>
                <div className="stepRec wide">
                  <span>3단계</span>
                  <Image
                    preview={false}
                    src="/images/menu5/burn_cont_007.png"
                  />
                  <p>
                    <b>재생치료</b>
                    화상재생 치료는 습윤드레싱, 그리고 다른 생물학적 드레싱으로
                    보다 인체친화적인 방법이 사용됩니다. 이러한 치료는
                    연고치료에 비해 화상수술(피부이식)이 줄어 들었으며 상처가
                    나은 뒤 생기는 흉터의 범위등이 예상보다 줄어드는 것을
                    보입니다.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="cont_step light">
            <img
              className="vDescImg"
              alt=""
              src="/images/menu/v_desc_eng.png"
            />
            <Row justify="center" align="middle">
              <Col span={4}>
                <h2>
                  <b>손상깊이</b>에 <br /> 따른 <br /> 화상치료
                </h2>
              </Col>
              <Col span={12} style={{ display: 'flex', gap: '40px' }}>
                <div className="stepImg">
                  <Image
                    preview={false}
                    src="/images/menu5/burn_cont_008.png"
                  />
                  <p className="desc">
                    <span>경도 화상</span>
                    응급처치로 흐르는 물에 화기를 식혀주고 이후 진통제 및
                    항생제를 복용하면서 오염 물을 제거해주는 드레싱을 시행하여
                    합니다. 필요시 파상풍 백신을 투여하기도 합니다.
                  </p>
                </div>
                <div className="stepImg">
                  <Image
                    preview={false}
                    src="/images/menu5/burn_cont_009.png"
                  />
                  <p className="desc">
                    <span>깊은 경도 화상</span>
                    항생제 크림과 피부재생에 필요한 물질을 포함한 연고 등 그리고
                    감염을 막는 은을 포함한 드레싱 제제를 사용하기도 합니다.
                    폼제제를 사용하여 습식 드레싱을 유지 합니다. 이것으로 안될
                    경우 피부이식 등을 고려해볼 수도 있습니다.
                  </p>
                </div>
                <div className="stepImg">
                  <Image
                    preview={false}
                    src="/images/menu5/burn_cont_010.png"
                  />
                  <p className="desc">
                    <span>중증화상</span>
                    탈수 및 쇼크가 와서 사망까지 이를 수 있어 다량의 수액 정맥
                    주입 부터 시작하여 산소 공급 등이 이뤄져야 합니다. 그 뒤로
                    피부 처치가 이뤄져야 되고 그 피부가 재생이 잘 되도록 충분한
                    영양공급이 있어야 합니다.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="cont_way"
            style={{
              backgroundImage: 'url(/images/menu/how_bg.png)',
              backgroundColor: '#d9d9d9',
            }}
          >
            <Row justify="center">
              <Col span={10} align="middle" style={{ paddingTop: '300px' }}>
                <h2>
                  <b>화상</b>은 초기에 <b>어떻게 응급처치를 하느냐</b>가
                  중요합니다.
                </h2>
                <p className="subDescRound">
                  <b>진단</b> 및 <b>치료</b>
                </p>
                <p className="desc">
                  <b>시흥드림항유외과</b>는 다양한 처지 재료를 보유하고 있어{' '}
                  <b>화상의 시기와 상처에 맞는 재료를 선택</b>하여 치료를 받으실
                  수 있습니다.
                </p>
                <p className="desc">
                  또한 진정제 투여가 필요한 경우 산소포화도, 심전도 등의
                  혈역학적 감시장치로 <b>지속모니터링 </b>하며 안전하게
                  치료합니다.
                </p>
              </Col>
              <Col span={24} align="middle">
                <img
                  className="descImgEnd"
                  alt=""
                  src="/images/menu/desc_eng_w.png"
                />
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top005m.png)',
          }}
        >
          <div className="descWrapM">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>화상</b>클리닉
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={20}>
              <h2>
                <b>화상</b>의 치료
              </h2>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={12}>
              <p className="subDesc">
                <font>시흥드림항유외과</font>는 <font>화상 상처를 최소화</font>
                할 수 있도록 고민합니다.
              </p>
              <p className="subDesc">
                화상 응급처치의 가장 기본은 <font>쿨링</font>입니다. 상온의
                흐르는 물로 20분간 상처부위를 식힌 후 더이상 아무것도 하지마시고
                내원하시기 바랍니다
              </p>
            </Col>
            <Col span={12}>
              <img className="cirImg" alt="" src="/images/mobile/cir009m.png" />
            </Col>
          </Row>
        </div>
        <div className="contStepM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                효과적인 <b>화상치료</b>
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <div className="stepRec wide">
                <span>1단계</span>
                <img alt="" src="/images/menu5/burn_cont_005.png" />
                <p>
                  <b>쿨링치료</b>
                  화상 응급처지의 기본은 쿨링입니다. 화상 후 흐르는 상온의 물로
                  20분간 식히는 것으로 쿨링의 실행여부에 따라 치료결과의 차이가
                  극명하게 다릅니다.
                </p>
              </div>
              <div className="stepRec wide">
                <span>2단계</span>
                <img alt="" src="/images/menu5/burn_cont_006.png" />
                <p>
                  <b>염증치료</b>
                  염증 반응을 최소화 하며 죽은 조직(=가피)이 있으면 변연절제술
                  및 가피절제술을 시행하여 새로운 조직이 잘 살아 날수 있게
                  환경을 조성하는 것이 중요합니다. 이를 위해 항생제와 항염증
                  작용을 하는 다양한 드레싱 제품을 사용하여 화상드레싱을 해야
                  합니다.
                </p>
              </div>
              <div className="stepRec wide">
                <span>3단계</span>
                <img alt="" src="/images/menu5/burn_cont_007.png" />
                <p>
                  <b>재생치료</b>
                  화상재생 치료는 습윤드레싱, 그리고 다른 생물학적 드레싱으로
                  보다 인체친화적인 방법이 사용됩니다. 이러한 치료는 연고치료에
                  비해 화상수술(피부이식)이 줄어 들었으며 상처가 나은 뒤 생기는
                  흉터의 범위등이 예상보다 줄어드는 것을 보입니다.
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="contWayM"
          style={{
            backgroundColor: '#fff',
          }}
        >
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>손상깊이</b>에 따른 화상치료
              </h2>
            </Col>
            <Col>
              <div className="stepImg">
                <img alt="" src="/images/menu5/burn_cont_008.png" />
                <p className="desc">
                  <span>경도 화상</span>
                  응급처치로 흐르는 물에 화기를 식혀주고 이후 진통제 및 항생제를
                  복용하면서 오염 물을 제거해주는 드레싱을 시행하여 합니다.
                  필요시 파상풍 백신을 투여하기도 합니다.
                </p>
              </div>
              <div className="stepImg">
                <img alt="" src="/images/menu5/burn_cont_009.png" />
                <p className="desc">
                  <span>깊은 경도 화상</span>
                  항생제 크림과 피부재생에 필요한 물질을 포함한 연고 등 그리고
                  감염을 막는 은을 포함한 드레싱 제제를 사용하기도 합니다.
                  폼제제를 사용하여 습식 드레싱을 유지 합니다. 이것으로 안될
                  경우 피부이식 등을 고려해볼 수도 있습니다.
                </p>
              </div>
              <div className="stepImg">
                <img alt="" src="/images/menu5/burn_cont_010.png" />
                <p className="desc">
                  <span>중증화상</span>
                  탈수 및 쇼크가 와서 사망까지 이를 수 있어 다량의 수액 정맥
                  주입 부터 시작하여 산소 공급 등이 이뤄져야 합니다. 그 뒤로
                  피부 처치가 이뤄져야 되고 그 피부가 재생이 잘 되도록 충분한
                  영양공급이 있어야 합니다.
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="contWayM"
          style={{
            backgroundColor: '#d9d9d9',
          }}
        >
          <img className="causeBg01" alt="" src="/images/mobile/bg002m.png" />
          <Row justify="center">
            <Col span={20}>
              <h2>
                <b>화상</b>은 초기에 <b>어떻게 응급처치를 하느냐</b>가
                중요합니다.
              </h2>
            </Col>
            <Col span={24}>
              <p className="subDescRound">
                <b>진단</b> 및 <b>치료</b>
              </p>
            </Col>
            <Col span={20}>
              <p className="subDesc">
                <b>시흥드림항유외과</b>는 다양한 처지 재료를 보유하고 있어{' '}
                <b>화상의 시기와 상처에 맞는 재료를 선택</b>하여 치료를 받으실
                수 있습니다.
              </p>
              <p className="subDesc">
                또한 진정제 투여가 필요한 경우 산소포화도, 심전도 등의 혈역학적
                감시장치로 <b>지속모니터링 </b>하며 안전하게 치료합니다.
              </p>
            </Col>
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
};
export default BurnTreatment;

import { isMobile } from 'react-device-detect'; //temp
const BoardSection = ({ title, description }) => {
  return (
    <div className={isMobile ? 'mobileSectionBoard' : 'browserSectionBoard'}>
      <h3>{title}</h3>
      <h4>{description}</h4>
    </div>
  );
};
export default BoardSection;

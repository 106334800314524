import { Image, Row, Col, List, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { MobileView, BrowserView } from 'react-device-detect';

const Colonoscopy = () => {
  const data = [
    '수면(진정) 내시경 검사 후 바로 운전이나 집중이 필요한 정교한 작업은 피하시기 바랍니다.',
    '대장용종을 제거한 후 출혈, 복부통증, 어지럼증, 구토, 고열 등이 동반되면 본원으로 연락하시거나 가까운 응급실을 찾으시기 바랍니다.',
    '검사 후 첫 식사는 죽과 같은 부드러운 음식을 드시기 바라며 한동안 자극적인 음식은 피하시기 바랍니다.',
  ];
  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu6/gas000.png)',
          }}
        >
          <Row align="middle">
            <Col span={12}></Col>
            <Col span={12}>
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>내시경</b>센터
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>대장내시경</b>이란?
                </h2>
              </Col>
              <Col style={{ width: '400px' }}>
                <p className="subDesc">
                  내시경을 통하여 항문과 직장 및 대장 내부를 관찰하는
                  검사입니다.
                </p>
                <p className="subDesc">
                  대장의 병변을 확인하고 조직검사 및 제거까지 시행함으로써 다른
                  검사(CT, 초음파)보다 정확한 검사입니다. 대장 용종(폴립)이
                  있으면 내시경을 통하여 절제할 수 있고 경우에 따라서는 출혈을
                  멈추게 하는 치료도 할 수 있습니다.
                </p>
              </Col>
              <Col style={{ width: '600px' }}>
                <Image
                  preview={false}
                  src="/images/menu6/cont1_col_circle01.png"
                />
              </Col>
            </Row>
          </div>
          <div
            className="cont_care"
            style={{
              backgroundImage: 'url(/images/menu6/care2_bg.png)',
            }}
          >
            <Row justify="center" align="middle">
              <Col span={10} align="middle">
                <img
                  alt=""
                  className="descImg"
                  src="/images/menu/desc_eng_w.png"
                />
                <h2>
                  시흥드림항유외과
                  <br />
                  <b>내시경</b>의<b> 특장점</b>
                </h2>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <div className="imgGroup recRotate">
                  <Image
                    preview={false}
                    src="/images/menu/cont1_bre_circle02.png"
                  />
                  <Image
                    preview={false}
                    src="/images/menu/cont1_bre_circle03_out.png"
                  />
                  <Image
                    preview={false}
                    src="/images/menu6/cont1_col_circle03_in.png"
                  />
                  <div className="txtGroup three">
                    <p className="subDesc txt01">
                      숙련된 외과전문의가 <br /> 직접 검사합니다.
                    </p>
                    <p className="subDesc txt02">
                      대학병원급 내시경 <br /> 장비로 진단합니다.
                    </p>
                    <p className="subDesc txt03">
                      검사 중 발견된 용종은 <br /> 바로 제거가 가능합니다.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="cont_way light"
            style={{
              backgroundImage: 'url(/images/menu6/way2_bg.png)',
              backgroundSize: 'cover',
            }}
          >
            <img
              className="vDescImg"
              alt=""
              src="/images/menu/v_desc_eng.png"
            />
            <Row justify="center" align="middle">
              <Col span={6} justify="end">
                <h2>
                  <b>대장내시경</b>
                  <br />
                  검사가 <br />
                  <b>필요한</b>경우
                </h2>
              </Col>
              <Col span={10}>
                <p className="recBlock color">
                  <span>
                    <b>01</b>
                    대장암의 가족력이 <br /> 있는 경우
                  </span>
                  <span>
                    <b>02</b>
                    복통, 또는 설사가 <br />
                    오래 지속되는 경우
                  </span>
                  <span>
                    <b>03</b>
                    배변습관의 변화
                  </span>
                </p>
                <p className="recBlock color">
                  <span>
                    <b>04</b>
                    체중감소가 있을 때
                  </span>
                  <span>
                    <b>05</b>
                    복부팽만, 구토 증상이 동반될 때
                  </span>
                </p>
                <p className="recBlock color">
                  <span>
                    <b>06</b>설사가 심해 탈수가 <br />
                    동반되는 경우
                  </span>
                  <span>
                    <b>07</b>
                    배변 시 항문으로 출혈이 있거나 대변 에 피가 섞여 나올 때
                  </span>
                </p>
                <p className="recBlock color">
                  <span>
                    <b>08</b>
                    대장암의 조기검진
                    <br /> (50대 이후 3년 마다 한번)
                  </span>
                  <span>
                    <b>09</b>
                    대장용종 절제 병력이 있으신 분<br />
                    (용종의 종류에 따라 1-2년 간격으로 시행)
                  </span>
                </p>
              </Col>
            </Row>
          </div>
          <div className="cont_step">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng_w.png"
                />
                <h2>
                  <b>대장내시경</b>검사순서
                </h2>
              </Col>
              <Col span={24}>
                <Row justify="center">
                  <Col
                    style={{ display: 'flex', gap: '20px', height: '350px' }}
                  >
                    <div className="stepRec short">
                      <span>1. 장청소</span>
                      <Image
                        preview={false}
                        src="/images/menu6/col_cont_001.png"
                      />
                      <p>
                        검사 직전 내시경 삽입으로 인한 통증을 줄이기 위해
                        구강마취제(스프레이)로 목 마취를 합니다.
                      </p>
                    </div>
                    <div className="stepRec short">
                      <span>2. 진정제 투여</span>
                      <Image
                        preview={false}
                        src="/images/menu6/gas_cont_002.png"
                      />
                      <p>
                        검사 중 가벼운 통증을 느낄 수 있는데 미리 진정제,
                        진통제를 주사하고 검사를 하면 통증이 미미합니다.
                      </p>
                    </div>
                    <div className="stepRec short">
                      <span>3. 내시경 검사</span>
                      <Image
                        preview={false}
                        src="/images/menu6/gas_cont_003.png"
                      />
                      <p>
                        대장내시경 검사는 카메라로 직접 대장을 보면서 검사를
                        하기 때문에 검사 후 바로 결과를 알 수 있으며 조직검사를
                        한 경우 약 일주일정도 후에 결과를 알 수 있습니다.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>대장용종</b>이란?
                </h2>
              </Col>
              <Col style={{ width: '400px' }}>
                <p className="subDesc">
                  용종은 마치 사마귀처럼 생긴 것으로, 대장 점막의 혹을
                  지칭합니다.
                </p>
                <p className="subDesc">
                  용종은 그냥 놔두면 대장암으로 발전할 수 있는 용종(선종)이 있기
                  때문 에 내시경검사 도중에 용종이 발견되면 제거를 하고
                  조직검사로 확인 해야 합니다.
                </p>
                <p className="subDesc">
                  용종이 발견되었던 분은 정기적으로 대장내시경 검사를 받는 게
                  좋습니다. 대장내시경 검사는 일반적으로 3년에 한 번 정도 하는
                  것이 좋으나, 용종이 선종으로 나온 경우 1년 후에 다시 받는 것을
                  권장합니다.
                </p>
              </Col>
              <Col style={{ width: '600px' }}>
                <Image
                  preview={false}
                  src="/images/menu6/cont1_col_circle02.png"
                />
              </Col>
            </Row>
          </div>
          <div className="cont_notice">
            <Row justify="center">
              <Col span={24} align="middle">
                <h2>
                  <b>대장내시경</b> 후 주의사항
                </h2>
              </Col>
              <Col>
                <div className="noticeRec">
                  <List
                    bordered
                    style={{ letterSpacing: 0 }}
                    dataSource={data}
                    renderItem={(item) => (
                      <List.Item>
                        <Typography.Text mark>
                          <CheckOutlined />
                        </Typography.Text>
                        <div style={{ paddingLeft: '-150px' }}>{item}</div>
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top007m.png)',
          }}
        >
          <div className="descWrapM">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>내시경</b>센터
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>대장내시경</b>이란?
              </h2>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={14}>
              <p className="subDesc">
                내시경을 통하여 항문과 직장 및 대장 내부를 관찰하는 검사입니다.
                대장의 병변을 확인하고 조직검사 및 제거까지 시행함으로써 다른
                검사(CT, 초음파)보다 정확한 검사입니다. 대장 용종(폴립)이 있으면
                내시경을 통하여 절제할 수 있고 경우에 따라서는 출혈을 멈추게
                하는 치료도 할 수 있습니다.
              </p>
            </Col>
            <Col span={10}>
              <img className="cirImg" alt="" src="/images/mobile/cir011m.png" />
            </Col>
          </Row>
        </div>
        <div className="contCareM">
          <Row justify="center" align="middle">
            <Col span={24}>
              <img
                className="descImg"
                alt=""
                src="/images/menu/desc_eng_w.png"
              />
            </Col>
            <Col span={24}>
              <h2>
                시흥드림항유외과
                <br />
                <b>내시경</b>의<b> 특장점</b>
              </h2>
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  <div
                    className="careCir"
                    style={{
                      backgroundImage: 'url(/images/mobile/care_col_cir01.png)',
                    }}
                  >
                    <div
                      className="careCirOut"
                      style={{
                        backgroundImage: 'url(/images/menu/care_hem_cir02.png)',
                      }}
                    />
                    <div
                      className="careCirRotate"
                      style={{
                        backgroundImage:
                          'url(/images/menu/care_hem_cir03_rotate.png)',
                      }}
                    />
                  </div>
                </Col>
                <Col span={24} style={{ display: 'flex' }}>
                  <div className="careText">
                    <em>1.</em>
                    <p>대학병원급 내시경 장비로 진단합니다.</p>
                  </div>
                  <div className="careText">
                    <em>2.</em>
                    <p>검사 중 발견된 용종은 바로 제거가 가능합니다.</p>
                  </div>
                  <div className="careText">
                    <em>3.</em>
                    <p>숙련된 외과전문의가 직접 검사합니다.</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="contWayM color" style={{ backgroundColor: '#fff' }}>
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>대장내시경</b>검사가 <br />
                <b>필요한</b> 경우
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <p className="rec01">
                <span>
                  <b>01</b>대장암의 가족력이 있는 경우
                  <b>02</b>복통, 또는 설사가 오래 지속되는 경우
                  <b>03</b>배변습관의 변화
                </span>
              </p>
              <p className="rec03">
                <span>
                  <b>04</b>배변 시 항문으로 출혈이 있거나 대변 에 피가 섞여 나올
                  때<b>05</b>대장암의 조기검진 (50대 이후 3년 마다 한번)
                  <b>06</b>대장용종 절제 병력이 있으신 분 (용종의 종류에 따라
                  1-2년 간격으로 시행)
                </span>
              </p>
              <p className="rec02">
                <span>
                  <b>07</b>체중감소가 있을 때<b>08</b>복부팽만, 구토 증상이
                  동반될 때<b>09</b>설사가 심해 탈수가 동반되는 경우
                </span>
              </p>
            </Col>
          </Row>
        </div>
        <div className="contStepM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>대장내시경</b>검사순서
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <div className="stepRec short">
                <span>1. 장청소</span>
                <img alt="" src="/images/menu6/col_cont_001.png" />
                <p>
                  검사 직전 내시경 삽입으로 인한 통증을 줄이기 위해
                  구강마취제(스프레이)로 목 마취를 합니다.
                </p>
              </div>
              <div className="stepRec short">
                <span>2. 진정제 투여</span>
                <img alt="" src="/images/menu6/gas_cont_002.png" />
                <p>
                  검사 중 가벼운 통증을 느낄 수 있는데 미리 진정제, 진통제를
                  주사하고 검사를 하면 통증이 미미합니다.
                </p>
              </div>
              <div className="stepRec short">
                <span>3. 내시경 검사</span>
                <img alt="" src="/images/menu6/gas_cont_003.png" />
                <p>
                  대장내시경 검사는 카메라로 직접 대장을 보면서 검사를 하기
                  때문에 검사 후 바로 결과를 알 수 있으며 조직검사를 한 경우 약
                  일주일정도 후에 결과를 알 수 있습니다.
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>대장용종</b>이란?
              </h2>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={14}>
              <p className="subDesc">
                용종은 마치 사마귀처럼 생긴 것으로, 대장 점막의 혹을 지칭합니다.
                용종은 그냥 놔두면 대장암으로 발전할 수 있는 용종(선종)이 있기
                때문 에 내시경검사 도중에 용종이 발견되면 제거를 하고 조직검사로
                확인 해야 합니다. 용종이 발견되었던 분은 정기적으로 대장내시경
                검사를 받는 게 좋습니다. 대장내시경 검사는 일반적으로 3년에 한
                번 정도 하는 것이 좋으나, 용종이 선종으로 나온 경우 1년 후에
                다시 받는 것을 권장합니다.
              </p>
            </Col>
            <Col span={10}>
              <img className="cirImg" alt="" src="/images/mobile/cir012m.png" />
            </Col>
          </Row>
        </div>
        <div className="contNoticeM">
          <Row justify="center" align="middle">
            <Col span={24}>
              <h2>
                <b>대장내시경</b> 주의사항
              </h2>
            </Col>
            <Col>
              <div className="noticeRec">
                <List
                  bordered
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item>
                      <Typography.Text mark>
                        <CheckOutlined />
                      </Typography.Text>
                      {item}
                    </List.Item>
                  )}
                />
              </div>
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
};
export default Colonoscopy;

import { useState } from 'react';
import {
  Button,
  List,
  Comment,
  ConfigProvider,
  Input,
  Switch,
  Form,
  Popconfirm,
} from 'antd';

import { MessageFilled, LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { postComment, putComment, deleteComment } from '../../api/atree-api';
import { isMobile } from 'react-device-detect';
const { TextArea } = Input;

const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <MessageFilled style={{ fontSize: 20 }} />
    <p>등록된 댓글이 없습니다.</p>
  </div>
);

const BoardComment = ({
  isEdit,
  isComment,
  boardId,
  comments,
  setComments,
}) => {
  const [parentId, setParentId] = useState(null);
  const [commentId, setCommentId] = useState(null);
  const [commentForm] = Form.useForm();

  const handleClickCommentCancel = () => {
    setParentId();
    setCommentId();
  };
  const handleClickCommentDelete = (commentId) => {
    deleteComment(commentId).then((content) => {
      refrashCommentEdit(comments, commentId, { content, deleted: true });
      setComments([...comments]);
    });
  };
  const handleClickCommentSave = (values) => {
    if (commentId) {
      putComment(commentId, values).then((updated) => {
        refrashCommentEdit(comments, commentId, {
          content: values.content,
          updated,
        });
        setComments([...comments]);
        setCommentId();
      });
    } else {
      postComment(boardId, { parentId, ...values }).then((data) => {
        const comment = { ...data, editable: true };
        if (data.parentId === 0) {
          setComments([...comments, comment]);
        } else {
          refrashCommentAdd(comments, comment);
          setComments([...comments]);
        }
        commentForm.resetFields();
        setParentId();
      });
    }
  };

  const refrashCommentAdd = (arr = [], comment) => {
    for (const data of arr) {
      if (data.id === comment.parentId) {
        if (data.children) {
          data.children.push(comment);
        } else {
          data.children = [comment];
        }
        return true;
      }
      if (data.children && refrashCommentAdd(data.children, comment)) {
        return true;
      }
    }
    return false;
  };

  const refrashCommentEdit = (arr = [], commentId, comment) => {
    for (const data of arr) {
      if (data.id === commentId) {
        Object.keys(comment).forEach((key) => {
          data[key] = comment[key];
        });
        data.updated = comment.updated;
        return true;
      }
      if (
        data.children &&
        refrashCommentEdit(data.children, commentId, comment)
      ) {
        return true;
      }
    }
    return false;
  };

  const handleClickReply = (id) => {
    setCommentId();
    setParentId(id);
    commentForm.resetFields();
  };
  const handleClickEdit = (id, content, secret) => {
    setParentId();
    setCommentId(id);
    commentForm.setFieldsValue({ content, secret });
  };

  const CommentArea = ({ secret = false, content }) => (
    <div>
      <Form.Item
        style={{ margin: 0 }}
        name="secret"
        label="비밀글"
        valuePropName="checked"
        value={secret}
      >
        <Switch />
      </Form.Item>

      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Form.Item
          name="content"
          value={content}
          style={{ margin: 0, width: '100%' }}
        >
          <TextArea rows={5} placeholder="내용" />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Button type="primary" htmlType="submit" style={{ margin: '10px' }}>
            등록
          </Button>
          <Button
            onClick={() => handleClickCommentCancel()}
            style={{ margin: '10px' }}
          >
            취소
          </Button>
        </div>
      </div>
    </div>
  );

  const RecursiveComment = (comments) => {
    return comments?.map(
      ({
        id,
        content,
        writer,
        children,
        created,
        updated,
        editable,
        deleted,
        secret,
      }) => (
        <Comment
          key={id}
          actions={
            deleted
              ? undefined
              : editable
              ? [
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => handleClickReply(id)}
                  >
                    답글
                  </Button>,
                  <Button
                    type="text"
                    size="small"
                    onClick={() => handleClickEdit(id, content, secret)}
                  >
                    수정
                  </Button>,
                  <Popconfirm
                    placement="rightTop"
                    icon={<LoadingOutlined style={{ color: 'red' }} />}
                    title="삭제하시겠습니까?"
                    onConfirm={() => handleClickCommentDelete(id)}
                    okText="확인"
                    showCancel={false}
                  >
                    <Button type="text" size="small">
                      삭제
                    </Button>
                  </Popconfirm>,
                ]
              : isComment
              ? [
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => setParentId(id)}
                  >
                    답글
                  </Button>,
                ]
              : undefined
          }
          author={
            <>
              {writer} ({new Date(created).toLocaleString()})
            </>
          }
          content={
            <>
              <p>{content}</p>
              {id === commentId && (
                <CommentArea secret={secret} content={content} />
              )}
            </>
          }
        >
          {RecursiveComment(children)}
          {id === parentId && <CommentArea content="" />}
        </Comment>
      )
    );
  };

  return (
    <>
      <div className={isMobile ? 'boardCommentM' : 'boardComment'}>
        <Form form={commentForm} onFinish={handleClickCommentSave}>
          <div style={{ textAlign: 'right' }}>
            {isComment && (
              <Button type="primary" onClick={() => handleClickReply(0)}>
                댓글
              </Button>
            )}
            <Button>
              <Link to={'..'}>목록</Link>
            </Button>
            <br />
          </div>
          {parentId === 0 && <CommentArea content="" />}
          {RecursiveComment(comments)}
        </Form>
      </div>
      {!comments?.length && parentId == null && (
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <List />
        </ConfigProvider>
      )}
    </>
  );
};

export default BoardComment;

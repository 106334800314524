import styled from 'styled-components';
import { Button } from 'antd';

const ButtonTopStyle = styled(Button)`
  & {
    border-radius: 50%;
    color: white;
    width: 68px;
    height: 68px;
    line-height: 68px;
    text-align: center;
    background-color: #2f2f2f;
    border: 0;
    bottom: -98px;
    right: 8px;
  }
  &:hover,
  &:focus,
  &:active {
    color: #2f2f2f;
    background-color: #fff;
  }
`;

export default ButtonTopStyle;

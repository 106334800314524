import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, Descriptions, Row, Col } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { MobileView, BrowserView } from 'react-device-detect';
import { UserContext } from '../../context/UserContext';
import { withdraw } from '../../api/login-api';

export default function Member() {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const handleClickWithdraw = () => {
    withdraw().then(() => {
      sessionStorage.removeItem('user');
      setUser({});
      navigate('/');
    });
  };
  return (
    <>
      <BrowserView>
        <div className='browserSection content'>
          <h3>회원정보</h3>
          <h4>siheung dream Members</h4>
          <div className='cont_table'>
            <Row justify='center'>
              <Col span={12}>
                <Descriptions bordered>
                  <Descriptions.Item label='이름'>
                    <Avatar
                      style={{
                        backgroundColor: '#59bec9',
                        marginRight: '20px',
                      }}
                      icon={<UserOutlined />}
                    />
                    {user?.name}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions bordered>
                  <Descriptions.Item label='성별'>
                    {user?.gender === 'M' ? '남' : '여'}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions bordered>
                  <Descriptions.Item label='출생년도'>
                    {user?.birthyear}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions bordered>
                  <Descriptions.Item label='휴대전화번호'>
                    {user?.mobile ? user.mobile : '-'}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions bordered>
                  <Descriptions.Item label='E-mail'>
                    {user?.email ? user.email : '-'}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Button onClick={handleClickWithdraw}>탈퇴하기</Button>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div className='mobileSection'>
          <h3>회원정보</h3>
          <h4>siheung dream Members</h4>
          <div className='contTableM'>
            <Descriptions bordered>
              <Descriptions.Item label='이름'>
                <Avatar
                  style={{
                    backgroundColor: '#59bec9',
                    marginRight: '10px',
                  }}
                  icon={<UserOutlined />}
                />
                {user?.name}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions bordered>
              <Descriptions.Item label='성별'>
                {user?.gender === 'M' ? '남' : '여'}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions bordered>
              <Descriptions.Item label='출생년도'>
                {user?.birthyear}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions bordered>
              <Descriptions.Item label='휴대전화번호'>
                {user?.mobile ? user.mobile : '-'}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions bordered>
              <Descriptions.Item label='E-mail'>
                {user?.email ? user.email : '-'}
              </Descriptions.Item>
            </Descriptions>
          </div>
          <Button onClick={handleClickWithdraw}>탈퇴하기</Button>
        </div>
      </MobileView>
    </>
  );
}

import { List, Row, Col, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
const PageDescription = ({ data: { items, title = '' } }) => {
  return (
    <div className='contNoticeM'>
      <Row justify='center' align='middle'>
        <Col span={24}>
          <h2 dangerouslySetInnerHTML={{ __html: title }} />
        </Col>
        <Col>
          <div className='noticeRec'>
            <List
              bordered
              dataSource={items}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text mark>
                    <CheckOutlined />
                  </Typography.Text>
                  {item}
                </List.Item>
              )}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default PageDescription;

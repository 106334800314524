import { useContext, useLayoutEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import BoardSection from '../../components/board/BoardSection';
import { logout } from '../../api/login-api';

const Logout = () => {
  const { setUser } = useContext(UserContext);

  useLayoutEffect(() => {
    logout().then(() => {
      sessionStorage.removeItem('user');
      setUser({});
    });
  }, []);

  //temp
  return (
    <>
      <BoardSection title='로그아웃' description='로그아웃 되었습니다.' />
    </>
  );
};
export default Logout;

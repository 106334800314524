import { useState, useContext } from 'react';
import { Table, Button } from 'antd';
import { LockFilled } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import TableWrapper from '../../components-design/styled/table/TableWrapper';
import TableHeader from '../../components-design/styled/table/TableHeader';
import BoardSection from '../../components/board/BoardSection';
import { useNavigate } from 'react-router-dom';
import { getBoards } from '../../api/atree-api';
import { UserContext } from '../../context/UserContext';

const browserColumns = [
  {
    key: 1,
    title: '제목',
    dataIndex: 'title',
    render: (text, record) => (
      <span>
        {text}
        {record.secret ? <LockFilled /> : null}
      </span>
    ),
  },
  {
    key: 2,
    title: '작성자',
    dataIndex: 'writer',
    width: '200px',
    align: 'center',
  },
  {
    key: 3,
    title: '등록일',
    dataIndex: 'created',
    width: '150px',
    align: 'center',
    render: (d) => new Date(d).toLocaleDateString(),
  },
];

const mobileColumns = [
  {
    key: 1,
    title: '제목',
    dataIndex: 'title',
    render: (text, { secret, writer, created }) => (
      <>
        <span>
          {text}
          {secret ? <LockFilled /> : null}
        </span>
        <br />
        <p
          style={{ fontSize: '0.7em', marginBottom: '-7px', color: 'darkgray' }}
        >
          {writer} | {new Date(created).toLocaleDateString()}
        </p>
      </>
    ),
  },
];

const Board = ({ menuKey, data }) => {
  const { user } = useContext(UserContext);
  const isWrite = !data.auth || user?.isAdmin;
  const { title, description, pageSize } = data;
  const [key, setKey] = useState();
  const [boards, setBoards] = useState();

  const navigate = useNavigate();
  if ((!boards && !key) || key !== menuKey) {
    getBoards(menuKey)
      .then(setBoards)
      .then(() => setKey(menuKey));
  }

  const handleClick = () => {
    navigate('write', { state: { menuKey, title, description } });
  };

  const handleRowClick = ({ id, secret }, navigate) => {
    navigate(String(id), { state: { secret, title, description } });
  };

  const handleRow = (props) => ({
    onClick: () => handleRowClick(props, navigate),
  });
  let columns;
  let padding;
  if (isMobile) {
    columns = mobileColumns;
    padding = '2em';
  } else {
    columns = browserColumns;
    padding = '2em';
  }

  return (
    <>
      <BoardSection title={title} description={description} />
      <TableWrapper
        className={isMobile ? 'm' : 'b'}
        style={{
          width: '100%',
          maxWidth: '1200px',
          margin: '0 auto',
          padding,
        }}
      >
        <TableHeader>
          <span>
            Total
            <b>{boards?.length ?? 0}</b>건
          </span>
          {isWrite && (
            <Button type="primary" onClick={handleClick}>
              글쓰기
            </Button>
          )}
        </TableHeader>
        <Table
          columns={columns}
          dataSource={boards ?? []}
          pagination={{ pageSize }}
          onRow={handleRow}
          rowKey="id"
          size="small"
        ></Table>
      </TableWrapper>
    </>
  );
};

export default Board;

import { Row, Col } from 'antd';
import { MobileView, BrowserView } from 'react-device-detect';

const OfficeHour = () => {
  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu1/intro_bg.jpg)',
          }}
        >
          <Row align="middle">
            <Col span={24} align="middle">
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>병원</b>소개
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>진료시간</b>안내
                </h2>
                <img
                  alt=""
                  src="/images/menu1/cont1_intro_cir01.png"
                  style={{ width: '500px', marginBottom: '50px' }}
                />
              </Col>
              <Col align="middle">
                <div className="officeHour">
                  <dl>
                    <dt>월, 목</dt>
                    <dd>
                      오전 9시 ~ 오후 6시
                      <span>(점심시간 오후 1시 ~ 오후 2시)</span>
                    </dd>
                  </dl>
                  <dl>
                    <dt>화, 금</dt>
                    <dd>
                      오전 9시 ~ 오후 8시
                      <span>(점심시간 오후 1시 ~ 오후 2시)</span>
                    </dd>
                  </dl>
                  <dl>
                    <dt>수, 토</dt>
                    <dd>오전 9시 ~ 오후 1시</dd>
                  </dl>
                  <p className="subDesc">* 일요일 및 공휴일 휴진</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top002m.png)',
          }}
        >
          <div className="descWrapM center">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>병원</b>소개
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24} align="middle">
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
              <h2>
                <b>진료시간</b>안내
              </h2>
              <img
                alt=""
                src="/images/menu1/cont1_intro_cir01.png"
                style={{
                  width: 'calc(500 / 750 * 100vw)',
                  margin: 'calc(40 / 750 * 100vw)',
                }}
              />
            </Col>
            <Col span={24} align="middle">
              <div className="officeHour">
                <dl>
                  <dt>월, 목</dt>
                  <dd>
                    오전 9시 ~ 오후 6시
                    <span>(점심시간 오후 1시 ~ 오후 2시)</span>
                  </dd>
                </dl>
                <dl>
                  <dt>화, 금</dt>
                  <dd>
                    오전 9시 ~ 오후 8시
                    <span>(점심시간 오후 1시 ~ 오후 2시)</span>
                  </dd>
                </dl>
                <dl>
                  <dt>수, 토</dt>
                  <dd>오전 9시 ~ 오후 1시</dd>
                </dl>
                <p>* 일요일 및 공휴일 휴진</p>
              </div>
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
};
export default OfficeHour;

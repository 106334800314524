import { Row, Col } from 'antd';

import PageTable from '../page/PageTable';
import PageDescriptions from '../page/PageDescriptions';
import PageContNoticeM from '../page/PageContNoticeM';

const NmcMobile = ({ datas }) => {
  return (
    <>
      <div
        className="pageTopM animation"
        style={{
          backgroundImage: 'url(/images/mobile/top006m.png)',
        }}
      >
        <div className="descWrapM">
          <p className="desc">
            <b>시흥드림</b>항유외과
          </p>
          <h2>
            <b>건강검진</b>센터
          </h2>
        </div>
      </div>
      <div className="contIsitM">
        <Row justify="center">
          <Col span={24}>
            <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
          </Col>
          <Col span={24}>
            <h2>
              공단<b>건강검진</b>
            </h2>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={14}>
            <p className="desc">
              <font>시흥드림항유외과</font>에서는 국민건강보험공단에서 <br />
              실시하는 검진을 편안하게 받으실 수 있습니다.
            </p>
          </Col>
          <Col span={10}>
            <img className="cirImg" alt="" src="/images/mobile/cir010m.png" />
          </Col>
        </Row>
      </div>
      <PageTable data={datas[0]} />
      <PageTable data={datas[1]} />

      <div
        className="contWayM"
        style={{
          backgroundColor: '#d9d9d9',
        }}
      >
        <img className="causeBg01" alt="" src="/images/mobile/bg003m.png" />
        <Row justify="center">
          <Col span={20}>
            <h2>
              <b>암 검진</b>의 종류
            </h2>
          </Col>
          <Col span={24} align="middle">
            <p className="subDesc">
              <b>위암 검진 대상자</b>는 <b>전화로 예약</b>하거나{' '}
              <b>암 검진표</b> 또는 <br />
              <b>신분증</b>을 가지고 방문하여 <b>사전 예약</b>을 할 수 있습니다.
            </p>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={5}>
            <p className="desc">
              <b>위암검진</b>
            </p>
          </Col>
          <Col span={15} align="left">
            <p className="desc">
              만 40세 이상 남녀는 증상이 없어도 2년마다 위내시경 검사를
              받습니다.
              <br /> - 위암 선정특례적용자는 검진대상에서 유예.
              <br />
              (본인 검진희망 시 대상 추가등록 가능)
            </p>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={5}>
            <p className="desc">
              <b>대장암검진</b>
            </p>
          </Col>
          <Col span={15} align="left">
            <p className="desc">
              만 50세 이상 남녀는 1년마다 분변잠혈검사(FOBT)를 받은 후
              양성판정자는 대장내시경 검사를 받습니다. <br />- 대장암
              선정특례적용자 또는 국가대장암건진 대장내시경검사 수검자는
              검진대상에서 5년간 유예.
              <br /> (본인 검진희망 시 대상 추가등록 가능)
            </p>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={5}>
            <p className="desc">
              <b>간암검진</b>
            </p>
          </Col>
          <Col span={15} align="left">
            <p className="desc">
              만 40세 이상 남녀 중 간암발생 고위험군에 해당되는 아래 대상자는
              상반기 1회, 하반기 1회로 6개월마다 간 초음파검사와
              혈액검사(혈청알파태아단백검사)를 받습니다. <br />- 간암
              산정특례적용자 검진대상에서 유예. <br />
              (본인 검진희망 시 대상 추가등록가능)
            </p>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24} align="middle">
            <img className="descImg" alt="" src="/images/menu/desc_eng_w.png" />
          </Col>
        </Row>
      </div>
      <PageContNoticeM data={datas[2]} />
      <PageDescriptions data={datas[3]} />
    </>
  );
};
export default NmcMobile;

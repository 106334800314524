import { Anchor } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import MobileMenu from '../menu/MobileMenu';

const PRIMARY_COLOR = process.env.REACT_APP_TITLE_PRIMARY_COLOR;

const MobileHeader = ({ client, menus }) => {
  return (
    <Anchor>
      <div className='anchorM'>
        {/* <a href={`tel:${client.phon1}`}> */}
        {/* 일단 아이콘이라도 넣어놨어요. */}
        <a href={`tel:${client.phon1}`}>
          <PhoneOutlined />
        </a>
        <Link to='/' style={{ color: 'black' }}>
          <img src='/images/logo.png' alt={client?.client} />
        </Link>
      </div>
      {menus && (
        <div style={{ left: 0 }}>
          <MobileMenu menus={menus} />
        </div>
      )}
    </Anchor>
  );
};
export default MobileHeader;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import layer from '../../data/layer';
import notice from '../../data/notice';
import { isMobile } from 'react-device-detect';
import BoardSection from '../../components/board/BoardSection';
import FormWrapper from '../../components-design/styled/table/FormWrapper';
import {
  getNotices,
  getManagementLayers,
  putManagementLayer,
} from '../../api/atree-api';
import {
  Divider,
  DatePicker,
  Form,
  Input,
  Button,
  Switch,
  Select,
  Upload,
} from 'antd';
import { openLayer } from '../../components/modal/Layer';
import moment from 'moment';
import { UploadOutlined } from '@ant-design/icons';

const IMAGE_PATH = `${process.env.REACT_APP_ATREE_CLIENT_API_URL}/images`;
const { Option } = Select;

//Component
const AdminLayer = () => {
  //자고일어나서 이미지 있는 경우 아래에 제목만 뿌리면 끝.
  const [notices, setNotices] = useState();
  const [layers, setLayers] = useState();
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();
  const [first] = Form.useForm();
  const [second] = Form.useForm();
  const [third] = Form.useForm();

  const forms = [
    { name: 'First', form: first },
    { name: 'Second', form: second },
    { name: 'Third', form: third },
  ];

  if (!layers && !notices) {
    const test = Promise.all([getManagementLayers(), getNotices(80)]);
    test.then(([layers, notices]) => {
      layers.forEach((layer, idx) =>
        forms[idx].form.setFieldsValue({
          ...layer,
          targetId: layer.targetId ? layer.targetId : notices[0]?.id,
          startTime: layer.startTime ? moment(layer.startTime) : moment(),
          endTime: layer.endTime ? moment(layer.endTime) : moment(),
        }),
      );
      setLayers(layers);
      setNotices(notices);
    });
  }

  const children = notices?.map(({ id, title }) => (
    <Option key={id} value={id}>
      {title}
    </Option>
  ));

  const handleFinish = (values) => {
    const formData = new FormData();
    if (values.image?.fileList) {
      formData.append('image', values.image.fileList[0].originFileObj);
    }
    formData.append('startTime', values.startTime.toISOString());
    formData.append('endTime', values.endTime.toISOString());

    delete values.image;
    delete values.startTime;
    delete values.endTime;

    for (const [k, v] of Object.entries(values)) {
      formData.append(k, v);
    }
    formData.append('targetPath', '/notice');

    setUploading(true);
    putManagementLayer(values.id, formData)
      .then(({ ok, status }) => {
        if (ok) alert('저장되었습니다.');
        else if (status === 401) {
          alert('권한이 없습니다.');
        }
      })
      .catch((err) => console.log('Error ? ', err))
      .finally(() => setUploading(false));
  };

  const handleClickPreview = (values) => {
    openLayer({
      ...values,
      force: true,
      callback: () => {
        if (values.targetId) {
          navigate(`/notice/${values.targetId}`, {
            state: { ...notice[0], secret: false },
          });
        }
      },
    });
  };

  return (
    <>
      <BoardSection title={layer.title} description={layer.description} />
      <FormWrapper>
        {layers?.map((layer, idx) => {
          const { name, form } = forms[idx];
          const uploadProps = {
            name: 'image',
            beforeUpload: () => false,
            maxCount: 1,
          };
          if (layer.image.id) {
            const image = layer.image;
            uploadProps.defaultFileList = [
              {
                uid: image.id,
                name: image.name,
                status: 'done',
                response: '',
                url: `${IMAGE_PATH}/${image.id}`,
              },
            ];
          }

          return (
            <Form
              key={layer.id}
              form={form}
              onFinish={handleFinish}
              style={{
                width: isMobile ? '100%' : '1200px',
                margin: '0 auto',
                padding: '2em',
              }}
            >
              <Divider orientation='left' orientationMargin='0'>
                {name}:
              </Divider>
              <Form.Item hidden name='id' initialValue={1}>
                <Input />
              </Form.Item>
              <Form.Item label='Hidden' name='hidden' valuePropName='checked'>
                <Switch />
              </Form.Item>
              <Form.Item label='Title' name='title'>
                <Input placeholder='Title' />
              </Form.Item>
              <Form.Item label='Content' name='content'>
                <Input placeholder='Content' />
              </Form.Item>
              <Form.Item label='Target' name='targetId'>
                <Select placeholder='select your gender'>
                  {children ?? null}
                </Select>
              </Form.Item>
              <Form.Item label='Start date' name='startTime'>
                <DatePicker showTime />
              </Form.Item>
              <Form.Item label='End date' name='endTime'>
                <DatePicker showTime />
              </Form.Item>
              <Form.Item label='Layer image'>
                <Form.Item name='image' valuePropName='imageList' noStyle>
                  <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </Form.Item>
              </Form.Item>
              <Button type='primary' htmlType='submit' loading={uploading}>
                저장
              </Button>
              <Button onClick={() => handleClickPreview(form.getFieldsValue())}>
                미리보기
              </Button>
            </Form>
          );
        })}
      </FormWrapper>
    </>
  );
};
export default AdminLayer;

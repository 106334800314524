import { Row, Col, Image } from 'antd';
import { MobileView, BrowserView } from 'react-device-detect';

const PrecautionsSurgery = () => {
  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu2/hem000.png)',
          }}
        >
          <Row align="middle">
            <Col span={12}></Col>
            <Col span={12}>
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>대장항문</b>클리닉
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div
            className="cont_way bgImgDark"
            style={{
              backgroundImage: 'url(/images/menu/care_bg.png)',
              backgroundColor: '#0b3465',
            }}
          >
            <Row justify="center" align="middle">
              <Col span={6}>
                <h2>
                  수술 후
                  <br />
                  <b>주의사항</b>
                </h2>
              </Col>
              <Col span={14}>
                <p className="recBlock">
                  <b>수술 후 통증</b>
                  <span>
                    수술 후 마취가 깨면 통증이 있습니다. 본원에서 드린 약을
                    먹고도 통증 조절이 안되면 추가로 약(타이레놀, 게보린 등)
                    복용이 가능하며, 배변 후 먹지 마시고{' '}
                    <font>배변 30분전에 진통제를 복용</font>하세요.
                  </span>
                </p>
                <p className="recBlock">
                  <b>좌욕</b>
                  <span>
                    좌욕은 수술부위를 물에 담그거나 흐르는 물에 약하게 마사지를
                    하는 것으로 상처 소독 및 혈액순환을 좋게 하며 항문 괄약근
                    이완으로 통증을 완화시키는 역활을 합니다. 수술 후 일주일
                    동안은 하루 2번(기상 후, 취침 전) 그리고 배변 후에 해주며
                    시간은 각 3분 이내로, 하루 총 횟수는 5번 이상을 넘기지
                    않도록 합니다. 물 온도는 목욕탕의 열탕(42도)정도로 합니다.
                    수술 후 일주일 이후부터는 하루 2~3번으로 횟수를 줄이며
                    시간을 조금씩 늘려가면 됩니다. 하지만 1회당 10분이상은 하지
                    않도록 합니다. 좌욕시 소금물이나 소독약은 첨가 하지 않도록
                    하며 쪼그려 않지마세요.
                  </span>
                </p>
                <p className="recBlock">
                  <b>봉합사</b>
                  <span>
                    수술 후 2주내에 봉합사가 녹지 않고 항문에서 빠져 나올수
                    있으나, 이는 정상적인 과정이니 걱정하지마시고 억지로
                    잡아당기지는 마십시오
                  </span>
                </p>
                <p className="recBlock">
                  <b>배변</b>
                  <span>
                    절대로 배변시간이 5분을 넘기시면 안됩니다. 보통 3~4분내로
                    해결하시거나 아니면 바로 일어서야 합니다. 배변을 오래
                    하시거나 과도하게 힘을 주시면 항문주변 부어 더욱 통증을
                    유발하게 됩니다. 첫 배변시 거즈 또는 핏덩어리 같은 것이 나올
                    수 있습니다. 수술 후 지혈 등의 이유로 끼워 넣은 것이니
                    안심하셔도 됩니다.
                  </span>
                </p>
                <p className="recBlock">
                  <b>분비물 </b>
                  <span>
                    분비물 또한 수술 직후가 많이 나오나 2주까지 점점 줄어듭니다.
                    수술 직후 분비물이 많이 나오기 때문에 거즈등을 자주 교환해
                    주셔야 합니다.
                  </span>
                </p>
                <p className="recBlock">
                  <b> 운동 및 여행</b>
                  <span>
                    과격한 운동이나 장기여행은 수술 후 2주간은 하지 않습니다.
                  </span>
                </p>
                <p className="recBlock">
                  <b>음식 조절</b>
                  <span>
                    퇴원 후 정상적인 식사가 가능하나 매운 음식, 기름진 음식,
                    카페인이 많은 커피 같은 음식은 피해 주시고 금연, 금주를
                    3개월 이상 지켜주시기 바랍니다. 섬유질이 많은 음식, 유산균이
                    풍부한 음식 등은 도움이 되며, 식이섬유 복용은 수술 후
                    3개월까지 드시는 것을 권장합니다.
                  </span>
                </p>
                <p className="recBlock">
                  <b> 출혈</b>
                  <span>
                    수술 후 조금 묻어 나오거나 배변 후 한 두방울씩 떨어질수
                    있습니다. 정상적인 수술 후 경과입니다. 수술 후 2주이후에는
                    배변시 약간의 점혈이 묻어 나오는 정도가 될 것이며 보통 4주째
                    거의 보이지 않습니다. 수술 후 배변시 피가 설사처럼 쏟아지면
                    본원 또는 가까운 병원에서 응급조치를 받으셔야 합니다.
                  </span>
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url("/images/mobile/top001m.png")',
          }}
        >
          <div className="descWrapM">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>대장항문</b>클리닉
            </h2>
          </div>
        </div>
        <div className="contCareM">
          <Row justify="center" align="middle">
            <Col span={24}>
              <img
                className="descImg"
                alt=""
                src="/images/menu/desc_eng_w.png"
              />
              <h2>
                수술 후 <b>주의사항</b>
              </h2>
            </Col>
            <Col>
              <p className="recBlock">
                <span>
                  <b>수술 후 통증</b>
                  수술 후 마취가 깨면 통증이 있습니다. 본원에서 드린 약을 먹고도
                  통증 조절이 안되면 추가로 약(타이레놀, 게보린 등) 복용이
                  가능하며, 배변 후 먹지 마시고 배변 30분전에 진통제를
                  복용하세요.
                </span>
              </p>
              <p className="recBlock">
                <span>
                  <b>좌욕</b>
                  좌욕은 수술부위를 물에 담그거나 흐르는 물에 약하게 마사지를
                  하는 것으로 상처 소독 및 혈액순환을 좋게 하며 항문 괄약근
                  이완으로 통증을 완화시키는 역활을 합니다. 수술 후 일주일
                  동안은 하루 2번(기상 후, 취침 전) 그리고 배변 후에 해주며
                  시간은 각 3분 이내로, 하루 총 횟수는 5번 이상을 넘기지 않도록
                  합니다. 물 온도는 목욕탕의 열탕(42도)정도로 합니다. 수술 후
                  일주일 이후부터는 하루 2~3번으로 횟수를 줄이며 시간을 조금씩
                  늘려가면 됩니다. 하지만 1회당 10분이상은 하지 않도록 합니다.
                  좌욕시 소금물이나 소독약은 첨가 하지 않도록 하며 쪼그려
                  않지마세요.
                </span>
              </p>
              <p className="recBlock">
                <span>
                  <b>봉합사</b>
                  수술 후 2주내에 봉합사가 녹지 않고 항문에서 빠져 나올수
                  있으나, 이는 정상적인 과정이니 걱정하지마시고 억지로
                  잡아당기지는 마십시오
                </span>
              </p>
              <p className="recBlock">
                <span>
                  <b>배변</b>
                  절대로 배변시간이 5분을 넘기시면 안됩니다. 보통 3~4분내로
                  해결하시거나 아니면 바로 일어서야 합니다. 배변을 오래 하시거나
                  과도하게 힘을 주시면 항문주변 부어 더욱 통증을 유발하게
                  됩니다. 첫 배변시 거즈 또는 핏덩어리 같은 것이 나올 수
                  있습니다. 수술 후 지혈 등의 이유로 끼워 넣은 것이니 안심하셔도
                  됩니다.
                </span>
              </p>
              <p className="recBlock">
                <span>
                  <b> 운동 및 여행</b>
                  과격한 운동이나 장기여행은 수술 후 2주간은 하지 않습니다.
                </span>
              </p>
              <p className="recBlock">
                <span>
                  <b>음식 조절</b>
                  퇴원 후 정상적인 식사가 가능하나 매운 음식, 기름진 음식,
                  카페인이 많은 커피 같은 음식은 피해 주시고 금연, 금주를 3개월
                  이상 지켜주시기 바랍니다. 섬유질이 많은 음식, 유산균이 풍부한
                  음식 등은 도움이 되며, 식이섬유 복용은 수술 후 3개월까지
                  드시는 것을 권장합니다.
                </span>
              </p>
              <p className="recBlock">
                <span>
                  <b> 출혈</b>
                  수술 후 조금 묻어 나오거나 배변 후 한 두방울씩 떨어질수
                  있습니다. 정상적인 수술 후 경과입니다. 수술 후 2주이후에는
                  배변시 약간의 점혈이 묻어 나오는 정도가 될 것이며 보통 4주째
                  거의 보이지 않습니다. 수술 후 배변시 피가 설사처럼 쏟아지면
                  본원 또는 가까운 병원에서 응급조치를 받으셔야 합니다.
                </span>
              </p>
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
};
export default PrecautionsSurgery;

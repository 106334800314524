import { Row, Col, Image } from 'antd';
import { MobileView, BrowserView } from 'react-device-detect';

export default function AnalFissure() {
  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu2/hem000.png)',
          }}
        >
          <Row align="middle">
            <Col span={12}></Col>
            <Col span={12}>
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>대장항문</b>클리닉
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>치열</b>이란?
                </h2>
                <p className="desc">
                  <b>치열</b>은 <b>항문이 찢어지는 질환</b>입니다.
                </p>
              </Col>
              <Col style={{ width: '400px' }}>
                <p className="subDesc">
                  변비 혹은 설사, 잦은 배변습관 변화 그리고 항문의 협착이 원인이
                  되어 항문이 찢어지게 되면 출혈 및 통증이 발생합니다.
                </p>
                <p className="subDesc">
                  치열은 크게 급성치열 과 만성치열로 나뉘는데, 최근에 생겨
                  내괄약근의 섬유화가 진행되지 않은 상태를 급성 치열, 보통 3개월
                  이상 난치성 궤양을 동반하며 내괄약근의 섬유화가 진행되어
                  수술적 치료가 필요한 경우를 만성 치열이라고 합니다.
                </p>
              </Col>
              <Col style={{ width: '600px' }}>
                <Image
                  preview={false}
                  src="/images/menu2/cont1_ana_circle02.png"
                />
              </Col>
            </Row>
          </div>
          <div className="cont_step">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng_w.png"
                />
                <h2>
                  <b>치열</b>의 종류
                </h2>
              </Col>
              <Col span={24}>
                <Row justify="center">
                  <Col style={{ display: 'flex', gap: '20px' }}>
                    <div className="stepRec">
                      <span>만성치열</span>
                      <Image
                        preview={false}
                        src="/images/menu2/ana_cont_005.png"
                      />
                      <p>
                        3개월 이상 난치성 궤양을 동반하며 내괄약근의 섬유화가
                        진행되어 수술적 치료가 필요한 경우
                      </p>
                    </div>
                    <div className="stepRec">
                      <span>급성치열</span>
                      <Image
                        preview={false}
                        src="/images/menu2/ana_cont_006.png"
                      />
                      <p>최근에 생겨 내괄약근의 섬유화가 진행되지 않은 상태</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className="cont_care"
            style={{
              backgroundImage: 'url(/images/menu/care_bg.png)',
            }}
          >
            <Row justify="center">
              <Col span={24} align="middle">
                <img
                  alt=""
                  className="descImg"
                  src="/images/menu/desc_eng_w.png"
                />
                <h2>
                  <b>치열</b>의 <b>원인</b>
                </h2>
                <Row justify="center">
                  <Col
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '30px',
                    }}
                  >
                    <div className="careText">
                      <div>
                        <em>증상 1.</em>
                        <span>
                          <font>
                            <b>출혈</b>
                          </font>
                        </span>
                        <p>
                          배변 시나 배변 후에 선홍색의 출혈이 발생할 수
                          있습니다.
                        </p>
                      </div>
                      <div>
                        <em>증상 3.</em>
                        <span>
                          <font>
                            <b>가려움</b>
                          </font>
                        </span>
                        <p>
                          손상된 항문점막 부위에서 분비된 점액으로 가려움증을
                          동반하기도 합니다.
                        </p>
                      </div>
                    </div>
                    <div
                      className="careCir"
                      style={{
                        backgroundImage:
                          'url(/images/menu2/care_ana_cir02.png)',
                      }}
                    >
                      <div
                        className="careCirOut"
                        style={{
                          backgroundImage:
                            'url(/images/menu/care_hem_cir02.png)',
                        }}
                      />
                      <div
                        className="careCirRotate"
                        style={{
                          backgroundImage:
                            'url(/images/menu/care_hem_cir03_rotate.png)',
                        }}
                      />
                    </div>
                    <div className="careText">
                      <div>
                        <em>증상 2.</em>
                        <span>
                          <font>
                            <b>통증</b>
                          </font>
                        </span>
                        <p>
                          찢어지는 듯한 통증이 배변 시나 배변 후 얼마간
                          지속되기도 합니다.
                        </p>
                      </div>
                      <div>
                        <em>증상 4.</em>
                        <span>
                          <font>
                            <b>변비</b>
                          </font>
                        </span>
                        <p>
                          통증이 두려워 배변을 미루다가 변비가 더욱 심해집니다.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className="cont_cause"
            style={{
              backgroundImage: 'url(/images/menu2/cause_bg.png)',
              backgroundColor: '#ffe3d3',
            }}
          >
            <img
              className="vDescImg"
              alt=""
              src="/images/menu/v_desc_eng.png"
            />
            <Row justify="center">
              <Col span={16}>
                <Row justify="start" style={{ height: '500px' }}>
                  <Col>
                    <h2>
                      <b>치열</b>의 증상
                    </h2>
                  </Col>
                </Row>
                <Row
                  gutter={20}
                  justify="center"
                  align="top"
                  style={{ display: 'flex' }}
                >
                  <Col span={6}>
                    <div className="causeRec">
                      <span>변비</span>
                      <p>
                        변비 증상이 있는 경우 딱딱하고 굵은 변이 배출되는
                        과정에서 마찰로 인해 항문 피부조직이 찢어집니다.
                      </p>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="causeRec">
                      <span>괄약근 이상</span>
                      <p>
                        항문괄약근이 지나치게 수축되어 있어 항문통로가 좁아져
                        대변과 마찰하면서 발생합니다.
                      </p>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="causeRec">
                      <span>설사</span>
                      <p>
                        설사는 항문관 피부조직을 심하게 자극하는데 이 과정에서
                        항문관의 피부조직이 찢어지는 경우가 있습니다.
                      </p>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="causeRec">
                      <span>염증성 장 질환</span>
                      <p>
                        직장이나 항문 주위에 생긴 염증성 장 질환(크론병, 대장염
                        등)이 원인이 되는 경우가 있습니다. 반복되는 복통과
                        설사가 치열과 함께 나타날 경우 염증성 장 질환을 확인하기
                        위해 대장내시경이 필요합니다.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className="cont_way"
            style={{
              backgroundImage: 'url(/images/menu/how_bg.png)',
              backgroundColor: '#d9d9d9',
            }}
          >
            <Row justify="center">
              <Col span={12} align="middle" style={{ paddingTop: '300px' }}>
                <h2>
                  <b>치열</b>의 치료방법
                </h2>
              </Col>
            </Row>
            <Row justify="center">
              <Col style={{ display: 'flex' }}>
                <div className="wayCir wide">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon02.png)',
                    }}
                  ></span>
                  <p className="subDesc">
                    초기 급성 치열의 경우 대개 좌욕 및 약물치료로 1주일 내에
                    호전됩니다.
                  </p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                  <div
                    className="plusIcon"
                    style={{
                      backgroundImage: 'url(/images/menu/way_plus.png)',
                    }}
                  />
                </div>
                <div className="wayCir wide">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon08.png)',
                    }}
                  ></span>
                  <p className="subDesc">
                    보톡스(보툴리늄톡신)는 특정 세균에 추출한 신경독 단백질로
                    근육에 주사하면 근육을 움직이는 신경전달물질을 막아 근육을
                    이완시키는 역할을 합니다.
                  </p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                  <div
                    className="plusIcon"
                    style={{
                      backgroundImage: 'url(/images/menu/way_plus.png)',
                    }}
                  />
                </div>
                <div className="wayCir wide">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon05.png)',
                    }}
                  ></span>
                  <p className="subDesc">
                    적절한 양의 내괄약근의 일부만을 절개하는 수술로 수술 후
                    변실금 등의 합병증을 없도록 하기 위해서는 정확한 검사 및
                    의료진의 경험이 중요합니다. 수술 후 통증은 거의 없으며 바로
                    일상생활이 가능합니다.
                  </p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                </div>
              </Col>
              <Col span={24} align="middle">
                <img
                  className="descImgEnd"
                  alt=""
                  src="/images/menu/desc_eng_w.png"
                />
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top001m.png)',
          }}
        >
          <div className="descWrapM">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>대장항문</b>클리닉
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
              <h2>
                <b>치열</b>이란?
              </h2>
              <p className="desc">
                <b>치열</b>은 <b>항문이 찢어지는 질환</b>입니다.
              </p>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={14}>
              <p className="subDesc">
                변비 혹은 설사, 잦은 배변습관 변화 그리고 항문의 협착이 원인이
                되어 항문이 찢어지게 되면 출혈 및 통증이 발생합니다. <br />
                치열은 크게 급성치열 과 만성치열로 나뉘는데, 최근에 생겨
                내괄약근의 섬유화가 진행되지 않은 상태를 급성 치열, 보통 3개월
                이상 난치성 궤양을 동반하며 내괄약근의 섬유화가 진행되어 수술적
                치료가 필요한 경우를 만성 치열이라고 합니다.
              </p>
            </Col>
            <Col span={10}>
              <img className="cirImg" alt="" src="/images/mobile/cir003m.png" />
            </Col>
          </Row>
        </div>
        <div className="contStepM">
          <Row>
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>치열</b>의 종류
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <div className="stepRec wide">
                <span>급성치열</span>
                <img alt="" src="/images/menu2/ana_cont_005.png" />
                <p>
                  3개월 이상 난치성 궤양을 동반하며 내괄약근의 섬유화가 진행되어
                  수술적 치료가 필요한 경우
                </p>
              </div>
              <div className="stepRec wide">
                <span>만성치열</span>
                <img alt="" src="/images/menu2/ana_cont_006.png" />
                <p>최근에 생겨 내괄약근의 섬유화가 진행되지 않은 상태</p>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="contCauseM"
          style={{
            backgroundColor: '#ffe3d3',
          }}
        >
          <img className="causeBg01" alt="" src="/images/mobile/bg001m.jpg" />
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>치열</b>의 <b>증상</b>
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col style={{ display: 'flex' }}>
              <div className="causeRec">
                <span>변비</span>
                <p>
                  변비 증상이 있는 경우 딱딱하고 굵은 변이 배출되는 과정에서
                  마찰로 인해 항문 피부조직이 찢어집니다.
                </p>
              </div>
              <div className="causeRec">
                <span>괄약근 이상</span>
                <p>
                  항문괄약근이 지나치게 수축되어 있어 항문통로가 좁아져 대변과
                  마찰하면서 발생합니다.
                </p>
              </div>
            </Col>
            <Col style={{ display: 'flex' }}>
              <div className="causeRec">
                <span>설사</span>
                <p>
                  설사는 항문관 피부조직을 심하게 자극하는데 이 과정에서
                  항문관의 피부조직이 찢어지는 경우가 있습니다.
                </p>
              </div>
              <div className="causeRec">
                <span>염증성 장 질환</span>
                <p>
                  직장이나 항문 주위에 생긴 염증성 장 질환(크론병, 대장염 등)이
                  원인이 되는 경우가 있습니다. 반복되는 복통과 설사가 치열과
                  함께 나타날 경우 염증성 장 질환을 확인하기 위해 대장내시경이
                  필요합니다.
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="contWayM single"
          style={{
            backgroundColor: '#d9d9d9',
          }}
        >
          <img className="causeBg01" alt="" src="/images/mobile/bg002m.png" />
          <Row justify="center">
            <Col span={24}>
              <h2>
                <b>치열</b>의 치료방법
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <div className="wayCir">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon01.png)',
                  }}
                ></span>
                <p>
                  초기 급성 치열의 경우 대개 좌욕 및 약물치료로 1주일 내에
                  호전됩니다.
                </p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
              </div>
              <div className="wayCir">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon08.png)',
                  }}
                ></span>
                <p>
                  보톡스(보툴리늄톡신)는 특정 세균에 추출한 신경독 단백질로
                  근육에 주사하면 근육을 움직이는 신경전달물질을 막아 근육을
                  이완시키는 역할을 합니다.
                </p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
              </div>
              <div className="wayCir">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon05.png)',
                  }}
                ></span>
                <p>
                  적절한 양의 내괄약근의 일부만을 절개하는 수술로 수술 후 변실금
                  등의 합병증을 없도록 하기 위해서는 정확한 검사 및 의료진의
                  경험이 중요합니다. 수술 후 통증은 거의 없으며 바로 일상생활이
                  가능합니다.
                </p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
              </div>
            </Col>
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
          </Row>
        </div>
        <div className="contCareM">
          <Row justify="center" align="middle">
            <Col span={24}>
              <img
                className="descImg"
                alt=""
                src="/images/menu/desc_eng_w.png"
              />
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  <div
                    className="careCir"
                    style={{
                      backgroundImage: 'url(/images/menu2/care_ana_cir02.png)',
                    }}
                  >
                    <div
                      className="careCirOut"
                      style={{
                        backgroundImage: 'url(/images/menu/care_hem_cir02.png)',
                      }}
                    />
                    <div
                      className="careCirRotate"
                      style={{
                        backgroundImage:
                          'url(/images/menu/care_hem_cir03_rotate.png)',
                      }}
                    />
                  </div>
                </Col>
                <Col span={24} style={{ display: 'flex' }}>
                  <div className="careText">
                    <em>증상 1.</em>
                    <span>
                      <font>
                        <b>출혈</b>
                      </font>
                    </span>
                    <p>
                      배변 시나 배변 후에 선홍색의 출혈이 발생할 수 있습니다.
                    </p>
                  </div>
                  <div className="careText">
                    <em>증상 2.</em>
                    <span>
                      <font>
                        <b>통증</b>
                      </font>
                    </span>
                    <p>
                      찢어지는 듯한 통증이 배변 시나 배변 후 얼마간 지속되기도
                      합니다.
                    </p>
                  </div>
                </Col>
                <Col span={24} style={{ display: 'flex' }}>
                  <div className="careText">
                    <em>증상 3.</em>
                    <span>
                      <font>
                        <b>가려움</b>
                      </font>
                    </span>
                    <p>
                      손상된 항문점막 부위에서 분비된 점액으로 가려움증을
                      동반하기도 합니다.
                    </p>
                  </div>
                  <div className="careText">
                    <em>증상 4.</em>
                    <span>
                      <font>
                        <b>변비</b>
                      </font>
                    </span>
                    <p>통증이 두려워 배변을 미루다가 변비가 더욱 심해집니다.</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
}

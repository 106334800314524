import { Fragment } from 'react';
import { Table, Card } from 'antd';
import { isMobile } from 'react-device-detect';

const PageTable = ({ data: { cols, data } }) => {
  const Component = isMobile ? (
    data.map((d, key) => (
      <Card
        key={key}
        title={key === 0 || data[key - 1].title !== d.title ? d.title : ''}
        bodyStyle={{ paddingTop: '0' }}
      >
        {cols
          .map(({ dataIndex }, j) =>
            dataIndex !== 'title' ? d[dataIndex] : null
          )
          .filter((item) => item)
          .flat()
          .map((item, k) => (
            <Fragment key={k}>
              {item}
              <br />
            </Fragment>
          ))}
      </Card>
    ))
  ) : (
    <Table
      size="small"
      style={{ margin: '50px 350px' }}
      columns={cols.map((column, key) => ({
        ...column,
        key,
        render: (__html) => <span dangerouslySetInnerHTML={{ __html }} />,
      }))}
      dataSource={data.map((data, key) => ({ ...data, key }))}
      pagination={false}
    />
  );

  return <div className="contTableM">{Component}</div>;
};

export default PageTable;

import { useEffect, useState } from 'react';
import { Button, List, Image, Divider } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { getBoard, deleteBoard } from '../../api/atree-api';
import DescWrapper from '../../components-design/styled/table/DescWrapper';
import BoardSection from '../../components/board/BoardSection';
import BoardComment from './BoardComment';

const ATREE_CLIENT_API_URL = process.env.REACT_APP_ATREE_CLIENT_API_URL;

const BoardImageContent = () => {
  //
  const { id: boardId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState();
  console.log('state= :', state);
  const [comments, setComments] = useState();

  useEffect(() => {
    if (!state) {
      navigate('..');
    }
  }, [state, navigate]);

  if (!data && state) {
    getBoard(boardId).then((res) => {
      setComments(res?.comments);
      delete res.comments;
      setData(res ?? {});
    });
  }

  const handleClickDelete = ({ password }) =>
    deleteBoard(boardId, window.btoa(password))
      .then((success) => {
        if (success) {
          navigate('..');
        }
      })
      .catch((err) => {
        if (err.status === 401) {
        }
      });

  const buttons = [
    <Button key="3">
      <Link to={'..'}>목록</Link>
    </Button>,
  ];

  if (data?.isEdit) {
    buttons.unshift(
      <Button type="primary" key="1" onClick={handleClickDelete}>
        삭제
      </Button>
    );
    buttons.unshift(
      <Button type="primary" key="2">
        <Link to={'./edit'} state={{ data, id: boardId, title: state?.title }}>
          수정
        </Link>
      </Button>
    );
  }

  return (
    <>
      <BoardSection title={state.title} description={state.description} />
      <div className={isMobile ? 'boardSectionM' : 'boardSection'}>
        <div>
          <span>{buttons}</span>
          <h2>{data?.title}</h2>
          <p>
            <span>{data?.writer}</span>
            <span>
              {data?.created ? new Date(data.created).toLocaleString() : null}
            </span>
          </p>
        </div>
        <pre>{data?.content}</pre>
        <div style={{ textAlign: 'center' }}>
          {data?.images?.map(({ id }) => (
            <Image
              key={id}
              preview={false}
              src={`${ATREE_CLIENT_API_URL}/images/${id}`}
            />
          ))}
        </div>
        <Divider />
        <List
          size="small"
          header={null}
          footer={null}
          bordered
          dataSource={data?.files ?? []}
          renderItem={({ id, name }) => (
            <List.Item>
              <a href={`${ATREE_CLIENT_API_URL}/files/${id}`} download>
                <PaperClipOutlined />
                {name}
              </a>
            </List.Item>
          )}
        />
      </div>
      <DescWrapper>
        <BoardComment
          isEdit={data?.isEdit}
          isComment={data?.isComment}
          boardId={boardId}
          comments={comments}
          setComments={setComments}
        />
      </DescWrapper>
    </>
  );
};

export default BoardImageContent;

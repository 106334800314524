import { useEffect, useState } from 'react';

const useScript = (key, url) => {
  const [lib, setLib] = useState({});

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.onload = () => setLib(window[key]);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [key, url]);
  return lib;
};

export default useScript;

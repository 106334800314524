import { Route } from 'react-router-dom';
import AdminLayer from '../pages/admin/AdminLayer';

const RouterAdmin = [
  <Route
    key='layer'
    path='/layer'
    element={<AdminLayer menuKey='layer' path='/layer' />}
  />,
];

export default RouterAdmin;

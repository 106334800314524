import styled from 'styled-components';

const FormWrapper = styled.div`
  & {
    .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
      color: #59bec9;
    }
    .ant-btn-primary {
      background: #59bec9;
      border-color: #59bec9;
    }
    .ant-descriptions-view {
      border: 1px solid #e4e4e4;
    }
    .ant-descriptions-item-label {
      width: 180px;
    }
    .ant-descriptions-item-content {
      pre {
        white-space: pre-wrap;
      }
      .ant-image {
        margin-bottom: 1em;
      }
    }
  }
`;

export default FormWrapper;

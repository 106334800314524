import { List, Typography } from 'antd';

const PageDescription = ({ data: { items, title = '' } }, key) => {
  return (
    <div className='contTableM'>
      <h2>
        <b>{title}</b>
      </h2>
      <List
        bordered
        dataSource={items}
        renderItem={(item) => (
          <List.Item>
            <Typography.Text>{item}</Typography.Text>
          </List.Item>
        )}
      />
    </div>
  );
};
export default PageDescription;

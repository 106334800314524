import { Row, Col, Image } from 'antd';
import { MobileView, BrowserView } from 'react-device-detect';

export default function FecalIncontinence() {
  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu2/hem000.png)',
          }}
        >
          <Row align="middle">
            <Col span={12}></Col>
            <Col span={12}>
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>대장항문</b>클리닉
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>변비</b>란?
                </h2>
                <p className="desc">
                  <b>변비</b>는 의학적으로 배변 횟수가 적거나 <br />
                  (1주일에 3회 미만) <b>배변이 힘든 경우</b>로 정의합니다.
                </p>
              </Col>
              <Col style={{ width: '400px' }}>
                <p className="subDesc">
                  여기서 말하는 힘든 경우란 변을 볼 때 힘을 많이 주는 경우, 오래
                  보는 경우, 배변 후 잔변감이 있는 경우, 변이 딱딱한 경우,
                  배변을 위해 추가적인 동작이 필요한 경우가 장기간 지속 될 때를
                  말합니다.
                </p>
                <p className="subDesc">
                  변비는 전 인구의 5-20%가 증상을 호소할 만큼 매우 흔한 증상으로
                  연령이 증가하면 그 빈도가 증가하며 남자보다는 여자에게 흔하게
                  발생합니다.
                </p>
              </Col>
              <Col style={{ width: '600px' }}>
                <Image
                  preview={false}
                  src="/images/menu2/cont1_ana_circle03.png"
                />
              </Col>
            </Row>
          </div>
          <div
            className="cont_cause"
            style={{
              backgroundImage: 'url(/images/menu2/cause2_bg.png)',
              backgroundColor: '#ffe3d3',
            }}
          >
            <img
              className="vDescImg"
              alt=""
              src="/images/menu/v_desc_eng.png"
            />
            <Row justify="center">
              <Col span={16}>
                <Row justify="start" style={{ height: '450px' }}>
                  <Col span={8}>
                    <h2>
                      <b>변비증상</b> 및 <b>진단기준</b>은?
                    </h2>
                    <p className="subDesc">
                      환자들이 호소하는 증상이 매우 다양하여 단지 배변
                      횟수만으로 변비를 진단하기 어려운 경우가 많습니다.
                    </p>
                    <p className="subDesc">
                      따라서 이를 해결하기 위해 다음과 같이{' '}
                      <b>6가지 대표적인 증상</b>
                      으로 객관적인 기준을 정하고 있습니다.
                    </p>
                  </Col>
                </Row>
                <Row justify="center" style={{ height: '100px' }}>
                  <Col>
                    <p className="subDesc">
                      적어도 <b>6개월 전부터 시작</b>되어 다음과 같은 증상이
                    </p>
                    <p className="desc">
                      <b>
                        최근 3개월 동안 <font>2가지 이상</font>
                      </b>
                      있어야 합니다.
                    </p>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col style={{ display: 'flex' }}>
                    <div className="singleRec">
                      <div>
                        <div className="numWrap">
                          <p>
                            <span>1</span>과도한 힘주기
                          </p>
                          <p>
                            <span>4</span>항문 폐쇄감
                          </p>
                        </div>
                        <div className="numWrap">
                          <p>
                            <span>2</span>
                            덩어리지거나 딱딱한 변
                          </p>
                          <p>
                            <span>5</span>
                            원활한 배변을 위해 부가적인 처치
                          </p>
                        </div>
                        <div className="numWrap">
                          <p>
                            <span>3</span>
                            잔변감
                          </p>
                          <p>
                            <span>6</span>
                            배변
                          </p>
                        </div>
                      </div>
                      <div className="subDesc" align="middle">
                        <font>1~5번</font>항목 중 4번의 배변 시 적어도{' '}
                        <b>1회 이상</b> 인 경우, <font>6번</font>은 일주일{' '}
                        <b>3회 미만</b> 인 경우
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className="cont_way"
            style={{
              backgroundImage: 'url(/images/menu/how_bg.png)',
              backgroundColor: '#d9d9d9',
            }}
          >
            <Row justify="center">
              <Col span={12} align="middle" style={{ paddingTop: '300px' }}>
                <h2>
                  <b>변비</b>의 치료방법
                </h2>
              </Col>
            </Row>
            <Row justify="center">
              <Col style={{ display: 'flex' }}>
                <div className="wayCir wide">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon10.png)',
                    }}
                  ></span>
                  <p className="subDesc">
                    <b>배변습관 교정 및 운동요법</b>
                    변은 정말 보고 싶을 때 화장실에 가서 가급적 짧은시간(5분내)
                    동안 봐야합니다. 변을 보기 힘들 때는 발 받침대를 놓고
                    고관절을 굴곡시킨 상태에서 보는 것도 도움이 됩니다.
                    복부마사지, 두드리기 등이 도움이 되며 활동량이 적은 사람
                    들은 운동을 권합니다.
                  </p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                  <div
                    className="plusIcon"
                    style={{
                      backgroundImage: 'url(/images/menu/way_plus.png)',
                    }}
                  />
                </div>
                <div className="wayCir wide">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon08.png)',
                    }}
                  ></span>
                  <p className="subDesc">
                    <b>약물요법</b>
                    위에 언급한 비약물적 요법에도 효과가 없으면 약물치료를
                    병행합니다. 초기에는 팽창성하제를 사용해 볼 수 있으며 효과가
                    없을 시 삼투성하제 나 염류성하제를 사용하며 그래도 효과 없을
                    시 자극성하제를 사용해 볼 수 있으나 장기간 사용 시 다른
                    부작용 발생 등으로 단기간만 사용합니다.
                  </p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                  <div
                    className="plusIcon"
                    style={{
                      backgroundImage: 'url(/images/menu/way_plus.png)',
                    }}
                  />
                </div>
                <div className="wayCir wide">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon09.png)',
                    }}
                  ></span>
                  <p className="subDesc">
                    <b>식이요법</b>
                    변비를 해결하기 위해서는 식이섬유와 수분이 풍부한 음식을
                    섭취해야 합니다. 식이섬유는 체내에서 흡수되지 않고 수분을
                    흡수하는 특성이 있으며 대장의 운동을 촉진하는 역할을 합니다.
                    섬유량이 많은 전곡류, 과일류, 채소류, 해조류 등 의 섭취를
                    늘리는 것이 중요합니다.
                  </p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                </div>
              </Col>
              <Col span={24} align="middle">
                <img
                  className="descImgEnd"
                  alt=""
                  src="/images/menu/desc_eng_w.png"
                />
              </Col>
            </Row>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>변실금</b>이란?
                </h2>
                <p className="desc">
                  <b>자기도 모르게</b> 대변이 나오거나, <b>참지 못하며 </b>
                  옷에 실수를 하고,
                  <br /> 기침을 하거나 방귀가 나올 때 <b>변이 나오는 것</b>을
                  말합니다.
                </p>
              </Col>
              <Col style={{ width: '400px' }}>
                <p className="subDesc">
                  4세 이상의 연령에서는 반복적으로 딱딱한 변, 물변, 가스 등의
                  배변을 자신의 의지대로 조절할 수 없는 상태가 3개월 이상
                  지속되는 경우를 변실금이라 합니다.
                </p>
                <p className="subDesc">
                  대개 65세 이상에서 발생하며 2/3는 여성에서 많이 발생합니다.
                </p>
              </Col>
              <Col style={{ width: '600px' }}>
                <Image
                  preview={false}
                  src="/images/menu2/cont1_ana_circle03.png"
                />
              </Col>
            </Row>
          </div>
          <div
            className="cont_cause"
            style={{
              backgroundImage: 'url(/images/menu2/cause2_bg.png)',
              backgroundColor: '#ffe3d3',
            }}
          >
            <img
              className="vDescImg"
              alt=""
              src="/images/menu/v_desc_eng.png"
            />
            <Row justify="center">
              <Col span={16}>
                <Row justify="start" style={{ height: '500px' }}>
                  <Col span={6}>
                    <h2>
                      <b>변실금</b>의 <br /> <b>원인</b>은?
                    </h2>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col style={{ display: 'flex' }}>
                    <div className="singleRec">
                      <div>
                        <div className="numWrap">
                          <p>
                            <span>1</span>항문 수술에 의한 손상
                          </p>
                        </div>
                        <div className="numWrap">
                          <p>
                            <span>2</span>
                            분만 시 항문괄약근 손상
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="numWrap">
                          <p>
                            <span>3</span>
                            대장항문 질환
                          </p>
                        </div>
                        <div className="numWrap">
                          <p>
                            <span>4</span>
                            설사약 남용
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className="cont_way"
            style={{
              backgroundImage: 'url(/images/menu/how_bg.png)',
              backgroundColor: '#d9d9d9',
            }}
          >
            <Row justify="center">
              <Col span={12} align="middle" style={{ paddingTop: '300px' }}>
                <h2>
                  <b>변실금</b>의 치료방법
                </h2>
              </Col>
            </Row>
            <Row justify="center">
              <Col style={{ display: 'flex' }}>
                <div className="wayCir wide">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon10.png)',
                    }}
                  ></span>
                  <p className="subDesc">
                    <b>회음부운동</b>
                    요실금이 있을 때 시행하는 케겔 운동(항문과 질, 요도를 조이는
                    운동)으로 변실금 치료하는 데 유용할 수 있습니다.
                  </p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                  <div
                    className="plusIcon"
                    style={{
                      backgroundImage: 'url(/images/menu/way_plus.png)',
                    }}
                  />
                </div>
                <div className="wayCir wide">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon08.png)',
                    }}
                  ></span>
                  <p className="subDesc">
                    <b>약물요법</b>
                    묽은 변일수록 참기가 힘들고 실금이 많이 발생 하므로 변실금의
                    약물치료는 약간의 변비를 만드는 약물을 사용하여 변의 굳기를
                    조절하는 것이 목표 입니다. 일차적으로 수분을 흡수하고 고형을
                    만드는 식이섬유 치료를 시작하며 효과가 미비할 경우 장운동
                    저하제를 사용합니다.
                  </p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                  <div
                    className="plusIcon"
                    style={{
                      backgroundImage: 'url(/images/menu/way_plus.png)',
                    }}
                  />
                </div>
                <div className="wayCir wide">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon09.png)',
                    }}
                  ></span>
                  <p className="subDesc">
                    <b>식이요법</b>
                    음식물 종류에 따라 변의 굵기와 변이 장을 통과 하는 속도가
                    달라지기 때문에 음식물 섭취를 조절 하는 것은 매우
                    중요합니다. 따라서 음식 섭취 일지 를 작성하여 어떤 음식을
                    먹었을 때 변실금이 악화 되는지 확인하면 변실금 예방에 도움이
                    됩니다.
                  </p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                </div>
              </Col>
              <Col span={24} align="middle">
                <img
                  className="descImgEnd"
                  alt=""
                  src="/images/menu/desc_eng_w.png"
                />
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top001m.png)',
          }}
        >
          <div className="descWrapM">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>대장항문</b>클리닉
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>변비</b>란?
              </h2>
              <p className="desc">
                <b>변비</b>는 의학적으로 배변 횟수가 적거나 <br />
                (1주일에 3회 미만) <b>배변이 힘든 경우</b>로 정의합니다.
              </p>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={14}>
              <p className="subDesc">
                여기서 말하는 힘든 경우란 변을 볼 때 힘을 많이 주는 경우, 오래
                보는 경우, 배변 후 잔변감이 있는 경우, 변이 딱딱한 경우, 배변을
                위해 추가적인 동작이 필요한 경우가 장기간 지속 될 때를 말합니다.
                변비는 전 인구의 5-20%가 증상을 호소할 만큼 매우 흔한 증상으로
                연령이 증가하면 그 빈도가 증가하며 남자보다는 여자에게 흔하게
                발생합니다.
              </p>
            </Col>
            <Col span={10}>
              <img className="cirImg" alt="" src="/images/mobile/cir004m.png" />
            </Col>
          </Row>
        </div>
        <div
          className="contCauseM"
          style={{
            backgroundColor: '#ffe3d3',
          }}
        >
          <img className="causeBg01" alt="" src="/images/mobile/bg001m.jpg" />
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>변비증상</b> 및 <b>진단기준</b>은?
              </h2>
            </Col>
            <Col span={20}>
              <p className="subDesc">
                환자들이 호소하는 증상이 매우 다양하여 단지 배변 횟수만으로
                변비를 진단하기 어려운 경우가 많습니다. 따라서 이를 해결하기
                위해 다음과 같이 <b>6가지 대표적인 증상</b>
                으로 객관적인 기준을 정하고 있습니다.
              </p>
              <p className="subDesc center">
                적어도 <b>6개월 전부터 시작</b>되어 다음과 같은 증상이{' '}
                <b>
                  최근 3개월 동안 <font>2가지 이상</font>
                </b>
                있어야 합니다.
              </p>
            </Col>
            <Col>
              <div className="singleRec">
                <div className="numWrap">
                  <p>
                    <span>1</span>과도한 힘주기
                  </p>
                  <p>
                    <span>2</span>
                    덩어리지거나 딱딱한 변
                  </p>
                </div>
                <div className="numWrap">
                  <p>
                    <span>3</span>
                    잔변감
                  </p>
                  <p>
                    <span>4</span>항문 폐쇄감
                  </p>
                </div>
                <div className="numWrap">
                  <p>
                    <span>5</span>
                    원활한 배변을 위해 부가적인 처치
                  </p>
                  <p>
                    <span>6</span>
                    배변
                  </p>
                </div>
              </div>
              <div className="subDesc center">
                <font>1~5번</font>항목 중 4번의 배변 시 적어도 <b>1회 이상</b>{' '}
                인 경우, <br /> <font>6번</font>은 일주일 <b>3회 미만</b> 인
                경우
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="contWayM single"
          style={{
            backgroundColor: '#d9d9d9',
          }}
        >
          <img className="causeBg01" alt="" src="/images/mobile/bg002m.png" />
          <Row justify="center">
            <Col span={24}>
              <h2>
                <b>변비</b>의 치료방법
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <div className="wayCir">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon10.png)',
                  }}
                ></span>
                <p>
                  <b>배변습관 교정 및 운동요법</b>
                  변은 정말 보고 싶을 때 화장실에 가서 가급적 짧은시간(5분내)
                  동안 봐야합니다. 변을 보기 힘들 때는 발 받침대를 놓고 고관절을
                  굴곡시킨 상태에서 보는 것도 도움이 됩니다. 복부마사지,
                  두드리기 등이 도움이 되며 활동량이 적은 사람 들은 운동을
                  권합니다.
                </p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
              </div>
              <div className="wayCir">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon09.png)',
                  }}
                ></span>
                <p>
                  <b>식이요법</b>
                  변비를 해결하기 위해서는 식이섬유와 수분이 풍부한 음식을
                  섭취해야 합니다. 식이섬유는 체내에서 흡수되지 않고 수분을
                  흡수하는 특성이 있으며 대장의 운동을 촉진하는 역할을 합니다.
                  섬유량이 많은 전곡류, 과일류, 채소류, 해조류 등 의 섭취를
                  늘리는 것이 중요합니다.
                </p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
              </div>
              <div className="wayCir single">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon08.png)',
                  }}
                ></span>
                <p>
                  <b>약물요법</b>
                  위에 언급한 비약물적 요법에도 효과가 없으면 약물치료를
                  병행합니다. 초기에는 팽창성하제를 사용해 볼 수 있으며 효과가
                  없을 시 삼투성하제 나 염류성하제를 사용하며 그래도 효과 없을
                  시 자극성하제를 사용해 볼 수 있으나 장기간 사용 시 다른 부작용
                  발생 등으로 단기간만 사용합니다.
                </p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
          </Row>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={20}>
              <h2>
                <b>변실금</b>이란?
              </h2>
              <p className="desc">
                <b>자기도 모르게</b> 대변이 나오거나, <b>참지 못하며 </b>
                옷에 실수를 하고, 기침을 하거나 방귀가 나올 때{' '}
                <b>변이 나오는 것</b>을 말합니다.
              </p>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={14}>
              <p className="subDesc">
                4세 이상의 연령에서는 반복적으로 딱딱한 변, 물변, 가스 등의
                배변을 자신의 의지대로 조절할 수 없는 상태가 3개월 이상 지속되는
                경우를 변실금이라 합니다. 대개 65세 이상에서 발생하며 2/3는
                여성에서 많이 발생합니다.
              </p>
            </Col>
            <Col span={10}>
              <img className="cirImg" alt="" src="/images/mobile/cir004m.png" />
            </Col>
          </Row>
        </div>
        <div
          className="contCauseM"
          style={{
            backgroundColor: '#ffe3d3',
          }}
        >
          <img className="causeBg01" alt="" src="/images/mobile/bg001m.jpg" />
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>변실금</b>의 <b>원인</b>은?
              </h2>
            </Col>
            <Col>
              <div className="singleRec">
                <div className="numWrap">
                  <p>
                    <span>1</span>항문 수술에 의한 손상
                  </p>
                  <p>
                    <span>2</span>
                    분만 시 항문괄약근 손상
                  </p>
                </div>
                <div className="numWrap">
                  <p>
                    <span>3</span>
                    대장항문 질환
                  </p>
                  <p>
                    <span>4</span> 설사약 남용
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="contWayM single"
          style={{
            backgroundColor: '#d9d9d9',
          }}
        >
          <img className="causeBg01" alt="" src="/images/mobile/bg002m.png" />
          <Row justify="center">
            <Col span={24}>
              <h2>
                <b>변실금</b>의 치료방법
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <div className="wayCir">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon10.png)',
                  }}
                ></span>
                <p>
                  <b>회음부운동</b>
                  요실금이 있을 때 시행하는 케겔 운동(항문과 질, 요도를 조이는
                  운동)으로 변실금 치료하는 데 유용할 수 있습니다.
                </p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
              </div>
              <div className="wayCir">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon04.png)',
                  }}
                ></span>
                <p>
                  <b>약물요법</b>
                  묽은 변일수록 참기가 힘들고 실금이 많이 발생 하므로 변실금의
                  약물치료는 약간의 변비를 만드는 약물을 사용하여 변의 굳기를
                  조절하는 것이 목표 입니다. 일차적으로 수분을 흡수하고 고형을
                  만드는 식이섬유 치료를 시작하며 효과가 미비할 경우 장운동
                  저하제를 사용합니다.
                </p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
              </div>
              <div className="wayCir">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon09.png)',
                  }}
                ></span>
                <p>
                  <b>식이요법</b>
                  음식물 종류에 따라 변의 굵기와 변이 장을 통과 하는 속도가
                  달라지기 때문에 음식물 섭취를 조절 하는 것은 매우 중요합니다.
                  따라서 음식 섭취 일지 를 작성하여 어떤 음식을 먹었을 때
                  변실금이 악화 되는지 확인하면 변실금 예방에 도움이 됩니다.
                </p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
}

import BrowserHeader from './BrowserHeader';
import BrowserSide from './BrowserSide';
import BrowserFooter from './BrowserFooter';
import BrowserMenu from '../../components/menu/BrowserMenu';

import { Anchor } from 'antd';

const BrowserLayout = ({ client, menus, content }) => {
  return (
    <>
      <BrowserHeader client={client} />
      <Anchor>{client?.menus && <BrowserMenu menus={menus} />}</Anchor>
      {content}
      <BrowserSide />
      <BrowserFooter client={client} />
    </>
  );
};
export default BrowserLayout;

import { useEffect, useContext } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Board from '../pages/board/Board';
import BoardWrite from '../pages/board/BoardWrite';
import BoardEdit from '../pages/board/BoardEdit';
import BoardContent from '../pages/board/BoardContent';
import BoardImageContent from '../pages/board/BoardImageContent';
import BoardImageContentWrite from '../pages/board/BoardImageContentWrite';
import BoardImageContentEdit from '../pages/board/BoardImageContentEdit';
import Main from '../pages/Main';
import RouterAdmin from './RouterAdmin';
import Login from '../pages/login';
import Logout from '../pages/login/logout';
import Member from '../pages/login/member';
import Naver from '../pages/login/Naver';
import Kakao from '../pages/login/Kakao';
import Google from '../pages/login/Google';
import { UserContext } from '../context/UserContext';
import RouterDesign from './Router-design';
//data
import notice from '../data/notice';
import inquiry from '../data/inquiry';

export default function Router() {
  const location = useLocation();
  const { user } = useContext(UserContext);
  useEffect(() => window.scrollTo(0, 0), [location]);

  return (
    <Routes>
      <Route path='/' element={<Main />} />
      {RouterDesign}
      <Route key={81} path={`/inquiry/*`}>
        <Route index={true} element={<Board menuKey={81} data={inquiry} />} />
        <Route path=':id' element={<BoardContent />} />
        <Route path='write' element={<BoardWrite user={user} />} />
        <Route path=':id/edit' element={<BoardEdit />} />
      </Route>
      <Route key={80} path={'/notice/*'}>
        <Route index={true} element={<Board menuKey={80} data={notice} />} />
        <Route path=':id' element={<BoardImageContent />} />
        <Route path='write' element={<BoardImageContentWrite user={user} />} />
        <Route path=':id/edit' element={<BoardImageContentEdit />} />
      </Route>
      {RouterAdmin}

      <Route path='/login' element={<Login />} />
      <Route path='/login/naver' element={<Naver />} />
      <Route path='/login/kakao' element={<Kakao />} />
      <Route path='/login/google' element={<Google />} />

      <Route path='/member' element={<Member />} />
      <Route path='/logout' element={<Logout />} />
    </Routes>
  );
}

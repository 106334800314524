const ATREE_CLIENT_API_URL = process.env.REACT_APP_ATREE_CLIENT_API_URL;
// ATREE_BACKEND_API_URL
export const getClient = () => {
  return fetch(ATREE_CLIENT_API_URL, {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  }).then(
    async (res) => await res.json(),
    (error) => {
      console.log('ERROR : ', error);
    },
  );
};

export const getNotices = (menuKey) => {
  return fetch(`${ATREE_CLIENT_API_URL}/menus/${menuKey}/notices`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  }).then(
    async (res) => {
      return (await res.json()) ?? [];
    },
    (err) => {
      console.log('ERROR : ', err);
      return err;
    },
  );
};

export const getLayers = () => {
  return fetch(`${ATREE_CLIENT_API_URL}/layers`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  }).then(
    async (res) => {
      return (await res.json()) ?? [];
    },
    (err) => {
      console.log('ERROR : ', err);
      return err;
    },
  );
};
//management
export const getManagementLayers = () => {
  return fetch(`${ATREE_CLIENT_API_URL}/management/layers`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  }).then(
    async (res) => {
      return (await res.json()) ?? [];
    },
    (err) => {
      console.log('ERROR : ', err);
      return err;
    },
  );
};
//management
export const putManagementLayer = (id, body) => {
  return fetch(`${ATREE_CLIENT_API_URL}/management/layers/${id}`, {
    method: 'PUT',
    credentials: 'include',
    headers: {},
    body,
  }).then(
    async (res) => await res.ok,
    (err) => {
      throw err;
    },
  );
};

export const getBoards = (menuKey) => {
  return fetch(`${ATREE_CLIENT_API_URL}/menus/${menuKey}/boards`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  }).then(
    async (res) => {
      return (await res.json()) ?? [];
    },
    (err) => {
      console.log('ERROR : ', err);
      return err;
    },
  );
};

export const getBoard = (id) => {
  return fetch(`${ATREE_CLIENT_API_URL}/boards/${id}`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  }).then(async (res) => {
    if (res.ok) {
      return (await res.json()) ?? {};
    }
    throw res;
  });
};

//deprecated
export const postBoardSecret = (id, password) => {
  return fetch(`${ATREE_CLIENT_API_URL}/boards/${id}`, {
    method: 'post',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: password,
  }).then(async (res) => {
    if (res.ok) {
      return (await res.json()) ?? {};
    }
    throw res;
  });
};

// data.UserId, data.Writer, data.Title, data.Secret
export const postBoard = (menuKey, body) => {
  return fetch(`${ATREE_CLIENT_API_URL}/menus/${menuKey}/boards`, {
    method: 'POST',
    credentials: 'include',
    headers: {},
    body,
  }).then(
    async (res) => {
      return await res;
    },
    (err) => {
      throw err;
    },
  );
};

export const postBoardUser = (menuKey, body) => {
  return fetch(`${ATREE_CLIENT_API_URL}/menus/${menuKey}/boards`, {
    method: 'POST',
    credentials: 'include',
    headers: {},
    body,
  }).then(
    async (res) => {
      return await res.ok;
    },
    (err) => {
      throw err;
    },
  );
};

export const putBoard = (id, body) => {
  return fetch(`${ATREE_CLIENT_API_URL}/boards/${id}`, {
    method: 'PUT',
    credentials: 'include',
    headers: {},
    body,
  }).then(
    async (res) => await res.ok,
    (err) => {
      throw err;
    },
  );
};

export const deleteBoard = (id) => {
  return fetch(`${ATREE_CLIENT_API_URL}/boards/${id}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  }).then(async (res) => await res.ok);
};
//comment
export const postComment = (boardId, payload) => {
  return fetch(`${ATREE_CLIENT_API_URL}/boards/${boardId}/comments`, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }).then(
    async (res) => {
      return await res.json();
    },
    (err) => {
      throw err;
    },
  );
};
export const putComment = (commentId, payload) => {
  return fetch(`${ATREE_CLIENT_API_URL}/comments/${commentId}`, {
    method: 'PUT',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }).then(
    async (res) => {
      return await res.text();
    },
    (err) => {
      throw err;
    },
  );
};
export const deleteComment = (commentId, body) => {
  return fetch(`${ATREE_CLIENT_API_URL}/comments/${commentId}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body,
  }).then(
    async (res) => {
      return await res.text();
    },
    (err) => {
      throw err;
    },
  );
};
// deprecated
export const deleteBoardPassword = (id, password) => {
  return fetch(`${ATREE_CLIENT_API_URL}/boards/${id}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: password,
  }).then(async (res) => await res.ok);
};

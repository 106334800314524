import { Row, Col, Tabs } from 'antd';
import { MobileView, BrowserView } from 'react-device-detect';

const { TabPane } = Tabs;

const Intro = () => {
  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu1/intro_bg.jpg)',
          }}
        >
          <Row align="middle">
            <Col span={24} align="middle">
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>병원</b>소개
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>의료진</b>소개
                </h2>
                <p className="desc">
                  "<font>대학병원급 장비</font>와 <font>숙련된 기술</font>,{' '}
                  그리고 <br />
                  <font>친절함</font>으로 감동을 드리는 의원이 되겠습니다."
                </p>
              </Col>
              <Row justify="center" align="middle" style={{ gap: '50px' }}>
                <Col style={{ width: '600px' }} align="middle">
                  <p className="desc">
                    대표원장 <b>이 종 석</b>
                  </p>
                  <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size="large"
                    style={{ height: '800px' }}
                  >
                    <TabPane tab="약력" key="1">
                      <p className="subDesc" align="left">
                        - 연세대학교 신촌세브란스병원 외과 전임의
                        <br /> - 한림대학교 강동성심병원 외과 전공의
                        <br /> - 안산 한도병원 외과과장
                        <br /> - 수원 신세계외과 진료원장
                        <br /> - 안양 상쾌한항외과 진료원장
                        <br /> - 연세대학교 세브란스병원 외과 외래조교수
                        <br />
                        <br />- 유방양성질환 분과전문의 취득
                        <br />- 대장내시경 분과전문의 취득
                        <br />- 대한대장항문학회 정회원
                        <br />- 한국유방암학회 정회원
                        <br />- 대한내분비외과학회 정회원
                        <br />- 대한외과초음파학회 정회원
                        <br /> - 대한외과학회 정회원
                        <br />
                        <br />- 유방암수술 150건 이상
                        <br />- 갑상선암수술 100건 이상
                        <br />- 항문질환수술 1,000건 이상
                        <br />- 맘모톰 시술 200건 이상
                        <br />- 전문 화상치료 경력 (신세계외과 화상치료센터)
                        <br />- 대장내시경 700례 이상
                        <br />- 위내시경 800례 이상
                      </p>
                    </TabPane>
                    <TabPane tab="인사말" key="2">
                      <p className="subDesc" align="left">
                        안녕하세요.
                        <br /> 시흥 능곡역에 위치한 항문 및 유방중점 외과,
                        시흥드림항유외과 <font>원장 이종석</font>입니다.
                        <br />
                        <br />
                        저는 지난 20년간 외과전문의로서 그리고 유방 및
                        대장내시경 세부전문의로서 국내 유수 대학병원에서
                        암수술을 포함한 다양한 임상경험으로 실력을 쌓아왔으며
                        최근에는 화상치료센터에서 다양한 케이스의 환자를
                        치료했습니다.
                        <br />
                        <br />
                        환자분들의 불안과 걱정을 덜어들이기 위해
                        시흥드림항유외과는 <font>네가지 진료원칙</font>이
                        있습니다.
                        <br />
                        <br />
                        <font>신속정확</font> 신속하고 정확한 진단으로
                        환자분들의 불편을 덜어드리겠습니다. <br />
                        <font>친절설명</font> 환자분이 이해가 쉽도록 천천히
                        친절하게 설명하겠습니다. <br /> <font>최소절개</font>{' '}
                        통증의 경감과 보다 작은 흉터를 위해 절개를
                        최소화하겠습니다. <br />
                        <font>심신공감</font> 질환과 함께 동반되는 마음의
                        고통까지 함께 공감하겠습니다.
                        <br />
                        <br />
                        <font>'기억하는 가장 좋은 방법은 감동받는 것'</font>
                        이라는 말이 있습니다. <br />
                        여러분이 믿고 의지할 든든한 동반자로 기억될 수 있도록
                        대학병원급 장비와 숙련된 기술 그리고 친절함으로 감동을
                        드리는 의원이 되겠습니다.
                      </p>
                    </TabPane>
                  </Tabs>
                </Col>
                <Col style={{}}>
                  <img alt="" src="/images/menu1/doctor.png" />
                </Col>
              </Row>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top002m.png)',
          }}
        >
          <div className="descWrapM center">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>병원</b>소개
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24} align="middle">
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
              <h2>
                <b>의료진</b>소개
              </h2>
              <p className="desc">
                "<font>대학병원급 장비</font>와 <font>숙련된 기술,</font> 그리고{' '}
                <br />
                <font>친절함</font>으로 감동을 드리는 의원이 되겠습니다."
              </p>
            </Col>
            <Col span={20} align="middle">
              <img
                alt=""
                src="/images/menu1/doctor.png"
                style={{ width: '80%', marginTop: '2em' }}
              />
              <p className="desc" style={{ margin: '1em' }}>
                대표원장 <b>이 종 석</b>
              </p>
              <Tabs defaultActiveKey="1" type="card" size="large">
                <TabPane tab="약력" key="1">
                  <p className="subDesc" align="left">
                    - 연세대학교 신촌세브란스병원 외과 전임의
                    <br /> - 한림대학교 강동성심병원 외과 전공의
                    <br /> - 안산 한도병원 외과과장
                    <br /> - 수원 신세계외과 진료원장
                    <br /> - 안양 상쾌한항외과 진료원장
                    <br /> - 연세대학교 세브란스병원 외과 외래조교수
                    <br />
                    <br />- 유방양성질환 분과전문의 취득
                    <br />- 대장내시경 분과전문의 취득
                    <br />- 대한대장항문학회 정회원
                    <br />- 한국유방암학회 정회원
                    <br />- 대한내분비외과학회 정회원
                    <br />- 대한외과초음파학회 정회원
                    <br /> - 대한외과학회 정회원
                    <br />
                    <br />- 유방암수술 150건 이상
                    <br />- 갑상선암수술 100건 이상
                    <br />- 항문질환수술 1,000건 이상
                    <br />- 맘모톰 시술 200건 이상
                    <br />- 전문 화상치료 경력 (신세계외과 화상치료센터)
                    <br />- 대장내시경 700례 이상
                    <br />- 위내시경 800례 이상
                  </p>
                </TabPane>
                <TabPane tab="인사말" key="2">
                  <p className="subDesc" align="left">
                    안녕하세요.
                    <br /> 시흥 능곡역에 위치한 항문 및 유방중점 외과,
                    시흥드림항유외과 <font>원장 이종석</font>입니다.
                    <br />
                    <br />
                    저는 지난 20년간 외과전문의로서 그리고 유방 및 대장내시경
                    세부전문의로서 국내 유수 대학병원에서 암수술을 포함한 다양한
                    임상경험으로 실력을 쌓아왔으며 최근에는 화상치료센터에서
                    다양한 케이스의 환자를 치료했습니다.
                    <br />
                    <br />
                    환자분들의 불안과 걱정을 덜어들이기 위해 시흥드림항유외과는{' '}
                    <font>네가지 진료원칙</font>이 있습니다.
                    <br />
                    <br />
                    <font>신속정확</font> 신속하고 정확한 진단으로 환자분들의
                    불편을 덜어드리겠습니다. <br />
                    <font>친절설명</font> 환자분이 이해가 쉽도록 천천히 친절하게
                    설명하겠습니다. <br /> <font>최소절개</font> 통증의 경감과
                    보다 작은 흉터를 위해 절개를 최소화하겠습니다. <br />
                    <font>심신공감</font> 질환과 함께 동반되는 마음의 고통까지
                    함께 공감하겠습니다.
                    <br />
                    <br />
                    <font>'기억하는 가장 좋은 방법은 감동받는 것'</font>
                    이라는 말이 있습니다. <br />
                    여러분이 믿고 의지할 든든한 동반자로 기억될 수 있도록
                    대학병원급 장비와 숙련된 기술 그리고 친절함으로 감동을
                    드리는 의원이 되겠습니다.
                  </p>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
};
export default Intro;

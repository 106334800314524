import { useState } from 'react';
import { MobileView, BrowserView } from 'react-device-detect';
import { Row, Col } from 'antd';
import { Navigation, FreeMode, Thumbs, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../index.css';

const TakeLook = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const items = new Array(27);
  for (let i = 0; i < 27; i++) {
    items[i] = (
      <SwiperSlide key={i}>
        <img alt={i} src={`/images/take-look/slide${i}.jpg`} width="100%" />
      </SwiperSlide>
    );
  }

  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu1/intro_bg.jpg)',
          }}
        >
          <Row align="middle">
            <Col span={24} align="middle">
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>병원</b>소개
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="top">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>병원</b>둘러보기
                </h2>
                <p className="desc">
                  <font>시흥드림항유외과</font>의 공간을 소개합니다.
                </p>
              </Col>
              <Col span={12}>
                <Swiper
                  onSwiper={setThumbsSwiper}
                  loop={true}
                  style={{
                    '--swiper-navigation-color': '#fff',
                    // '--swiper-pagination-color': '#fff',
                    marginBottom: '20px',
                  }}
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  navigation={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper"
                >
                  {items}
                </Swiper>
                <Swiper
                  style={{
                    '--swiper-navigation-color': '#fff',
                    // '--swiper-pagination-color': '#fff',
                  }}
                  loop={true}
                  spaceBetween={10}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs, Autoplay]}
                  className="mySwiper2"
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                >
                  {items}
                </Swiper>
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top002m.png)',
          }}
        >
          <div className="descWrapM center">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>병원</b>소개
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24} align="middle">
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
              <h2>
                <b>병원</b>둘러보기
              </h2>
              <p className="desc">
                <font>시흥드림항유외과</font>의 공간을 소개합니다.
              </p>
            </Col>
            <Col span={20}>
              <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                style={{
                  '--swiper-navigation-color': '#fff',
                  // '--swiper-pagination-color': '#fff',
                  marginBottom: '20px',
                }}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                navigation={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
              >
                {items}
              </Swiper>
              <Swiper
                style={{
                  '--swiper-navigation-color': '#fff',
                  // '--swiper-pagination-color': '#fff',
                }}
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs, Autoplay]}
                className="mySwiper2"
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
              >
                {items}
              </Swiper>
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
};
export default TakeLook;

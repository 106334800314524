import styled from 'styled-components';
import { Layout } from 'antd';
const { Footer } = Layout;

const FooterStyle = styled(Footer)`
  & {
    background: #ffffff;
    .ftInner {
      max-width: 1200px;
      margin: 20px auto 0 auto;
      height: 330px;
      .ant-row {
        .ant-col {
          &:first-child {
            img {
              width: 240px;
              margin-top: 20px;
            }
          }
          & title {
            display: block;
            font-size: 16px;
            font-weight: 500;
            margin: 20px 0 5px 0;
            & + a {
              font-size: 32px;
              font-weight: bold;
              letter-spacing: -1px;
              color: #3e3e3e;
              line-height: 32px;
            }
          }
          .time {
            dl {
              display: flex;
              margin-bottom: 4px;
              font-size: 14px;
              dt {
                min-width: 60px;
                letter-spacing: -1.5px;
                text-align: left;
              }
              dd {
                margin: 0 16px 0 0;
                letter-spacing: -1px;
              }
            }
          }
          .map {
            height: 200px;
            border: 1px solid #e1e1e1;
            position: relative;
            background-size: contain;
            > div {
              background: #001529;
              color: #fff;
              position: absolute;
              bottom: 0;
              display: block;
              width: 100%;
              text-align: center;
              padding: 2px 0;
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
  & {
    .footerInfo {
      height: 150px;
      width: 100%;
      background: #eeeeee;
      padding-top: 35px;
      & dl {
        display: flex;
        margin-bottom: 4px;
        color: #747474;
        justify-content: center;
        dt {
          margin-right: 6px;
        }
        dd {
          margin: 0 16px 0 0;
        }
        &:last-child {
          dd {
            &:last-child {
              font-size: 12px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  & {
    .footerInfoM {
      .ant-menu {
        height: 40px;
        line-height: 40px;
        justify-content: center;
        background-color: #fafafa;
        font-size: 12px;
      }
      text-align: center;
      img {
        height: 40px;
        margin: 15px 0;
      }
      width: 100%;
      background: #eeeeee;
      & dl {
        display: flex;
        margin-bottom: 2px;
        color: #747474;
        justify-content: center;
        font-size: 12px;
        dt {
          margin-right: 3px;
        }
        dd {
          margin: 0 6px 0 0;
        }
        &:last-child {
          dd {
            &:last-child {
              font-size: 10px;
              padding: 20px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  &.ant-layout-footer {
    padding: 0;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after {
    border-bottom-color: #59bec9;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #59bec9;
  }
`;

export default FooterStyle;

const ATREE_CLIENT_API_URL = process.env.REACT_APP_ATREE_CLIENT_API_URL;
const KAKAO_API_KEY = process.env.REACT_APP_KAKAO_API_KEY;
const KAKAO_OAUTH_URL = process.env.REACT_APP_KAKAO_OAUTH_URL;
const LOGIN_CALLBACK_URL = process.env.REACT_APP_LOGIN_CALLBACK_URL;

export const withdraw = () => {
  return fetch(`${ATREE_CLIENT_API_URL}/withdraw`, {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  })
    .then(
      async (res) => await res.ok(),
      (error) => {
        console.log('ERROR : ', error);
      },
    )
    .catch((e) => e);
};
export const logout = () => {
  return fetch(`${ATREE_CLIENT_API_URL}/logout`, {
    method: 'GET',
    credentials: 'include',
  })
    .then(
      async (res) => await res.ok(),
      (error) => {
        console.log('ERROR : ', error);
      },
    )
    .catch((e) => e);
};

export const getTokenNaver = (search) => {
  return fetch(`${ATREE_CLIENT_API_URL}/login/sns/naver${search}`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  })
    .then(
      async (res) => await res.json(),
      (error) => {
        console.log('ERROR : ', error);
      },
    )
    .catch((e) => e);
};

export const getTokenKakao = (search) => {
  const params = new URLSearchParams(search);
  const body = {
    grant_type: 'authorization_code',
    client_id: KAKAO_API_KEY,
    redirect_uri: `${LOGIN_CALLBACK_URL}/kakao`,
    code: params.get('code'),
  };

  const qs = Object.keys(body)
    .map((k) => `${encodeURIComponent(k)}=${encodeURI(body[k])}`)
    .join('&');

  return fetch(`${KAKAO_OAUTH_URL}/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    body: qs,
  })
    .then(
      async (res) => await res.json(),
      (error) => {
        console.log('ERROR get token : ', error);
        return error;
      },
    )
    .then(async (payload) => {
      if (payload.error) {
        return payload;
      }
      return fetch(`${ATREE_CLIENT_API_URL}/login/sns/kakao`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then(
          async (res) => await res.json(),
          (error) => {
            console.log('ERROR me : ', error);
          },
        )
        .catch((e) => e);
    })
    .catch((e) => e);
};

import React, { useState, useContext } from 'react';
import { Drawer, Menu, Avatar, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { MenuOutlined, UserOutlined } from '@ant-design/icons';
import { UserContext } from '../../context/UserContext';
import { logout } from '../../api/login-api';

const MobileMenu = ({ menus }) => {
  //
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [openKeys, setOpenKeys] = React.useState([]);

  const handleOpenChange = (keys) => {
    setOpenKeys(keys?.length ? [keys[keys?.length - 1]] : keys);
  };
  const move = (key) => {
    const menu = menus.find((menu) => String(menu.key) === key);
    if (menu) {
      navigate(menu.path);
    } else {
      for (const { children } of menus) {
        const menu = children?.find((menu) => String(menu.key) === key);
        if (menu) {
          navigate(menu.path);
          break;
        }
      }
    }
  };
  const handleChangeVisible = () => {
    setVisible(!visible);
  };
  const handleClickLink = (link) => {
    setVisible(false);
    navigate(link);
  };

  const handleClickMenu = ({ key }) => {
    handleChangeVisible();
    move(key);
  };

  return (
    <>
      <MenuOutlined
        style={{
          position: 'absolute',
          top: 12,
          right: 10,
        }}
        onClick={handleChangeVisible}
      />
      <Drawer
        width={'65%'}
        placement='right'
        onClose={handleChangeVisible}
        closeIcon={false}
        visible={visible}
        bodyStyle={{ padding: '0', margin: '0' }}
        headerStyle={{ padding: '0', height: '64px' }}
        title={
          <div
            style={{
              padding: '0',
              margin: '0',
            }}
          >
            <span style={{ lineHeight: '32px' }}>
              {user?.id ? (
                <>
                  <Avatar
                    onClick={() => handleClickLink('/member')}
                    style={{
                      backgroundColor: '#8bd1d9',
                      marginRight: '5px',
                    }}
                    icon={<UserOutlined />}
                  />
                  <Button onClick={() => handleClickLink('/logout')} ghost>
                    LOOUT
                  </Button>
                </>
              ) : (
                <Button onClick={() => handleClickLink('/login')} ghost>
                  LOGIN
                </Button>
              )}
            </span>
          </div>
        }
      >
        <UserContext.Consumer>
          {({ setUser }) => {
            const handleClick = (props) => {
              if (props.key === 'logout') {
                logout().then(() => {
                  sessionStorage.removeItem('user');
                  setUser({});
                  handleChangeVisible();
                  navigate('/');
                });
              } else {
                handleClickMenu(props);
              }
            };
            return (
              <Menu
                onOpenChange={handleOpenChange}
                onClick={handleClick}
                inlineIndent={15}
                style={{
                  width: '100%',
                  height: '15px',
                  padding: '0',
                  margin: '0',
                }}
                mode='inline'
                openKeys={openKeys}
                items={menus}
              />
            );
          }}
        </UserContext.Consumer>
      </Drawer>
    </>
  );
};
export default MobileMenu;

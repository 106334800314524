import { useLayoutEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card } from 'antd';
import { openLayer, closeLayer } from '../components/modal/Layer';
import { getLayers } from '../api/atree-api';
import notice from '../data/notice';
import MainCarousel from '../components/layout/MainCarousel';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';

const { Meta } = Card;

const style = isMobile
  ? {
      width: '90%',
      height: 'calc(200 / 750 * 100vw )',
      backgroundPositionX: 'right',
      margin: 'calc(20 / 750 * 100vw ) auto',
    }
  : { width: 1200, height: 300, margin: '50px auto' };

export default function Main() {
  const videoRef = useRef(null);
  const navigate = useNavigate();

  const handleScroll = () => {
    console.log('???');
    const rect = videoRef.current.getBoundingClientRect();
    const bool1 = window.innerHeight / 2 > rect.top;
    const bool2 = window.innerHeight / 2 > rect.bottom;
    bool1 && !bool2 ? videoRef.current.play() : videoRef.current.pause();
  };

  useLayoutEffect(() => {
    getLayers().then((layers) => {
      return layers?.forEach((layer) => {
        openLayer({
          ...layer,
          callback: () => {
            if (layer.targetId) {
              navigate(`/notice/${layer.targetId}`, {
                state: { ...notice[0], secret: false },
              });
            }
          },
        });
      });
    });

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      closeLayer();
    };
  }, []);

  return (
    <>
      <MainCarousel />
      <BrowserView>
        <div className='mainLink'>
          <Row justify='center'>
            <Col>
              <div className='textWrap'>
                <h2>시흥드림항유외과</h2>
                <p className='desc'>진료과목</p>
                <p className='subDesc'>
                  <b>오직</b>, 환자에만 집중합니다.
                </p>
              </div>
            </Col>
            <Col style={{ display: 'flex', gap: '20px' }}>
              <Card
                onClick={() => navigate('hemorrhoids')}
                hoverable
                cover={<img alt='' src='/images/main_link_001.png' />}
              >
                <Meta title='대장항문센터' />
              </Card>
              <Card
                onClick={() => navigate('nmc')}
                hoverable
                cover={<img alt='' src='/images/main_link_002.png' />}
              >
                <Meta title='건강검진센터' />
              </Card>
              <Card
                onClick={() => navigate('burn')}
                hoverable
                cover={<img alt='' src='/images/main_link_003.png' />}
              >
                <Meta title='화상센터' />
              </Card>
              <Card
                onClick={() => navigate('breast')}
                hoverable
                cover={<img alt='' src='/images/main_link_004.png' />}
              >
                <Meta title='유방/갑상선센터' />
              </Card>
            </Col>
          </Row>
        </div>
        <video
          style={{
            width: '1200px',
            margin: style.margin,
            display: 'block',
          }}
          playsInline
          ref={videoRef}
          loop
          muted
          controls
        >
          <source src='/video/dream_main.mp4' type='video/mp4'></source>
        </video>
        <div
          className='mainBanner'
          style={{
            width: '100%',
            height: style.height,
            backgroundImage: 'url("/images/main_banner.png")',
          }}
        >
          <h1>
            <b>시흥드림항유외과</b>의<br />
            <font>
              네가지 <b>진료원칙</b>
            </font>
          </h1>
          <ul>
            <li>
              <span>신속정확</span> 신속하고 정확한 진단으로 환자분들의 불편을
              덜어드리겠습니다.
            </li>
            <li>
              <span>친절설명</span> 환자분이 이해가 쉽도록 천천히 친절하게
              설명하겠습니다.
            </li>
            <li>
              <span>최소절개</span> 통증의 경감과 보다 작은 흉터를 위해 절개를
              최소화하겠습니다.
            </li>
            <li>
              <span>심신공감</span> 질환과 함께 동반되는 마음의 고통까지 함께
              공감하겠습니다.
            </li>
          </ul>
        </div>
      </BrowserView>
      <MobileView>
        <div className='mainLinkM'>
          <Row justify='center'>
            <Col>
              <div className='textWrap'>
                <h2>시흥드림항유외과</h2>
                <p className='desc'>진료과목</p>
              </div>
            </Col>
            <Col>
              <Card
                onClick={() => navigate('hemorrhoids')}
                hoverable
                cover={<img alt='' src='/images/main_link_001.png' />}
              >
                <Meta title='대장항문센터' />
              </Card>
              <Card
                onClick={() => navigate('nmc')}
                hoverable
                cover={<img alt='' src='/images/main_link_002.png' />}
              >
                <Meta title='건강검진센터' />
              </Card>
            </Col>
            <Col>
              <Card
                onClick={() => navigate('burn')}
                hoverable
                cover={<img alt='' src='/images/main_link_003.png' />}
              >
                <Meta title='화상센터' />
              </Card>
              <Card
                onClick={() => navigate('breast')}
                hoverable
                cover={<img alt='' src='/images/main_link_004.png' />}
              >
                <Meta title='유방/갑상선센터' />
              </Card>
            </Col>
          </Row>
        </div>
        <video
          style={{
            width: style.width,
            margin: style.margin,
            display: 'block',
          }}
          playsInline
          ref={videoRef}
          loop
          muted
          controls
        >
          <source src='/video/dream_main.mp4' type='video/mp4'></source>
        </video>
        <div
          className='mainBannerM'
          style={{
            width: style.width,
            height: style.height,
            backgroundImage: 'url("/images/main_banner.png")',
            backgroundPositionX: style.backgroundPositionX,
            margin: style.margin,
          }}
        >
          <h1>
            <b>시흥드림항유외과</b>의<br />
            <font>
              네가지 <b>진료원칙</b>
            </font>
          </h1>
          <ul>
            <li>
              <span>신속정확</span>
            </li>
            <li>
              <span>친절설명</span>
            </li>
            <li>
              <span>최소절개</span>
            </li>
            <li>
              <span>심신공감</span>
            </li>
          </ul>
        </div>
      </MobileView>
    </>
  );
}

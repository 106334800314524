import styled from 'styled-components';

const TITLE_PRIMARY_COLOR = process.env.REACT_APP_TITLE_PRIMARY_COLOR;
const BORDER_LIGHT_COLOR = process.env.REACT_APP_BORDER_LIGHT_COLOR;

const DescWrapper = styled.div`
  & {
    .ant-btn-primary {
      background: ${TITLE_PRIMARY_COLOR};
      border-color: ${TITLE_PRIMARY_COLOR};
    }
    .ant-descriptions-view {
      border: 1px solid ${BORDER_LIGHT_COLOR};
    }
    .ant-descriptions-item-label {
      width: 180px;
    }
    .ant-descriptions-item-content {
      pre {
        white-space: pre-wrap;
      }
      .ant-image {
        margin-bottom: 1em;
      }
    }
  }
`;

export default DescWrapper;

import { Image, Row, Col } from 'antd';
import { MobileView, BrowserView } from 'react-device-detect';

const Thyroid = () => {
  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu3/bre000.png)',
          }}
        >
          <Row align="middle">
            <Col span={12}></Col>
            <Col span={12}>
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>유방·갑상선</b>클리닉
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>갑상선</b>이란?
                </h2>
                <p className="desc">
                  <b>갑상선</b>은 목 앞 중앙에 위치한 나비모양으로
                  <br />
                  <b>갑상선호르몬</b>을 만드는 내분비기관입니다.
                </p>
              </Col>
              <Col style={{ width: '400px' }}>
                <p className="subDesc">
                  갑상선호르몬은 인체 내 모든 기관의 기능을 적정하게
                  유지시켜주는 필수적인 호르몬입니다.
                </p>
                <p className="subDesc">
                  <b>갑상선검사</b>
                  기본적인 갑상선 검사는 혈액검사, 초음파 검사 등인데, 갑상선
                  결절 및 주변 림프절 이상유무를 판단하는데 갑상선 초음파검사를
                  정밀하고 편리하게 이용할 수 있습니다. 검사시간은 약 5분으로
                  예방적 검진 및 갑상선암 수술 후 경과 확인에도 사용됩니다.
                </p>
              </Col>
              <Col style={{ width: '600px' }}>
                <Image
                  preview={false}
                  src="/images/menu3/cont1_thy_circle01.png"
                />
              </Col>
            </Row>
          </div>
          <div
            className="cont_way"
            style={{
              backgroundImage: 'url(/images/menu3/way3_bg.png)',
              backgroundSize: 'cover',
            }}
          >
            <img
              className="vDescImg"
              alt=""
              src="/images/menu/v_desc_eng.png"
            />
            <Row justify="center" align="middle">
              <Col span={4}></Col>
              <Col span={4} justify="end">
                <h2>
                  수술 후
                  <br />
                  <b>주의사항</b>
                </h2>
              </Col>
              <Col span={12}>
                <p className="recBlock color">
                  <span>
                    <b>갑상선결절(혹)</b>
                    갑상선 세포의 이상 증식으로 혹처럼 자라난 것으로 초음파
                    검사로 발견할 수 있습니다. 작은 결절은 증상이 없으나 큰
                    결절은 목의 멍울, 이물감, 목소리 변화, 통증 등이 나타납니다.
                    혹이 크거나 모양 이 좋지 않으면 양성인지 악성인지 확인 하기
                    위해 세침흡인 세포검사가 필요합니다.
                  </span>
                  <div
                    className="floatImg"
                    style={{
                      backgroundImage: 'url(/images/menu/cont1_thy_rec01.png)',
                    }}
                  />
                </p>
                <p className="recBlock color">
                  <span>
                    <b>갑상선염</b>
                    갑상선에 염증이 생기는 것으로 통증이 있는 아급성 갑상선염,
                    통증이 없는 하시모토 갑상선염, 산후 갑상선염 등이 있습니다.
                    자가면역반응, 바이러스, 세균감염 등이 원인이며 염증의 진행
                    상황에 따라 갑상선기 능항진증이나 갑상선기능저하증이 동반될
                    수 있습니다. 통증이나 기능이상이 발생할 경우 약물치료를
                    진행합니다.
                  </span>
                </p>
                <p className="recBlock color">
                  <span>
                    <b>갑상선암</b>
                    갑상선암은 다른 암에 비해 증식하는 속도나 전이 속도가 느려
                    초기에 증상을 일으키는 경우가 거의 없어 초음파 검사를 통해
                    진단되는 경우가 대부분입니다. 세침흡인 세포검사를 통해
                    갑상선암 종류를 알 수 있으며, 암의 종류, 위치, 크기 등을
                    고려하여 치료방법을 정합니다.
                  </span>
                  <div
                    className="floatImg"
                    style={{
                      backgroundImage: 'url(/images/menu/cont1_thy_rec02.png)',
                    }}
                  />
                </p>
              </Col>
            </Row>
          </div>
          <div
            className="cont_care"
            style={{
              backgroundImage: 'url(/images/menu/care_bg.png)',
            }}
          >
            <Row justify="center">
              <Col span={24} align="middle">
                <img
                  alt=""
                  className="descImg"
                  src="/images/menu/desc_eng_w.png"
                />
                <Row justify="center">
                  <Col
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '30px',
                    }}
                  >
                    <div className="careText">
                      <span>
                        갑상선
                        <br />
                        <font>
                          기능<b>항진증</b>
                        </font>
                      </span>
                      <p className="subDesc">
                        혈중 갑상선호르몬이{' '}
                        <font>
                          필요 이상으로 많아지는 상태로 과도한 에너지 대사
                        </font>
                        가 일어나게 되는 질환입니다.
                      </p>
                      <em>
                        - 식욕은 왕성하지만 살이 찌지 않음
                        <br />
                        - 심박수가 빨라지거나 일정하지 않음
                        <br />
                        - 더위를 잘 타고 땀이 많아짐
                        <br />
                        - 피로하거나 초조함이 있음
                        <br />
                        - 설사나 무월경의 증상이 나타남
                        <br />- 안구가 돌출되는 경우가 있음
                      </em>
                      <p>
                        초기에 발견하여 약물치료를 하는 것이 중요합니다. 약 복용
                        기간은 개인마다 다르며 약물로 조절이 안되는 경우 갑상선
                        절제술을 고려합니다.
                      </p>
                    </div>
                    <div
                      className="careCir"
                      style={{
                        backgroundImage:
                          'url(/images/menu3/care_thy_cir01.png)',
                      }}
                    >
                      <div
                        className="careCirOut"
                        style={{
                          backgroundImage:
                            'url(/images/menu/care_hem_cir02.png)',
                        }}
                      />
                      <div
                        className="careCirRotate"
                        style={{
                          backgroundImage:
                            'url(/images/menu/care_hem_cir03_rotate.png)',
                        }}
                      />
                    </div>
                    <div className="careText">
                      <span>
                        갑상선
                        <br />
                        <font>
                          기능<b>저하증</b>
                        </font>
                      </span>
                      <p className="subDesc">
                        혈중 갑상선호르몬이{' '}
                        <font>부족한 상태로 전신에 활성도가 떨어지는 </font>
                        질환입니다.
                      </p>
                      <em>
                        - 식욕은 부진하지만 체중이 늘어남
                        <br /> - 추위를 심하게 느낌
                        <br /> - 피로감과 무력감이 생김
                        <br /> - 생리양이 많아져 빈혈이 생김
                        <br /> - 피부가 거칠어지고 부종이 생김
                        <br /> - 변비가 생기거나 기억력이 감퇴
                      </em>
                      <p>
                        갑상선이 기능을 회복하게되면 치료가 필요없으나 상태가
                        지속되면 갑상선호르몬제를 복용해야 합니다. 만약 영구적
                        갑상선기능저하 상태라면 평생 복용이 필요합니다.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top003m.png)',
          }}
        >
          <div className="descWrapM">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>유방·갑상선</b>클리닉
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>갑상선</b>이란?
              </h2>
              <p className="desc">
                <b>갑상선</b>은 목 앞 중앙에 위치한 나비모양으로
                <br />
                <b>갑상선호르몬</b>을 만드는 내분비기관입니다.
              </p>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={14}>
              <p className="subDesc">
                갑상선호르몬은 인체 내 모든 기관의 기능을 적정하게 유지시켜주는
                필수적인 호르몬입니다.
              </p>
              <p className="subDesc">
                <span>갑상선검사</span>
                기본적인 갑상선 검사는 혈액검사, 초음파 검사 등인데, 갑상선 결절
                및 주변 림프절 이상유무를 판단하는데 갑상선 초음파검사를
                정밀하고 편리하게 이용할 수 있습니다. 검사시간은 약 5분으로
                예방적 검진 및 갑상선암 수술 후 경과 확인에도 사용됩니다.
              </p>
            </Col>
            <Col span={10}>
              <img className="cirImg" alt="" src="/images/mobile/cir005m.png" />
            </Col>
          </Row>
        </div>
        <div className="contWayM color" style={{ backgroundColor: '#fff' }}>
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>갑상선</b>질환
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <p className="rec01">
                <span>
                  <b>갑상선결절(혹)</b>
                  갑상선 세포의 이상 증식으로 혹처럼 자라난 것으로 초음파 검사로
                  발견할 수 있습니다. 작은 결절은 증상이 없으나 큰 결절은 목의
                  멍울, 이물감, 목소리 변화, 통증 등이 나타납니다. 혹이 크거나
                  모양 이 좋지 않으면 양성인지 악성인지 확인 하기 위해 세침흡인
                  세포검사가 필요합니다.
                </span>
              </p>
              <p className="rec03">
                <span>
                  <b>갑상선염</b>
                  갑상선에 염증이 생기는 것으로 통증이 있는 아급성 갑상선염,
                  통증이 없는 하시모토 갑상선염, 산후 갑상선염 등이 있습니다.
                  자가면역반응, 바이러스, 세균감염 등이 원인이며 염증의 진행
                  상황에 따라 갑상선기 능항진증이나 갑상선기능저하증이 동반될 수
                  있습니다. 통증이나 기능이상이 발생할 경우 약물치료를
                  진행합니다.
                </span>
              </p>
              <p className="rec02">
                <span>
                  <b> 갑상선암</b>
                  갑상선암은 다른 암에 비해 증식하는 속도나 전이 속도가 느려
                  초기에 증상을 일으키는 경우가 거의 없어 초음파 검사를 통해
                  진단되는 경우가 대부분입니다. 세침흡인 세포검사를 통해
                  갑상선암 종류를 알 수 있으며, 암의 종류, 위치, 크기 등을
                  고려하여 치료방법을 정합니다.
                </span>
              </p>
            </Col>
          </Row>
        </div>
        <div className="contCareM">
          <Row justify="center" align="middle">
            <Col span={24}>
              <img
                className="descImg"
                alt=""
                src="/images/menu/desc_eng_w.png"
              />
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  <div
                    className="careCir"
                    style={{
                      backgroundImage: 'url(/images/menu3/care_thy_cir01.png)',
                    }}
                  >
                    <div
                      className="careCirOut"
                      style={{
                        backgroundImage: 'url(/images/menu/care_hem_cir02.png)',
                      }}
                    />
                    <div
                      className="careCirRotate"
                      style={{
                        backgroundImage:
                          'url(/images/menu/care_hem_cir03_rotate.png)',
                      }}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="careText single">
                    <span>
                      갑상선
                      <br />
                      <font>
                        기능<b>항진증</b>
                      </font>
                    </span>
                    <p className="subDesc">
                      혈중 갑상선호르몬이
                      <font>
                        필요 이상으로 많아지는 상태로 과도한 에너지 대사
                      </font>
                      가 일어나게 되는 질환입니다.
                    </p>
                    <em>
                      - 식욕은 왕성하지만 살이 찌지 않음
                      <br />
                      - 심박수가 빨라지거나 일정하지 않음
                      <br />
                      - 더위를 잘 타고 땀이 많아짐
                      <br />
                      - 피로하거나 초조함이 있음
                      <br />
                      - 설사나 무월경의 증상이 나타남
                      <br />- 안구가 돌출되는 경우가 있음
                    </em>
                    <p>
                      초기에 발견하여 약물치료를 하는 것이 중요합니다. 약 복용
                      기간은 개인마다 다르며 약물로 조절이 안되는 경우 갑상선
                      절제술을 고려합니다.
                    </p>
                  </div>
                  <div className="careText single">
                    <span>
                      갑상선
                      <br />
                      <font>
                        기능<b>저하증</b>
                      </font>
                    </span>
                    <p className="subDesc">
                      혈중 갑상선호르몬이
                      <font>부족한 상태로 전신에 활성도가 떨어지는 </font>
                      질환입니다.
                    </p>
                    <em>
                      - 식욕은 부진하지만 체중이 늘어남
                      <br /> - 추위를 심하게 느낌
                      <br /> - 피로감과 무력감이 생김
                      <br /> - 생리양이 많아져 빈혈이 생김
                      <br /> - 피부가 거칠어지고 부종이 생김
                      <br /> - 변비가 생기거나 기억력이 감퇴
                    </em>
                    <p>
                      갑상선이 기능을 회복하게되면 치료가 필요없으나 상태가
                      지속되면 갑상선호르몬제를 복용해야 합니다. 만약 영구적
                      갑상선기능저하 상태라면 평생 복용이 필요합니다.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
};
export default Thyroid;

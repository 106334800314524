/* eslint-disable no-undef */
import { useRef, useState, useEffect } from 'react';
import useScript from '../../hook/useScript';
import { Row, Col } from 'antd';
import { MobileView, BrowserView, isMobile } from 'react-device-detect';

const NCP_CLIENT_ID = process.env.REACT_APP_NCP_CLIENT_ID;
const ncpMapsApi = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${NCP_CLIENT_ID}&callback=callback`;

const Location = () => {
  const mapRef = useRef(null);
  const [map, setMap] = useState();
  const { maps } = useScript('naver', ncpMapsApi);

  if (map) {
  }
  useEffect(() => {
    if (maps) {
      const position = new maps.LatLng(37.370214, 126.806668);
      const m = new maps.Map(mapRef.current, {
        center: position,
        zoom: 17,
      });
      const marker = new maps.Marker({
        position: position,
        map: m,
      });
      setMap(m);
    }
  }, [maps]);

  const mapStyle = isMobile
    ? {
        width: 'calc(600 / 750 * 100vw)',
        height: 'calc(600 / 750 * 100vw)',
        border: '1px solid #f0f0f0',
        margin: 'calc(30 / 750 * 100vw)',
      }
    : {
        width: '800px',
        height: '500px',
        border: '1px solid #f0f0f0',
        margin: '30px',
      };

  const mapDiv = <div id="map" ref={mapRef} style={mapStyle}></div>;

  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu1/intro_bg.jpg)',
          }}
        >
          <Row align="middle">
            <Col span={24} align="middle">
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>병원</b>소개
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>오시는 길</b>
                </h2>
                {mapDiv}
                <p className="subDesc">
                  <p className="desc">경기도 시흥시 능곡번영길 30, 6층</p>
                </p>
              </Col>
              <Col span={10}>
                <p className="subDesc">
                  <b>자가용</b>경기도 시흥시 능곡번영길 30. 센텀시티 6층 (1층
                  농협건물)
                  <br /> 건물 뒤편 지하주차장 이용 가능
                </p>
                <p className="subDesc">
                  <b>버스</b> 마을버스 - 8번, 8-1번, 5번 <br />
                  지선버스 - 26-1번, 32번, 97번, 530번 <br />
                  직행버스 - 3300번, 3301번, 6501번
                </p>
                <p className="subDesc">
                  <b>지하철</b> 서해선 시흥능곡역 3번 출구 (도보 3분)
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top002m.png)',
          }}
        >
          <div className="descWrapM center">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>병원</b>소개
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24} align="middle">
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
              <h2>
                <b>오시는 길</b>
              </h2>
              {mapDiv}
              <p className="subDesc">
                <p className="desc">경기도 시흥시 능곡번영길 30, 6층</p>
              </p>
              <p className="subDesc">
                <b>자가용</b> <br />
                경기도 시흥시 능곡번영길 30. 센텀시티 6층 (1층 농협건물)
                <br /> 건물 뒤편 지하주차장 이용 가능
              </p>
              <p className="subDesc">
                <b>버스</b> <br />
                마을버스 - 8번, 8-1번, 5번 <br />
                지선버스 - 26-1번, 32번, 97번, 530번 <br />
                직행버스 - 3300번, 3301번, 6501번
              </p>
              <p className="subDesc">
                <b>지하철</b> <br />
                서해선 시흥능곡역 3번 출구 (도보 3분)
              </p>
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
};
export default Location;

import React, { useState } from 'react';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { logout } from '../../api/login-api';

const BrowserMenu = ({ menus }) => {
  const navigate = useNavigate();
  const [openKey, setOpenKey] = useState();

  const move = (key) => {
    const menu = menus.find((menu) => String(menu.key) === key);
    if (menu) {
      navigate(menu.path);
    } else {
      for (const { children } of menus) {
        const menu = children?.find((menu) => String(menu.key) === key);
        if (menu) {
          navigate(menu.path);
          break;
        }
      }
    }
  };

  const handleClickMenu = ({ key }) => {
    move(key);
  };
  const handleClickCapture = (evt) => {
    if (evt.nativeEvent.currentTarget.id && openKey?.length) {
      move(openKey);
    }
  };
  const handleOpenChage = ([key]) => {
    setOpenKey(key);
  };

  return (
    <UserContext.Consumer>
      {({ setUser }) => {
        const handleClick = (props) => {
          if (props.key === 'logout') {
            logout().then(() => {
              sessionStorage.removeItem('user');
              setUser({});
              navigate('/');
            });
          } else {
            handleClickMenu(props);
          }
        };
        return (
          <Menu
            theme='dark'
            mode='horizontal'
            onOpenChange={handleOpenChage}
            onClick={handleClick}
            onClickCapture={handleClickCapture}
            items={menus}
          />
        );
      }}
    </UserContext.Consumer>
  );
};
export default BrowserMenu;

import React, { useState } from 'react';
import { UserContext } from './context/UserContext';
import './App.css';
import 'antd/dist/antd.css';
import 'swiper/css/bundle';

import { BrowserRouter } from 'react-router-dom';
import { MobileView, BrowserView } from 'react-device-detect';
import { Layout } from 'antd';

import BrowserLayout from './components/layout/BrowserLayout';
import MobileLayout from './components/layout/MobileLayout';

import Router from './components/Router';
// import designMenus from './constants/design-menus.json';
import defaultClient from './constants/default-client.json';

const { Content } = Layout;

function App() {
  const userString = sessionStorage.getItem('user');
  const [user, setUser] = useState(userString ? JSON.parse(userString) : {});
  const filteredMenus = defaultClient?.menus?.filter(
    ({ auth }) => !auth || user?.isAdmin,
  );
  const menus = [...(filteredMenus ?? [])];

  const content = (
    <Content>
      <Router />
    </Content>
  );

  return (
    <Layout>
      <BrowserRouter>
        <UserContext.Provider value={{ user, setUser }}>
          <BrowserView>
            <BrowserLayout
              client={defaultClient}
              content={content}
              menus={menus}
            />
          </BrowserView>
          <MobileView>
            <MobileLayout
              client={defaultClient}
              content={content}
              menus={menus}
            />
          </MobileView>
        </UserContext.Provider>
      </BrowserRouter>
    </Layout>
  );
}
export default App;

import { isBrowser, BrowserView, MobileView } from 'react-device-detect';
import CarouselStyle from '../styled/carousel/CarouselStyle';

const contentStyle = {
  height: isBrowser ? '760px' : 'calc(700 / 750 * 100vw)',
};

const MainCarousel = () => {
  return (
    <>
      <BrowserView>
        <CarouselStyle autoplay autoplaySpeed={5000}>
          <div>
            <div
              style={{
                height: contentStyle.height,
                backgroundImage: 'url("/images/carousel/main001.jpg")',
              }}
            ></div>
          </div>
          <div>
            <div
              style={{
                height: contentStyle.height,
                backgroundImage: 'url("/images/carousel/main002.jpg")',
              }}
            ></div>
          </div>
          <div>
            <div
              style={{
                height: contentStyle.height,
                backgroundImage: 'url("/images/carousel/main003.jpg")',
              }}
            ></div>
          </div>
        </CarouselStyle>
      </BrowserView>
      <MobileView>
        <CarouselStyle>
          <div>
            <div
              className="slideM01"
              style={{
                height: contentStyle.height,
                backgroundImage: 'url("/images/carousel/mb001.jpg")',
              }}
            >
              <img alt="" src="/images/carousel/mb001_text.png" />
            </div>
          </div>
          <div>
            <div
              className="slideM02"
              style={{
                height: contentStyle.height,
                backgroundImage: 'url("/images/carousel/mb002.jpg")',
              }}
            >
              <img alt="" src="/images/carousel/mb002_text.png" />
            </div>
          </div>
          <div>
            <div
              className="slideM03"
              style={{
                height: contentStyle.height,
                backgroundImage: 'url("/images/carousel/mb003.jpg")',
              }}
            >
              <img alt="" src="/images/carousel/mb003_text.png" />
            </div>
          </div>
        </CarouselStyle>
      </MobileView>
    </>
  );
};

export default MainCarousel;

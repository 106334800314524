import { Image, Row, Col, List, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { MobileView, BrowserView } from 'react-device-detect';

const Gastroscopy = () => {
  const data = [
    '수면(진정) 내시경 검사 후 바로 운전이나 집중이 필요한 정교한 작업은 피하시기 바랍니다.',
    '검사 당일 물을 포함한 일체의 음식을 삼가시기 바랍니다.',
    '혈압, 심장, 천식에 관련된 약은 복용 가능하나 당뇨약과 인슐린은 검사 후 복용해 주십시오.',
  ];
  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu6/gas000.png)',
          }}
        >
          <Row align="middle" style={{ height: '580px' }}>
            <Col span={12}></Col>
            <Col span={12}>
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>내시경</b>센터
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>위내시경</b>이란?
                </h2>
              </Col>
              <Col style={{ width: '400px' }}>
                <p className="subDesc">
                  구강을 통하여 내시경을 삽입하고 식도, 위, 십이지장의 내부를
                  관찰하는 검사입니다.
                </p>
                <p className="subDesc">
                  위내시경은 위암 조기 발견을 위해 가장 중요한 검사이며, 각종
                  위장 질환 (식도염, 식도암, 위염, 위궤양, 십이지장 궤양) 을
                  정확하고 빠르게 진단합니다.
                </p>
              </Col>
              <Col style={{ width: '600px' }}>
                <Image
                  preview={false}
                  src="/images/menu6/cont1_col_circle01.png"
                />
              </Col>
            </Row>
          </div>
          <div
            className="cont_care"
            style={{
              backgroundImage: 'url(/images/menu6/care2_bg.png)',
            }}
          >
            <Row justify="center" align="middle">
              <Col span={10} align="middle">
                <img
                  alt=""
                  className="descImg"
                  src="/images/menu/desc_eng_w.png"
                />
                <h2>
                  시흥드림항유외과
                  <br />
                  <b>내시경</b>의<b> 특장점</b>
                </h2>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <div className="imgGroup recRotate">
                  <Image
                    preview={false}
                    src="/images/menu/cont1_bre_circle02.png"
                  />
                  <Image
                    preview={false}
                    src="/images/menu/cont1_bre_circle03_out.png"
                  />
                  <Image
                    preview={false}
                    src="/images/menu6/cont1_bre_circle03_in.png"
                  />
                  <div className="txtGroup three">
                    <p className="subDesc txt01">
                      숙련된 외과전문의가 <br /> 직접 검사합니다.
                    </p>
                    <p className="subDesc txt02">
                      대학병원급 내시경 <br /> 장비로 진단합니다.
                    </p>
                    <p className="subDesc txt03">
                      검사 중 발견된 용종은 <br /> 바로 제거가 가능합니다.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="cont_way light"
            style={{
              backgroundImage: 'url(/images/menu6/way2_bg.png)',
              backgroundSize: 'cover',
            }}
          >
            <img
              className="vDescImg"
              alt=""
              src="/images/menu/v_desc_eng.png"
            />
            <Row justify="center" align="middle">
              <Col span={6} justify="end">
                <h2>
                  <b>위내시경</b>
                  <br />
                  검사가 <br />
                  <b>필요한</b>경우
                </h2>
              </Col>
              <Col span={8}>
                <p className="recBlock color">
                  <span>
                    <b>01</b>
                    속쓰림, 식후 상복부 불쾌감,흉부 동통, 소화불량, 식도에
                    이물감등의 증상이 있는 경우
                  </span>
                </p>
                <p className="recBlock color">
                  <span>
                    <b>02</b>
                    이전에 위궤양이나 십이지장 궤양으로 치료 후 재검사
                  </span>
                </p>
                <p className="recBlock color">
                  <span>
                    <b>03</b>위 절제 수술 후 주기적인 검진
                  </span>
                </p>
                <p className="recBlock color">
                  <span>
                    <b>04</b>
                    위암의 조기검진 (40세 이상 2년에 1회 )
                  </span>
                </p>
                <p className="recBlock color">
                  <span>
                    <b>05</b>
                    오랜 시간 앉아 있다 자세를 바꿀 때 통증을 많이 느끼는 경우
                  </span>
                </p>
              </Col>
            </Row>
          </div>
          <div className="cont_step">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng_w.png"
                />
                <h2>
                  <b>위내시경</b> 검사순서
                </h2>
              </Col>
              <Col span={24}>
                <Row justify="center">
                  <Col style={{ display: 'flex', gap: '20px' }}>
                    <div className="stepRec short">
                      <span>1. 마취</span>
                      <Image
                        preview={false}
                        src="/images/menu6/gas_cont_001.png"
                      />
                      <p>
                        검사 직전 내시경 삽입으로 인한 통증을 줄이기 위해
                        구강마취제(스프레이)로 목 마취를 합니다.
                      </p>
                    </div>
                    <div className="stepRec short">
                      <span>2. 전처치</span>
                      <Image
                        preview={false}
                        src="/images/menu6/gas_cont_002.png"
                      />
                      <p>
                        검사를 정확히 하기 위해 정상적인 위 운동을 줄여 주는
                        위장관운동 억제제 주사를 주입합니다.
                      </p>
                    </div>
                    <div className="stepRec short">
                      <span>3. 내시경 검사</span>
                      <Image
                        preview={false}
                        src="/images/menu6/gas_cont_003.png"
                      />
                      <p>
                        좌로 누운 자세로 내시경 을 삽입하여 식도, 위, 십이
                        지장까지 관찰합니다.
                      </p>
                    </div>
                    <div className="stepRec short">
                      <span>4. 결과설명</span>
                      <Image
                        preview={false}
                        src="/images/menu6/gas_cont_004.png"
                      />
                      <p>
                        수면이 풀리면 내시경 결과를 듣고 만약 조직검사까지 하면
                        일주일 후 결과를 들을 수 있습니다.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="cont_notice">
            <Row justify="center">
              <Col span={24} align="middle">
                <h2>
                  <b>수면내시경</b> 주의사항
                </h2>
              </Col>
              <Col>
                <div className="noticeRec">
                  <List
                    bordered
                    dataSource={data}
                    renderItem={(item) => (
                      <List.Item>
                        <Typography.Text mark>
                          <CheckOutlined />
                        </Typography.Text>
                        {item}
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top007m.png)',
          }}
        >
          <div className="descWrapM">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>내시경</b>센터
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>위내시경</b>이란?
              </h2>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={14}>
              <p className="subDesc">
                구강을 통하여 내시경을 삽입하고 식도, 위, 십이지장의 내부를
                관찰하는 검사입니다. 위내시경은 위암 조기 발견을 위해 가장
                중요한 검사이며, 각종 위장 질환 (식도염, 식도암, 위염, 위궤양,
                십이지장 궤양) 을 정확하고 빠르게 진단합니다.
              </p>
            </Col>
            <Col span={10}>
              <img className="cirImg" alt="" src="/images/mobile/cir011m.png" />
            </Col>
          </Row>
        </div>
        <div className="contCareM">
          <Row justify="center" align="middle">
            <Col span={24}>
              <img
                className="descImg"
                alt=""
                src="/images/menu/desc_eng_w.png"
              />
            </Col>
            <Col span={24}>
              <h2>
                시흥드림항유외과
                <br />
                <b>내시경</b>의<b> 특장점</b>
              </h2>
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  <div
                    className="careCir"
                    style={{
                      backgroundImage: 'url(/images/mobile/care_col_cir01.png)',
                    }}
                  >
                    <div
                      className="careCirOut"
                      style={{
                        backgroundImage: 'url(/images/menu/care_hem_cir02.png)',
                      }}
                    />
                    <div
                      className="careCirRotate"
                      style={{
                        backgroundImage:
                          'url(/images/menu/care_hem_cir03_rotate.png)',
                      }}
                    />
                  </div>
                </Col>
                <Col span={24} style={{ display: 'flex' }}>
                  <div className="careText">
                    <em>1.</em>
                    <p>대학병원급 내시경 장비로 진단합니다.</p>
                  </div>
                  <div className="careText">
                    <em>2.</em>
                    <p>검사 중 발견된 용종은 바로 제거가 가능합니다.</p>
                  </div>
                  <div className="careText">
                    <em>3.</em>
                    <p>숙련된 외과전문의가 직접 검사합니다.</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="contWayM color" style={{ backgroundColor: '#fff' }}>
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>위내시경</b>검사가 <br />
                <b>필요한</b> 경우
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <p className="rec01">
                <span>
                  <b>01</b>속쓰림, 식후 상복부 불쾌감, 흉부 동통, 소화불량,
                  식도에 이물감등의 증상이 있는 경우
                </span>
              </p>
              <p className="rec03">
                <span>
                  <b>02</b>이전에 위궤양이나 십이지장 궤양으로 치료 후 재검사
                  <b>03</b>위 절제 수술 후 주기적인 검진
                </span>
              </p>
              <p className="rec02">
                <span>
                  <b>04</b>위암의 조기검진 (40세 이상 2년에 1회 )<b>05</b>오랜
                  시간 앉아 있다 자세를 바꿀 때 통증을 많이 느끼는 경우
                </span>
              </p>
              <p className="rec01">
                <span>
                  <b>05</b>
                  오랜 시간 앉아 있다 자세를 바꿀 때 통증을 많이 느끼는 경우
                </span>
              </p>
            </Col>
          </Row>
        </div>
        <div className="contStepM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                위내시경 <b>검사순서</b>
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <div className="stepRec short">
                <span>1. 마취</span>
                <img alt="" src="/images/menu6/gas_cont_001.png" />
                <p>
                  검사 직전 내시경 삽입으로 인한 통증을 줄이기 위해
                  구강마취제(스프레이)로 목 마취를 합니다.
                </p>
              </div>
              <div className="stepRec short">
                <span>2. 전처치</span>
                <img alt="" src="/images/menu6/gas_cont_002.png" />
                <p>
                  검사를 정확히 하기 위해 정상적인 위 운동을 줄여 주는
                  위장관운동 억제제 주사를 주입합니다.
                </p>
              </div>
              <div className="stepRec short">
                <span>3. 내시경 검사</span>
                <img alt="" src="/images/menu6/gas_cont_003.png" />
                <p>
                  좌로 누운 자세로 내시경 을 삽입하여 식도, 위, 십이 지장까지
                  관찰합니다.
                </p>
              </div>
              <div className="stepRec short">
                <span>4. 결과설명</span>
                <img alt="" src="/images/menu6/gas_cont_004.png" />
                <p>
                  수면이 풀리면 내시경 결과를 듣고 만약 조직검사까지 하면 일주일
                  후 결과를 들을 수 있습니다.
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="contNoticeM">
          <Row justify="center" align="middle">
            <Col span={24}>
              <h2>
                <b>수면내시경</b> 주의사항
              </h2>
            </Col>
            <Col>
              <div className="noticeRec">
                <List
                  bordered
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item>
                      <Typography.Text mark>
                        <CheckOutlined />
                      </Typography.Text>
                      {item}
                    </List.Item>
                  )}
                />
              </div>
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
};
export default Gastroscopy;

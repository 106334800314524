import styled from 'styled-components';

const TableHeader = styled.div`
  & {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 14px;
    span {
      display: block;
      b {
        padding: 0 2px 0 6px;
      }
    }
    .ant-btn {
    }
  }
`;

export default TableHeader;

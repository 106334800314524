import styled from 'styled-components';

const TableWrapper = styled.div`
  & {
    .ant-btn-primary {
      background: #59bec9;
      border-color: #59bec9;
    }
    .ant-pagination-item-active {
      border-color: #59bec9;
      a {
        color: #59bec9;
      }
    }
  }
`;

export default TableWrapper;

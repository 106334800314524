import { Fragment } from 'react';
import { Table, Descriptions, List, Typography, Row, Col, Image } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { BrowserView, MobileView } from 'react-device-detect';

const dataPageTable = {
  cols: [
    {
      title: '비고',
      dataIndex: 'label',
      align: 'center',
      width: '20%',
    },
    {
      title: '일반 기업체',
      dataIndex: 'text',
    },
  ],
};

const columns2 = [
  {
    key: 1,
    title: '1차',
    dataIndex: 'first',
    render: (texts) => (
      <span dangerouslySetInnerHTML={{ __html: texts.join('<br/>') }}></span>
    ),
  },
  {
    key: 2,
    title: '2차',
    dataIndex: 'second',
    render: (texts) => (
      <span dangerouslySetInnerHTML={{ __html: texts.join('<br/>') }}></span>
    ),
  },
];

const data2 = [
  {
    key: 1,
    first: ['기초신체검사', '문진', '혈액검사', '소변검사', '방사선검사'],
    second: [
      '1차 검진 결과 분석 상담',
      '흉부질환검사',
      '고혈압성질환검사',
      '당뇨질환검사',
      '신장질환검사',
      '빈혈검사',
    ],
  },
];

const Employee = ({ employee }) => {
  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu6/nmc001.png)',
          }}
        >
          <Row align="middle" style={{ height: '580px' }}>
            <Col span={12}></Col>
            <Col span={12}>
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>건강검진</b>센터
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center">
              <Col span={10} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>채용/직장인</b>검진
                </h2>
                <p className="desc">
                  채용 신체검사는 기업 및 공공기관 등이 신규 채용함에 있어
                  직무를 담당할 수 있는 신체/정신 건강상의 능력을 평가하기
                  위하여 실시하는 검사입니다.
                </p>
              </Col>
            </Row>
            <Row justify="center" align="middle">
              <Col style={{ width: '400px' }}>
                <p className="subDesc">
                  입사 시 기업체에 제출할 채용 검진뿐만 아니라 일반 기업체에서
                  채용한 직원을 대상으로 실시하는 건강검진을 말합니다.
                </p>
                <p className="subDesc">
                  많은 기업에서 복리후생의 하나로 시행되고 있는 채용/직장인
                  검진은 기업 발전의 중심인 기업 임직원의 건강 유지 및 관리를
                  목적으로 시행되고 있습니다.
                </p>
                <p className="subDesc">
                  따라서, 공무원 직군과 기업이 원하는 검사항목에 특이사항이
                  없는지 확인한 후에 검사를 의뢰하는 것이 좋으며 직장가입자 중
                  비사무직 근로자는 매년, 사무직 근로자는 2년마다 1회 직장
                  검진을 받아야 합니다.
                </p>
              </Col>
              <Col style={{ width: '600px' }}>
                <Image
                  preview={false}
                  src="/images/menu6/cont1_nmc_circle01.png"
                />
              </Col>
            </Row>
          </div>
          <div className="cont_table">
            <Row justify="center" gutter={[0, 50]}>
              <Col span={16}>
                <Table
                  size="small"
                  columns={dataPageTable.cols.map((column, key) => ({
                    ...column,
                    key,
                    render: (__html) => (
                      <span dangerouslySetInnerHTML={{ __html }} />
                    ),
                  }))}
                  dataSource={employee[1].data.map((data, key) => ({
                    ...data,
                    key,
                  }))}
                  pagination={false}
                />
              </Col>
              <Col span={16}>
                <h1>{employee[2].title}</h1>
                <Table
                  columns={columns2}
                  dataSource={data2}
                  rowKey="key"
                  size="small"
                  pagination={false}
                />
              </Col>
            </Row>
          </div>
          <div className="cont_notice">
            <Row justify="center">
              <Col span={24} align="middle">
                <h2 dangerouslySetInnerHTML={{ __html: employee[3].title }} />
              </Col>
              <Col>
                <div className="noticeRec">
                  <List
                    bordered
                    dataSource={employee[3].items}
                    renderItem={(item) => (
                      <List.Item>
                        <Typography.Text mark>
                          <CheckOutlined />
                        </Typography.Text>
                        {item}
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top006m.png)',
          }}
        >
          <div className="descWrapM">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>건강검진</b>센터
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={20}>
              <h2>
                <b>채용/직장인</b>검진
              </h2>
              <p className="desc">
                채용 신체검사는 기업 및 공공기관 등이 신규 채용함에 있어 직무를
                담당할 수 있는 신체/정신 건강상의 능력을 평가하기 위하여
                실시하는 검사입니다.
              </p>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={14}>
              <p className="subDesc">
                입사 시 기업체에 제출할 채용 검진뿐만 아니라 일반 기업체에서
                채용한 직원을 대상으로 실시하는 건강검진을 말합니다.
              </p>
              <p className="subDesc">
                많은 기업에서 복리후생의 하나로 시행되고 있는 채용/직장인 검진은
                기업 발전의 중심인 기업 임직원의 건강 유지 및 관리를 목적으로
                시행되고 있습니다.
              </p>
              <p className="subDesc">
                따라서, 공무원 직군과 기업이 원하는 검사항목에 특이사항이 없는지
                확인한 후에 검사를 의뢰하는 것이 좋으며 직장가입자 중 비사무직
                근로자는 매년, 사무직 근로자는 2년마다 1회 직장 검진을 받아야
                합니다.
              </p>
            </Col>
            <Col span={10}>
              <img className="cirImg" alt="" src="/images/mobile/cir010m.png" />
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
};

export default Employee;

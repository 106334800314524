const data = [
  {
    pageType: 'PageDescription',
    items: [
      '채용 신체검사는 기업 및 공공기관 등이 신규 채용함에 있어 직무를 담당할 수 있는 신체/정신 건강상의 능력을 평가하기 위하여 실시하는 검사입니다.',
      '입사 시 기업체에 제출할 채용 검진뿐만 아니라 일반 기업체에서 채용한 직원을 대상으로 실시하는 건강검진을 말합니다.',
      '많은 기업에서 복리후생의 하나로 시행되고 있는 채용/직장인 검진은 기업 발전의 중심인 기업 임직원의 건강 유지 및 관리를 목적으로 시행되고 있습니다. ',
      '따라서, 공무원 직군과 기업이 원하는 검사항목에 특이사항이 없는지 확인한 후에 검사를 의뢰하는 것이 좋으며 직장가입자 중 비사무직 근로자는 매년, 사무직 근로자는 2년마다 1회 직장 검진을 받아야 합니다.',
    ],
  },
  {
    pageType: 'PageTable',

    cols: [
      {
        title: '비고',
        dataIndex: 'label',
        align: 'center',
      },
      {
        title: '일반 기업체',
        dataIndex: 'text',
      },
    ],
    data: [
      {
        label: '대상',
        text: '각 업체에서 입사시에 요구되는 신체검사서 발급을 원하는 자',
      },
      { label: '용도', text: '신규 채용 시에 건강증빙서류로 제출' },
    ],
  },
  {
    pageType: 'PageDescriptions',
    title: '채용/직장인검진 PROCESS',
    items: [
      {
        label: '1차',
        texts: ['기초신체검사', '문진', '혈액검사', '소변검사', '방사선검사'],
      },
      {
        label: '2차',
        texts: [
          '1차 검진 결과 분석 상담',
          '흉부질환검사',
          '고혈압성질환검사',
          '당뇨질환검사',
          '신장질환검사',
          '빈혈검사',
        ],
      },
    ],
  },
  {
    pageType: 'PageContNoticeM',
    title: '<b>채용/직장인검진</b> 주의사항',
    items: [
      '검사 전날은 과음이나 무리한 활동은 피하도록 합니다. ',
      '검사 전까지 최소 6시간 정도의 금식이 필요합니다.',
      '검사 시간은 1시간 정도 소요되며, 검사 내용에 따라 조금 더 걸릴 수 있습니다. ',
      '예방접종 기록표가 필요한 경우 가지고 오셔야 하며 없을 경우 내원 후 접종하셔야 합니다. ',
    ],
  },
];

export default data;

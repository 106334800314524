import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Spin } from 'antd';
import { getTokenKakao } from '../../api/login-api';
import { UserContext } from '../../context/UserContext';

const Kakao = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  return (
    <UserContext.Consumer>
      {({ user, setUser }) => {
        //dev
        if (!user.id) {
          getTokenKakao(search).then((res) => {
            if (res?.error) {
              alert(res.error ?? res.error_description);
              navigate('/login');
            } else if (res) {
              sessionStorage.setItem('user', JSON.stringify(res));
              setUser(res);
              navigate('/');
            }
          });
        }
        return (
          //temp
          <Spin tip='Loading...'>
            <Alert message='KAKAO' description='KAKAO LOGIN' type='info' />
          </Spin>
        );
      }}
    </UserContext.Consumer>
  );
};
export default Kakao;

import { Image, Table, Row, Col, List, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const data = [
  {
    key: 1,
    divis: '지역가입자',
    target: '세대주 및 20세 이상 세대원. 2년에 1회',
    cycle: '2년에 1회',
  },
  {
    key: 2,
    divis: '직장피부양자',
    target: '20세 이상 피부양자. 2년에 1회',
    cycle: '2년에 1회',
  },
  {
    key: 3,
    divis: '직장가입자',
    target: '사무직 근로자 중 격년제 실시에 다른 대상자, 비사무직 대상자 전체',
    cycle: '사무직 - 2년에 1회<br />비사무직 - 1년에 1회',
  },
  {
    key: 4,
    divis: '의료급여수급권자',
    target:
      '19세~63세 의료급여 수급권자<br />65세 이상은 노인복지법에 의한 검진실시로 제외<br />*66세 이상은 “의료급여 생애전환기검진” 실시',
    cycle: '2년에 1회',
  },
];

const data2 = [
  {
    key: 1,
    divis: '공통항목',
    part: '문진 및 체위검사, 흉부방사선 검사, 혈액검사(혈색소, 공복혈당, AST, ALT, γ-GTP, 혈청크레아티닌, e-GFR), 요검사, 구강검진 등',
    part2: '2년에 1회',
    rowSpan: 1,
  },
  {
    key: 2,
    divis: '성,연령별 항목',
    part: '혈액검사(이상지질혈증 검사) *총콜레스테롤, HDL콜레스테롤, 트리글리세라이드, LDL콜레스테롤',
    part2: '남성24세 이상, 여성40세 이상, 4년마다',
    rowSpan: 8,
  },
  {
    key: 3,
    divis: '성,연령별 항목',
    part: 'B형간염',
    part2: '40세',
    rowSpan: 0,
  },
  {
    key: 4,
    divis: '성,연령별 항목',
    part: '골다공증',
    part2: '54세, 66세 여성',
    rowSpan: 0,
  },
  {
    key: 5,
    divis: '성,연령별 항목',
    part: '인지기능장애',
    part2: '66세 이상(2년에 1회)',
    rowSpan: 0,
  },
  {
    key: 6,
    divis: '성,연령별 항목',
    part: '우울증',
    part2: '20, 30, 40, 50, 60, 70세 (해당 연령대에 1회)',
    rowSpan: 0,
  },
  {
    key: 7,
    divis: '성,연령별 항목',
    part: '생활습관평가',
    part2: '40, 50, 60, 70세',
    rowSpan: 0,
  },
  {
    key: 8,
    divis: '성,연령별 항목',
    part: '노인신체기능',
    part2: '66, 70, 80세',
    rowSpan: 0,
  },
  {
    key: 9,
    divis: '성,연령별 항목',
    part: '치면세균막검사',
    part2: '40세',
    rowSpan: 0,
  },
];
const columns = [
  {
    key: 1,
    title: '구분',
    dataIndex: 'divis',
    width: '20%',
  },
  {
    key: 2,
    title: '대상',
    dataIndex: 'target',
    render: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
  },
  {
    key: 3,
    title: '주기',
    dataIndex: 'cycle',
    width: '15%',
    render: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
  },
];

const columns2 = [
  {
    key: 1,
    title: '구분',
    dataIndex: 'divis',
    width: '20%',
    onCell: ({ rowSpan }) => ({ rowSpan }),
  },
  {
    key: 2,
    title: '검진항목',
    dataIndex: 'part',
    colSpan: 2,
  },
  {
    key: 3,
    dataIndex: 'part2',
    colSpan: 0,
  },
];

const columns4 = [
  {
    key: 1,
    title: '결과통보',
    dataIndex: 'result',
  },
  {
    key: 2,
    title: '비용부담',
    dataIndex: 'cost',
    render: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
  },
];

const data4 = [
  {
    key: 1,
    result:
      '검진결과는 암검진 수검일 이후 15일 이내에 검진받으신 분의 주소지(우편/메일/모바일)로 발송해 드립니다.',
    cost: '공단 90%, 수검자 10% 부담<br />(단, 대장암 및 자궁경부암은 전액 공단 부담)<br />*국가암건진사업 대상자, 의료급여수급권자는 수검자 부담 없음',
  },
];

const data3 = [
  '검진 전날 저녁 9시 이후에는 금식하시기 바랍니다.',
  '검진 당일 아침 식사는 물론 물, 커피, 우유, 담배, 주스, 껌 등 일체의 음식을 삼가야 합니다.',
  '되도록 오전 중에 검진을 받되, 오후에 검진받으실 분은 검사 전 최소 8시간 이상의 공복상태를 유지해야 합니다. (공복이 아닌 상태에서 검진 시 검사결과가 다르게 나타날 수 있음)',
];

const Nmc = () => {
  return (
    <>
      <div
        className="pageTop animation"
        style={{
          backgroundImage: 'url(/images/menu6/nmc001.png)',
        }}
      >
        <Row align="middle" style={{ height: '580px' }}>
          <Col span={12}></Col>
          <Col span={12}>
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>건강검진</b>센터
            </h2>
            <p className="subDesc">
              10년동안 10,000건 이상의 외과수술 경험! <br />
              시흥드림항유외과를 소개합니다.
            </p>
          </Col>
        </Row>
      </div>
      <div className="content">
        <div className="contBox">
          <div className="recRotate toRight">
            <img alt="" src="/images/menu/rec_circle_text.png" />
          </div>
        </div>
        <div className="cont_isIt">
          <Row justify="center" align="middle">
            <Col align="middle">
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
              <h2>
                공단<b>건강검진</b>
              </h2>
              <p className="desc">
                <font>시흥드림항유외과</font>에서는 국민건강보험공단에서 <br />
                실시하는 검진을 편안하게 받으실 수 있습니다.
              </p>
              <Image
                preview={false}
                src="/images/menu6/cont1_nmc_circle01.png"
              />
            </Col>
          </Row>
        </div>
        <div className="cont_table">
          <Row justify="center" gutter={[0, 50]}>
            <Col span={16}>
              <h1>검진대상</h1>
              <Table
                columns={columns}
                dataSource={data}
                rowKey="key"
                size="small"
                pagination={false}
              />
            </Col>
            <Col span={16}>
              <h1>검진항목</h1>
              <h4>
                - 검진항목 건강검진표는 우편 및 E-mail로 발송 및 수령하며
                검진표를 분실 또는 수령치 못한 경우에는 가까운 지사에서 재발급을
                받을 수 있습니다.
                <br />- 직장가입자의 경우 사업장으로 통보됩니다.
              </h4>
              <Table
                columns={columns2}
                dataSource={data2}
                size="small"
                pagination={false}
              />
            </Col>
          </Row>
        </div>
        <div
          className="cont_way"
          style={{
            backgroundImage: 'url(/images/menu6/cont_bg_00.jpg)',
            backgroundColor: '#d9d9d9',
          }}
        >
          <Row justify="center">
            <Col span={12} align="middle" style={{ paddingTop: '300px' }}>
              <h2>
                <b>암 검진</b>의 종류
              </h2>
              <p className="subDescRound">
                <b>위암 검진 대상자</b>는 <b>전화로 예약</b>하거나{' '}
                <b>암 검진표</b> 또는 <br />
                <b>신분증</b>을 가지고 방문하여 <b>사전 예약</b>을 할 수
                있습니다.
              </p>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={2}>
              <p className="desc">
                <b>위암검진</b>
              </p>
            </Col>
            <Col span={10} align="left">
              <p className="desc">
                만 40세 이상 남녀는 증상이 없어도 2년마다 위내시경 검사를
                받습니다.
                <br /> - 위암 선정특례적용자는 검진대상에서 유예.
                <br />
                (본인 검진희망 시 대상 추가등록 가능)
              </p>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={2}>
              <p className="desc">
                <b>대장암검진</b>
              </p>
            </Col>
            <Col span={10} align="left">
              <p className="desc">
                만 50세 이상 남녀는 1년마다 분변잠혈검사(FOBT)를 받은 후
                양성판정자는 대장내시경 검사를 받습니다. <br />- 대장암
                선정특례적용자 또는 국가대장암건진 대장내시경검사 수검자는
                검진대상에서 5년간 유예.
                <br /> (본인 검진희망 시 대상 추가등록 가능)
              </p>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={2}>
              <p className="desc">
                <b>간암검진</b>
              </p>
            </Col>
            <Col span={10} align="left">
              <p className="desc">
                만 40세 이상 남녀 중 간암발생 고위험군에 해당되는 아래 대상자는
                상반기 1회, 하반기 1회로 6개월마다 간 초음파검사와
                혈액검사(혈청알파태아단백검사)를 받습니다. <br />- 간암
                산정특례적용자 검진대상에서 유예. <br />
                (본인 검진희망 시 대상 추가등록가능)
              </p>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24} align="middle">
              <img
                className="descImgEnd"
                alt=""
                src="/images/menu/desc_eng_w.png"
              />
            </Col>
          </Row>
        </div>
        <div className="cont_notice">
          <Row justify="center">
            <Col span={24} align="middle">
              <h2>
                검진 전 <b>주의사항</b>
              </h2>
            </Col>
            <Col>
              <div className="noticeRec">
                <List
                  bordered
                  dataSource={data3}
                  renderItem={(item) => (
                    <List.Item>
                      <Typography.Text mark>
                        <CheckOutlined />
                      </Typography.Text>
                      {item}
                    </List.Item>
                  )}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="cont_table" gutter={[0, 50]}>
          <Row justify="center">
            <Col span={16}>
              <h1>결과통보 및 비용부담</h1>
              <Table
                columns={columns4}
                dataSource={data4}
                rowKey="key"
                size="small"
                pagination={false}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default Nmc;

import { Row, Col, Image } from 'antd';
import { MobileView, BrowserView } from 'react-device-detect';

const AnalFistula = () => {
  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu2/hem000.png)',
          }}
        >
          <Row align="middle">
            <Col span={12}></Col>
            <Col span={12}>
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>대장항문</b>클리닉
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>항문주위농양</b>/<b>치루</b>란?
                </h2>
              </Col>
              <Col style={{ width: '400px' }}>
                <p className="subDesc">
                  항문주위농양은 항문샘의 감염으로 염증이 발생하여
                  항문주위조직에 고름이 생기는 것입니다.
                </p>
                <p className="subDesc">
                  치루는 항문샘의 감염으로 항문 안쪽과 바깥쪽 피부사이에 통로가
                  생긴 상태입니다.
                </p>
              </Col>
              <Col style={{ width: '600px' }}>
                <Image
                  preview={false}
                  src="/images/menu2/cont1_ana_circle01.png"
                />
              </Col>
            </Row>
          </div>
          <div className="cont_step">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng_w.png"
                />
                <h2>
                  <b>항문주위농양</b>/<b>치루</b> 발생과정
                </h2>
              </Col>
              <Col span={24}>
                <Row justify="center">
                  <Col style={{ display: 'flex', gap: '20px' }}>
                    <div className="stepRec">
                      <span>1단계</span>
                      <Image
                        preview={false}
                        src="/images/menu2/ana_cont_001.png"
                      />
                      <p>항문샘에서 염증이 시작됨</p>
                    </div>
                    <div className="stepRec">
                      <span>2단계</span>
                      <Image
                        preview={false}
                        src="/images/menu2/ana_cont_002.png"
                      />
                      <p>항문 주위 농양이 형성됨</p>
                    </div>
                    <div className="stepRec">
                      <span>3단계</span>
                      <Image
                        preview={false}
                        src="/images/menu2/ana_cont_003.png"
                      />
                      <p>농양이 항문주위 피부로 터져 통로가 생김</p>
                    </div>
                    <div className="stepRec">
                      <span>4단계</span>
                      <Image
                        preview={false}
                        src="/images/menu2/ana_cont_004.png"
                      />
                      <p>치루 형성</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className="cont_care"
            style={{
              backgroundImage: 'url(/images/menu/care_bg.png)',
            }}
          >
            <Row justify="center">
              <Col span={24} align="middle">
                <img
                  alt=""
                  className="descImg"
                  src="/images/menu/desc_eng_w.png"
                />
                <Row justify="center">
                  <Col
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '30px',
                    }}
                  >
                    <div className="careText">
                      <div>
                        <em>증상 1.</em>
                        <span>
                          <font>
                            <b>분비물</b>
                          </font>
                        </span>
                        <p>
                          수 개월 길게는 수 년 동안 항문주위에서 고름과 같은
                          분비물이 나옵니다.
                        </p>
                      </div>
                      <div>
                        <em>증상 2.</em>
                        <span>
                          <font>
                            <b>외공</b>
                          </font>
                        </span>
                        <p>
                          항문주변에 단단하게 치루관이 만져지거나 외공을 볼 수
                          있습니다.
                        </p>
                      </div>
                    </div>
                    <div
                      className="careCir"
                      style={{
                        backgroundImage:
                          'url(/images/menu2/care_ana_cir01.png)',
                      }}
                    >
                      <div
                        className="careCirOut"
                        style={{
                          backgroundImage:
                            'url(/images/menu/care_hem_cir02.png)',
                        }}
                      />
                      <div
                        className="careCirRotate"
                        style={{
                          backgroundImage:
                            'url(/images/menu/care_hem_cir03_rotate.png)',
                        }}
                      />
                    </div>
                    <div className="careText">
                      <em>증상 3.</em>
                      <span>
                        <font>
                          <b>통증</b>
                        </font>
                      </span>
                      <p>
                        항문주위가 붓고 열이 나며 오한이 느껴지기도 합니다. 배변
                        시 통증이 있거나 가만히 있어도 통증이 지속되는 경우도
                        있습니다.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className="cont_way"
            style={{
              backgroundImage: 'url(/images/menu/how_bg.png)',
              backgroundColor: '#d9d9d9',
            }}
          >
            <Row justify="center">
              <Col span={10} align="middle" style={{ paddingTop: '300px' }}>
                <h2>
                  <b>치루</b>의 치료방법
                </h2>
                <p className="desc">
                  <b>단순치루</b>일 경우 <b>치루관을 절개하거나 모두 절제</b>{' '}
                  하는 방법을 사용할 수 있지만, 치루관이 여러 개 생긴{' '}
                  <b>복잡치루</b>를 수술할 때는 괄약근 절제 범위가 커질 수 있기
                  때문에 <b>두 번에 나눠 수술을 진행</b> 하기도 합니다.
                </p>
                <p className="subDescRound">
                  <b>치루</b> 수술법
                </p>
              </Col>
            </Row>
            <Row justify="center">
              <Col style={{ display: 'flex' }}>
                <div className="wayCir">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon05.png)',
                    }}
                  ></span>
                  <p>치루절개술</p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                  <div
                    className="plusIcon"
                    style={{
                      backgroundImage: 'url(/images/menu/way_plus.png)',
                    }}
                  />
                </div>
                <div className="wayCir">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon06.png)',
                    }}
                  ></span>
                  <p>치루절제술</p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                  <div
                    className="plusIcon"
                    style={{
                      backgroundImage: 'url(/images/menu/way_plus.png)',
                    }}
                  />
                </div>
                <div className="wayCir">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon07.png)',
                    }}
                  ></span>
                  <p>세톤법</p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                  <div
                    className="plusIcon"
                    style={{
                      backgroundImage: 'url(/images/menu/way_plus.png)',
                    }}
                  />
                </div>
                <div className="wayCir">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon05.png)',
                    }}
                  ></span>
                  <p>괄약근 보존술식</p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={10}>
                <p className="desc">
                  치루 수술은 항문을 조이는 항문괄약근 부분으로 자르는데 간혹
                  괄약근을 지나치게 많이 자르면 변실금이 유발될 수 있어{' '}
                  <b>외과전문의의 판단</b>이 중요합니다.
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={24} align="middle">
                <img
                  className="descImgEnd"
                  alt=""
                  src="/images/menu/desc_eng_w.png"
                />
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top001m.png)',
          }}
        >
          <div className="descWrapM">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>대장항문</b>클리닉
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
              <h2>
                <b>항문주위농양</b>/<b>치루</b>란?
              </h2>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={14}>
              <p className="subDesc">
                항문주위농양은 항문샘의 감염으로 염증이 발생하여 항문주위조직에
                고름이 생기는 것입니다.
                <br />
                치루는 항문샘의 감염으로 항문 안쪽과 바깥쪽 피부사이에 통로가
                생긴 상태입니다.
              </p>
            </Col>
            <Col span={10}>
              <img className="cirImg" alt="" src="/images/mobile/cir002m.png" />
            </Col>
          </Row>
        </div>
        <div className="contStepM">
          <Row>
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
              <h2>
                <b>항문주위농양</b>/<b>치루</b> 발생과정
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col style={{ display: 'flex' }}>
              <div className="stepRec">
                <span>1단계</span>
                <img alt="" src="/images/menu2/ana_cont_001.png" />
                <p>항문샘에서 염증이 시작됨</p>
              </div>
              <div className="stepRec">
                <span>2단계</span>
                <img alt="" src="/images/menu2/ana_cont_002.png" />
                <p>항문 주위 농양이 형성됨</p>
              </div>
            </Col>
            <Col style={{ display: 'flex' }}>
              <div className="stepRec">
                <span>3단계</span>
                <img alt="" src="/images/menu2/ana_cont_003.png" />
                <p>농양이 항문주위 피부로 터져 통로가 생김</p>
              </div>
              <div className="stepRec">
                <span>4단계</span>
                <img alt="" src="/images/menu2/ana_cont_004.png" />
                <p>치루 형성</p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="contCareM">
          <Row justify="center" align="middle">
            <Col span={24}>
              <img
                className="descImg"
                alt=""
                src="/images/menu/desc_eng_w.png"
              />
              <h2>
                <b>항문주위농양</b>/<b>치루</b>의 원인
              </h2>
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  <div
                    className="careCir"
                    style={{
                      backgroundImage: 'url(/images/menu2/care_ana_cir01.png)',
                    }}
                  >
                    <div
                      className="careCirOut"
                      style={{
                        backgroundImage: 'url(/images/menu/care_hem_cir02.png)',
                      }}
                    />
                    <div
                      className="careCirRotate"
                      style={{
                        backgroundImage:
                          'url(/images/menu/care_hem_cir03_rotate.png)',
                      }}
                    />
                  </div>
                </Col>
                <Col span={24} style={{ display: 'flex' }}>
                  <div className="careText">
                    <em>증상 1.</em>
                    <span>
                      <font>
                        <b>분비물</b>
                      </font>
                    </span>
                    <p>
                      수 개월 길게는 수 년 동안 항문주위에서 고름과 같은
                      분비물이 나옵니다.
                    </p>
                  </div>
                  <div className="careText">
                    <em>증상 2.</em>
                    <span>
                      <font>
                        <b>외공</b>
                      </font>
                    </span>
                    <p>
                      항문주변에 단단하게 치루관이 만져지거나 외공을 볼 수
                      있습니다.
                    </p>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="careText single">
                    <em>증상 3.</em>
                    <span>
                      <font>
                        <b>통증</b>
                      </font>
                    </span>
                    <p>
                      항문주위가 붓고 열이 나며 오한이 느껴지기도 합니다. 배변
                      시 통증이 있거나 가만히 있어도 통증이 지속되는 경우도
                      있습니다.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div
          className="contWayM"
          style={{
            backgroundColor: '#d9d9d9',
          }}
        >
          <img className="causeBg01" alt="" src="/images/mobile/bg002m.png" />
          <Row justify="center">
            <Col span={20}>
              <h2>
                <b>치루</b>는 <b>수술</b>로 <b>치료해야</b> 합니다.
              </h2>
              <p className="subDesc">
                <b>단순치루</b>일 경우 <b>치루관을 절개하거나 모두 절제</b> 하는
                방법을 사용할 수 있지만, 치루관이 여러 개 생긴 <b>복잡치루</b>를
                수술할 때는 괄약근 절제 범위가 커질 수 있기 때문에{' '}
                <b>두 번에 나눠 수술을 진행</b> 하기도 합니다.
              </p>
            </Col>
            <Col span={24} align="middle">
              <p className="subDescRound">
                <b>치루</b>수술법
              </p>
            </Col>
          </Row>
          <Row justify="center">
            <Col style={{ display: 'flex' }}>
              <div className="wayCir">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon05.png)',
                  }}
                ></span>
                <p>치루절개술</p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
                <div
                  className="plusIcon"
                  style={{
                    backgroundImage: 'url(/images/menu/way_plus.png)',
                  }}
                />
              </div>
              <div className="wayCir">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon06.png)',
                  }}
                ></span>
                <p>치루절제술</p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
              </div>
            </Col>
            <Col style={{ display: 'flex' }}>
              <div className="wayCir">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon07.png)',
                  }}
                ></span>
                <p>세톤법</p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
                <div
                  className="plusIcon"
                  style={{
                    backgroundImage: 'url(/images/menu/way_plus.png)',
                  }}
                />
              </div>
              <div className="wayCir">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon05.png)',
                  }}
                ></span>
                <p>괄약근 보존술식</p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
              </div>
            </Col>
            <Col span={22}>
              <p className="subDesc">
                치루 수술은 항문을 조이는 항문괄약근 부분으로 자르는데 간혹
                괄약근을 지나치게 많이 자르면 변실금이 유발될 수 있어{' '}
                <b>외과전문의의 판단</b>이 중요합니다.
              </p>
            </Col>
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
};
export default AnalFistula;

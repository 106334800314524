import styled from 'styled-components';
import { Carousel } from 'antd';

const CarouselStyle = styled(Carousel)`
  --size: ${750};
  & {
    .slick-slide {
      > div {
        > div {
          > div {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            &.slideM01,
            &.slideM02,
            &.slideM03 {
              position: relative;
              img {
                position: absolute;
              }
            }
            &.slideM01 {
              img {
                width: calc(400 / var(--size) * 100vw);
                bottom: calc(120 / var(--size) * 100vw);
                right: calc(80 / var(--size) * 100vw);
              }
            }
            &.slideM02 {
              img {
                width: calc(250 / var(--size) * 100vw);
                top: calc(80 / var(--size) * 100vw);
                left: calc(80 / var(--size) * 100vw);
              }
            }
            &.slideM03 {
              img {
                width: calc(400 / var(--size) * 100vw);
                bottom: calc(120 / var(--size) * 100vw);
                left: calc(80 / var(--size) * 100vw);
              }
            }
          }
        }
      }
    }
  }
  .slick-dots {
    li {
      width: 170px;
      & {
        button {
          border-radius: 0;
        }
      }
      &.slick-active {
        width: 170px;
      }
    }
  }
`;

export default CarouselStyle;

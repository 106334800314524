import { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { Row, Col, Image } from 'antd';
import { MobileView, BrowserView } from 'react-device-detect';
const data = {
  rows: [
    {
      cols: [
        {
          src: '/images/menu2/etc_cont_001.png',
          hover: '/images/menu2/etc_cont_001_ov.png',
        },
        {
          src: '/images/menu2/etc_cont_002.png',
          hover: '/images/menu2/etc_cont_002_ov.png',
        },
      ],
    },
    {
      cols: [
        {
          src: '/images/menu2/etc_cont_003.png',
          hover: '/images/menu2/etc_cont_003_ov.png',
        },
        {
          src: '/images/menu2/etc_cont_004.png',
          hover: '/images/menu2/etc_cont_004_ov.png',
        },
      ],
    },
    {
      cols: [
        {
          src: '/images/menu2/etc_cont_005.png',
          hover: '/images/menu2/etc_cont_005_ov.png',
        },
        {
          src: '/images/menu2/etc_cont_006.png',
          hover: '/images/menu2/etc_cont_006_ov.png',
        },
      ],
    },
  ],
};

const ColonAnusDiseases = () => {
  const { rows } = data;
  const style = isMobile
    ? { titleFontSize: '40px', colSpan: 24 }
    : { titleFontSize: '50px', colSpan: 6 };

  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu2/hem000.png)',
          }}
        >
          <Row align="middle">
            <Col span={12}></Col>
            <Col span={12}>
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>대장항문</b>클리닉
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>대장항문</b>질환
                </h2>
              </Col>
            </Row>
            <Row justify="center" align="middle" style={{}}>
              {rows.map((row, key) => {
                return (
                  <Fragment key={key}>
                    <Col span={6}></Col>
                    <Col span={style.colSpan}>
                      <div className="div-top-hover">
                        <img
                          priview="false"
                          width="100%"
                          style={{ padding: '10px' }}
                          className="img-top"
                          alt={row.cols[0].src}
                          src={row.cols[0].src}
                        />
                        <img
                          priview="false"
                          width="100%"
                          style={{ padding: '10px' }}
                          className="img-hover"
                          alt={row.cols[0].src}
                          src={row.cols[0].hover}
                        />
                      </div>
                    </Col>
                    <Col span={style.colSpan}>
                      <div className="div-top-hover">
                        <img
                          priview="false"
                          width="100%"
                          style={{ padding: '10px' }}
                          className="img-top"
                          alt={row.cols[1].src}
                          src={row.cols[1].src}
                        />
                        <img
                          priview="false"
                          width="100%"
                          style={{ padding: '10px' }}
                          className="img-hover"
                          alt={row.cols[1].src}
                          src={row.cols[1].hover}
                        />
                      </div>
                    </Col>
                    <Col span={6}></Col>
                  </Fragment>
                );
              })}
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top001m.png)',
          }}
        >
          <div className="descWrapM">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>대장항문</b>클리닉
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>대장항문</b>질환
              </h2>
            </Col>
          </Row>
          <Row justify="center" align="middle" style={{}}>
            {rows.map((row, key) => {
              return (
                <Fragment key={key}>
                  <Col span={6}></Col>
                  <Col span={style.colSpan}>
                    <div className="div-top-hover">
                      <img
                        priview="false"
                        width="100%"
                        style={{ padding: '10px 20px' }}
                        className="img-top"
                        alt={row.cols[0].src}
                        src={row.cols[0].src}
                      />
                      <img
                        priview="false"
                        width="100%"
                        style={{ padding: '10px 20px' }}
                        className="img-hover"
                        alt={row.cols[0].src}
                        src={row.cols[0].hover}
                      />
                    </div>
                  </Col>
                  <Col span={style.colSpan}>
                    <div className="div-top-hover">
                      <img
                        priview="false"
                        width="100%"
                        style={{ padding: '10px 20px' }}
                        className="img-top"
                        alt={row.cols[1].src}
                        src={row.cols[1].src}
                      />
                      <img
                        priview="false"
                        width="100%"
                        style={{ padding: '10px 20px' }}
                        className="img-hover"
                        alt={row.cols[1].src}
                        src={row.cols[1].hover}
                      />
                    </div>
                  </Col>
                  <Col span={6}></Col>
                </Fragment>
              );
            })}
          </Row>
        </div>
      </MobileView>
    </>
  );
};
export default ColonAnusDiseases;

import { useState } from 'react';
import { Menu, Row, Col, Modal, Input } from 'antd';
import { MobileView, BrowserView, isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import FooterStyle from '../styled/footer/FooterStyle';

const { TextArea } = Input;

const BrowserFooter = ({ client }) => {
  //
  const [txt, setTxt] = useState();
  const navigate = useNavigate();
  const size = isMobile
    ? { rows: 20, width: '90%', fontSize: '7px' }
    : { rows: 30, width: 1200, fontSize: '12px' };

  const handleClickMenu = ({ key }) => {
    console.log('key :', key);
    switch (key) {
      case '/intro':
      case '/location':
      case '/office-hour':
        navigate(key);
        break;
      case 'agreement':
        fetch('/terms/agreement.txt')
          .then((r) => r.text())
          .then((text) => {
            setTxt({ title: '개인정보처리방침', text });
          });
        break;
      case 'privacy':
        console.log('privacy: ');
        fetch('/terms/privacy.txt')
          .then((r) => r.text())
          .then((text) => {
            setTxt({ title: '서비스이용약관', text });
          });
        break;
      default:
    }
  };

  return (
    <FooterStyle>
      <BrowserView>
        <div className='ftInner'>
          <Menu
            mode='horizontal'
            onClick={handleClickMenu}
            items={[
              { label: '병원소개', key: '/intro' },
              { label: '오시는길', key: '/location' },
              { label: '진료안내', key: '/office-hour' },
              { label: '개인정보처리방침', key: 'agreement' },
              { label: '서비스이용약관', key: 'privacy' },
            ]}
          ></Menu>
          <Row span={24}>
            <Col span={6}>
              <img alt={client?.client} src={'/images/logo.png'} />
            </Col>
            <Col span={8}>
              <title>대표번호</title>
              <a href={'tel:' + client?.phon1}>{client?.phon1}</a>
              <title>진료시간</title>
              <div className='time'>
                {client?.etc?.officeHour?.map(({ title, text }, key) => (
                  <dl key={key}>
                    <dt>{title}</dt>
                    <dd>{text}</dd>
                  </dl>
                ))}
              </div>
            </Col>
            <Col span={10}>
              <title>LOCATION</title>
              <div
                className='map'
                style={{ backgroundImage: 'url(/images/map.png)' }}
              >
                <div>서해선 시흥능곡역 3번출구</div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='footerInfo'>
          <Row span={24}>
            <Col span={2}></Col>
            <Col span={20}>
              <dl>
                <dt className='name'>{client?.client}</dt>
                <dd>
                  {client?.address1}, {client?.address2}
                </dd>
              </dl>
              <dl>
                <dt>대표번호</dt>
                <dd>{client?.phon1 ? client?.phon1 : '02.547.1379'}</dd>
                <dt>대표</dt>
                <dd>{client?.ceo}</dd>
                <dt>사업자등록번호</dt>
                <dd>{client?.bizNo}</dd>
              </dl>
              <dl>
                <dd>
                  COPYRIGHT ⓒ 2022 SIHEUNG DREAM COLORECTAL BREAST SURGERY ALL
                  RIGHT PRESERVED
                </dd>
              </dl>
            </Col>
            <Col span={2}></Col>
          </Row>
        </div>
      </BrowserView>
      <MobileView>
        <div className='footerInfoM'>
          <Menu
            onClick={handleClickMenu}
            mode='horizontal'
            items={[
              { label: '개인정보처리방침', key: 'agreement' },
              { label: '서비스이용약관', key: 'privacy' },
            ]}
          ></Menu>
          <Row span={24}>
            <Col span={24}>
              <img alt={client?.client} src={'/images/logo.png'} />
            </Col>
            <Col span={24}>
              <dl>
                <dt className='name'>{client?.client}</dt>
              </dl>
              <dl>
                <dd>
                  {client?.address1}
                  <br />
                  {client?.address2}
                </dd>
              </dl>
              <dl>
                <dt>대표번호</dt>
                <dd>
                  <a href={`tel:${client?.phon1}`}>{client?.phon1}</a>
                </dd>
              </dl>
              <dl>
                <dt>대표</dt>
                <dd>{client?.ceo}</dd>
                <dt>사업자등록번호</dt>
                <dd>{client?.bizNo}</dd>
              </dl>
              <dl>
                <dd>
                  COPYRIGHT ⓒ 2022 SIHEUNG DREAM COLORECTAL BREAST SURGERY ALL
                  RIGHT PRESERVED
                </dd>
              </dl>
            </Col>
          </Row>
        </div>
      </MobileView>
      <Modal
        title={txt?.title}
        centered
        visible={txt?.title}
        onOk={() => setTxt(null)}
        onCancel={() => setTxt(null)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { fontSize: size.fontSize } }}
        okText={'확인'}
        okType='ghost'
        width={size.width}
        destroyOnClose={true}
        style={{ margin: 0, padding: 5 }}
        bodyStyle={{ margin: 0, padding: 0 }}
      >
        <TextArea
          value={txt?.text}
          rows={size.rows}
          readOnly={true}
          style={{ fontSize: size.fontSize }}
        />
      </Modal>
    </FooterStyle>
  );
};
export default BrowserFooter;

import styled from 'styled-components';

const QuickStyle = styled.div`
  & {
    display: block;
    background-color: unset;
    height: unset;
    width: unset;
    border: 0;
    padding: 0;
    &:hover,
    &:active,
    &:focus {
      background-color: unset;
      border: unset;
    }
    > .ant-btn {
      & {
        display: block;
        width: 68px;
        height: 68px;
        background-color: #fff;
        border: 0;
        border-radius: 0;
        font-size: 12px;
        letter-spacing: -1px;
        font-weight: 600;
        text-align: center;
        position: relative;
        padding: 5px;
        &:after {
          content: '';
          width: 48px;
          height: 1px;
          background-color: rgba(255, 255, 255, 0.3);
          position: absolute;
          bottom: 0;
          left: 10px;
        }
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &:hover,
      &:focus {
        background-color: #37adbe;
      }
      a {
        span {
          display: block;
          margin-bottom: 4px;
          svg {
            font-size: 26px;
          }
        }
      }
    }
  }
`;

export default QuickStyle;

import { BackTop, Button } from 'antd';
import {
  ArrowUpOutlined,
  LaptopOutlined,
  PushpinOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import AffixStyle from '../styled/affix/AffixStyle';
import QuickStyle from '../styled/button/QuickStyle';
import ButtonTopStyle from '../styled/button/ButtonTopStyle';

const BrowserSide = () => {
  return (
    <>
      <AffixStyle offsetTop={200}>
        <QuickStyle>
          {/* <Button>카톡문의</Button> */}
          {/* <Button>온라인예약</Button> */}
          {/* <Button>온라인상담</Button> */}
          {/* <Button>오시는길</Button> */}

          <Button type="primary">
            <Link to="/inquiry">
              <LaptopOutlined />
              온라인상담
            </Link>
          </Button>
          <Button type="primary">
            <Link to="/location">
              <PushpinOutlined />
              오시는길
            </Link>
          </Button>
        </QuickStyle>
      </AffixStyle>
      <BackTop
        duration="0"
        visibilityHeight="110"
        style={{ top: 300, right: 30 }}
      >
        <ButtonTopStyle icon={<ArrowUpOutlined />} />
      </BackTop>
    </>
  );
};

export default BrowserSide;

import { Image } from 'antd';
//temp
import PageTable from '../../components/page/PageTable';
import PageDescriptions from '../../components/page/PageDescriptions';
import PageDescription from '../../components/page/PageDescription';
import PageContNoticeM from '../../components/page/PageContNoticeM';

const CardType = ({ datas }) => {
  return (
    <>
      {datas?.map((data, key) => {
        switch (data.pageType) {
          case 'PageTable':
            return <PageTable key={key} data={data} />;
          case 'PageDescriptions':
            return <PageDescriptions key={key} data={data} />;
          case 'PageDescription':
            return <PageDescription key={key} data={data} />;
          case 'PageContNoticeM':
            return <PageContNoticeM key={key} data={data} />;
          case 'Image':
            return <Image key={key} preview={false} src={data.src} />;
          default:
            return null;
        }
      })}
    </>
  );
};
export default CardType;

import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Spin } from 'antd';
import { getTokenNaver } from '../../api/login-api';
import { UserContext } from '../../context/UserContext';

const Naver = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  return (
    <UserContext.Consumer>
      {({ user, setUser }) => {
        //dev
        if (!user.id) {
          getTokenNaver(search).then((res) => {
            console.log('res ======== :', res);
            if (res.error_description) {
              alert(res.message ?? res.error_description);
              navigate('/login');
            } else if (res) {
              sessionStorage.setItem('user', JSON.stringify(res));
              setUser(res);
              navigate('/');
            }
          });
        }
        return (
          //temp
          <Spin tip='Loading...'>
            <Alert message='NAVER' description='NAVER LOGIN' type='info' />
          </Spin>
        );
      }}
    </UserContext.Consumer>
  );
};
export default Naver;

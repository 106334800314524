import { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { Descriptions, Card } from 'antd';

const PageDescriptions = ({ data: { title, items } }) => {
  return isMobile ? (
    <div className="contTableM">
      {items.map(({ title, label, texts }, key) => (
        <Card key={key} title={label}>
          {texts.map((t, k) => (
            <Fragment key={k}>
              {t}
              <br />
            </Fragment>
          ))}
        </Card>
      ))}
    </div>
  ) : (
    <div className="contTableM">
      {items.map(({ label, texts }, key) => (
        <Descriptions
          key={key}
          title={!key ? title : ''}
          bordered
          labelStyle={{ width: '200px' }}
        >
          <Descriptions.Item label={label}>
            {texts.map((t, k) => (
              <Fragment key={k}>
                {t}
                <br />
              </Fragment>
            ))}
          </Descriptions.Item>
        </Descriptions>
      ))}
    </div>
  );
};

export default PageDescriptions;

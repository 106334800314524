import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { Layout, Image, Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
const { Header } = Layout;

const BrowserHeader = ({ client }) => {
  const { user } = useContext(UserContext);

  return (
    <Header style={{ background: 'white' }}>
      <div style={{ textAlign: 'center' }}>
        <div>
          <Link to='/'>
            <Image
              level={2}
              preview={false}
              src='/images/logo.png'
              alt={client?.client}
            />
          </Link>
          {user?.id ? (
            <>
              <span
                style={{
                  float: 'right',
                }}
              >
                <Link to='/logout'>
                  <Button
                    style={{
                      color: '#59bec9',
                    }}
                  >
                    LOOUT
                  </Button>
                </Link>
              </span>
              <Link to='/member'>
                <Avatar
                  style={{
                    backgroundColor: '#59bec9',
                    float: 'right',
                    marginTop: '43px',
                    marginRight: '10px',
                  }}
                  icon={<UserOutlined />}
                />
              </Link>
            </>
          ) : (
            <span
              style={{
                float: 'right',
              }}
            >
              <Link to='/login'>
                <Button
                  style={{
                    color: '#59bec9',
                  }}
                >
                  LOGIN
                </Button>
              </Link>
            </span>
          )}
        </div>
      </div>
    </Header>
  );
};
export default BrowserHeader;

import styled from 'styled-components';
import { Affix } from 'antd';

const AffixStyle = styled(Affix)`
  & {
    position: absolute;
    top: 110px;
    right: 10px;
  }
`;

export default AffixStyle;

import { Route } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Burn from './page/burn';
import BurnTreatment from './page/burn-treatment';
import Hemorrhoids from './page/hemorrhoids';
import AnalFistula from './page/anal-fistula';
import AnalFissure from './page/anal-fissure';
import FecalIncontinence from './page/fecal-incontinence';
import ColonAnusDiseases from './page/colon-anus-diseases';
import LipomaSebaceouscyst from './page/lipoma-sebaceouscyst';
import Breast from './page/breast';
import Thyroid from './page/thyroid';
import Gastroscopy from './page/gastroscopy';
import Colonoscopy from './page/colonoscopy';
import Nmc from './page/nmc';
import Employee from './page/employee';
import PrecautionsSurgery from './page/precautions-surgery';
//mobile
import NmcMobile from './mobile/nmc-mobile';
//
// import Home from '../pages-design/Home';
import OfficeHour from './page/office-hour';
import Intro from './page/intro';
import TakeLook from './page/take-look';
import NonBenefit from './page/non-benefit';
import Location from './page/location';
//table?
import CardType from '../pages/PageType/CardType';
//data
import employee from '../data/employee';
import nmc from '../data/nmc';

const RouterDesign = [
  <Route key='1' path='hemorrhoids' element={<Hemorrhoids />} />,
  <Route
    key='2'
    path='perianal-abscess/anal-fistula'
    element={<AnalFistula />}
  />,
  <Route key='3' path='anal-fissure' element={<AnalFissure />} />,
  <Route
    key='4'
    path='constipation/fecal-incontinence'
    element={<FecalIncontinence />}
  />,
  <Route key='5' path='colon-anus-diseases' element={<ColonAnusDiseases />} />,
  <Route
    key='6'
    path='lipoma-sebaceouscyst'
    element={<LipomaSebaceouscyst />}
  />,
  <Route key='7' path='gastroscopy' element={<Gastroscopy />} />,
  <Route key='8' path='colonoscopy' element={<Colonoscopy />} />,
  <Route key='9' path='breast' element={<Breast />} />,
  <Route key='10' path='thyroid' element={<Thyroid />} />,
  // <Route key='11' path='coming-soon' element={<Home />} />,
  <Route key='12' path='burn' element={<Burn />} />,
  <Route key='13' path='burn-treatment' element={<BurnTreatment />} />,
  <Route
    key='14'
    path='precautions-surgery'
    element={<PrecautionsSurgery />}
  />,
  <Route key='15' path='office-hour' element={<OfficeHour />} />,
  <Route key='16' path='intro' element={<Intro />} />,
  <Route key='17' path='take-look' element={<TakeLook />} />,
  <Route key='18' path='non-benefit' element={<NonBenefit />} />,
  <Route key='19' path='location' element={<Location />} />,
];
//temp
if (isMobile) {
  RouterDesign.push(
    <Route key='20' path='nmc' element={<NmcMobile datas={nmc} />} />,
    <Route
      key='21'
      path='employee'
      element={
        <>
          <Employee employee={employee} />
          <CardType datas={employee} />
        </>
      }
    />,
  );
} else {
  RouterDesign.push(
    <Route key='20' path='nmc' element={<Nmc />} />,
    <Route
      key='21'
      path='employee'
      element={<Employee employee={employee} />}
    />,
  );
}
export default RouterDesign;

import { Row, Col, Image, Table } from 'antd';
import { MobileView, BrowserView, isMobile } from 'react-device-detect';
// 초음파
const data = [
  {
    key: 1,
    divis: '초음파',
    part: '상복부 초음파',
    part2: '80,000',
    rowSpan: 11,
  },
  {
    key: 2,
    divis: '',
    part: '하복부 초음파',
    part2: '70,000',
    rowSpan: 0,
  },
  {
    key: 3,
    divis: '',
    part: '유방 초음파',
    part2: '100,000',
    rowSpan: 0,
  },
  {
    key: 4,
    divis: '',
    part: '갑상선 초음파',
    part2: '60,000',
    rowSpan: 0,
  },
  {
    key: 5,
    divis: '',
    part: '경부 초음파',
    part2: '70,000',
    rowSpan: 0,
  },
  {
    key: 6,
    divis: '',
    part: '경동맥 초음파',
    part2: '70,000',
    rowSpan: 0,
  },
  {
    key: 7,
    divis: '',
    part: '전립선 초음파',
    part2: '60,000',
    rowSpan: 0,
  },
  {
    key: 8,
    divis: '',
    part: '진단 초음파',
    part2: '50,000',
    rowSpan: 0,
  },
  {
    key: 9,
    divis: '',
    part: '정밀진단 초음파',
    part2: '80,000',
    rowSpan: 0,
  },
  {
    key: 10,
    divis: '',
    part: '갑상선/유방/임파선 세포검사초음파유도료',
    part2: '120,000',
    rowSpan: 0,
  },
  {
    key: 11,
    divis: '',
    part: '유방조직검사 초음파유도료',
    part2: '120,000',
    rowSpan: 0,
  },
];
const columns = [
  {
    key: 1,
    title: '',
    dataIndex: 'divis',
    width: '25%',
    align: 'center',
    onCell: ({ rowSpan }) => ({ rowSpan }),
  },
  {
    key: 2,
    title: '상세분류',
    dataIndex: 'part',
    align: 'center',
    render: (text) => <div dangerouslySetInnerHTML={{ __html: text }}></div>,
  },
  {
    key: 3,
    title: '가격 (원)',
    dataIndex: 'part2',
    align: 'center',
    render: (text) => <div dangerouslySetInnerHTML={{ __html: text }}></div>,
  },
];

// 화상 재료대
const data2 = [
  {
    key: 1,
    divis: '화상 재료대',
    part: '덤린 2g',
    part2: '30,000',
    rowSpan: 8,
  },
  {
    key: 2,
    divis: '',
    part: '덤린 5g',
    part2: '70,000',
    rowSpan: 0,
  },
  {
    key: 3,
    divis: '',
    part: '덤린 20g',
    part2: '220,000',
    rowSpan: 0,
  },
  {
    key: 4,
    divis: '',
    part: '렘스카겔',
    part2: '35,000',
    rowSpan: 0,
  },
  {
    key: 5,
    divis: '',
    part: '콜라힐',
    part2: '160,000',
    rowSpan: 0,
  },
  {
    key: 6,
    divis: '',
    part: 'NDA',
    part2: '120,000',
    rowSpan: 0,
  },
  {
    key: 7,
    divis: '',
    part: '미보MEBO연고',
    part2: '28,000',
    rowSpan: 0,
  },
  {
    key: 8,
    divis: '',
    part: '보습크림',
    part2: '38,000',
    rowSpan: 0,
  },
  {
    key: 9,
    divis: '화상 고정 재료대',
    part: '페하하프트 4*20',
    part2: '19,000',
    rowSpan: 8,
  },
  {
    key: 10,
    divis: '',
    part: '페하하프트 6*20',
    part2: '21,000',
    rowSpan: 0,
  },
  {
    key: 11,
    divis: '',
    part: '페하하프트 8*20',
    part2: '25,000',
    rowSpan: 0,
  },
  {
    key: 12,
    divis: '',
    part: '투비페스트 Green',
    part2: '9,000',
    rowSpan: 0,
  },
  {
    key: 13,
    divis: '',
    part: '투비페스트 Blue',
    part2: '8,800',
    rowSpan: 0,
  },
  {
    key: 14,
    divis: '',
    part: '투비페스트 Yellow',
    part2: '12,000',
    rowSpan: 0,
  },
  {
    key: 15,
    divis: '',
    part: '하이퍼스킨 TR',
    part2: '45,000',
    rowSpan: 0,
  },
  {
    key: 14,
    divis: '',
    part: '슈퍼포',
    part2: '15,000',
    rowSpan: 0,
  },
];
const columns2 = [
  {
    key: 1,
    title: '분류',
    dataIndex: 'divis',
    width: '25%',
    align: 'center',
    onCell: ({ rowSpan }) => ({ rowSpan }),
  },
  {
    key: 2,
    title: '상세분류',
    dataIndex: 'part',
    align: 'center',
    render: (text) => <div dangerouslySetInnerHTML={{ __html: text }}></div>,
  },
  {
    key: 3,
    title: '가격 (원)',
    dataIndex: 'part2',
    align: 'center',
    render: (text) => <div dangerouslySetInnerHTML={{ __html: text }}></div>,
  },
];

// 수술료
const data3 = [
  {
    key: 1,
    divis: '맘모톰(양성종양절제술) 1cm ~ 3cm',
    part: '1,200,000~5,000,000',
  },
];
const columns3 = [
  {
    key: 1,
    title: '항목',
    dataIndex: 'divis',
    width: '50%',
    align: 'center',
  },
  {
    key: 2,
    title: '가격 (원)',
    dataIndex: 'part',
    align: 'center',
    render: (text) => <div dangerouslySetInnerHTML={{ __html: text }}></div>,
  },
];

// 내시경 수면료
const data4 = [
  { key: 1, divis: '위내시경 수면비', part: '40,000' },
  {
    key: 2,
    divis: '대장내시경 수면비',
    part: '70,000',
  },
  {
    key: 3,
    divis: '위+대장 내시경 수면비',
    part: '100,000',
  },
  {
    key: 4,
    divis: '헬리코박터균 검사',
    part: '10,000',
  },
];
const columns4 = [
  {
    key: 1,
    title: '항목',
    dataIndex: 'divis',
    width: '50%',
    align: 'center',
  },
  {
    key: 2,
    title: '가격 (원)',
    dataIndex: 'part',
    align: 'center',
  },
];

// 약제비
const columns6 = [
  {
    key: 1,
    title: '항목',
    dataIndex: 'divis',
    width: '50%',
    align: 'center',
    render: (text) => <div dangerouslySetInnerHTML={{ __html: text }}></div>,
  },
  {
    key: 2,
    title: '가격 (원)',
    dataIndex: 'part',
    align: 'center',
  },
];
const data6 = [
  { key: 1, divis: '부스트릭스프리필드시린지', part: '35,000' },
  {
    key: 2,
    divis: '디펩티벤',
    part: '60,000',
  },
  { key: 3, divis: '플루아릭스테트라(유료독감)', part: '33,000' },
  {
    key: 4,
    divis: '훼모럼',
    part: '20,000',
  },
  { key: 5, divis: 'D3베이스주', part: '30,000' },
  { key: 6, divis: '영양제', part: '30,000~100,000' },
];
// 제증명 수수료
const data5 = [
  { key: 1, divis: '진단서', part: '20,000' },
  { key: 2, divis: '수술확인서', part: '20,000' },
  { key: 3, divis: '소견서', part: '20,000' },
  { key: 4, divis: '상해진단서 (3주 미만)', part: '120,000' },
  { key: 5, divis: '상해진단서 (3주 이상)', part: '150,000' },
  { key: 6, divis: '진료확인서', part: '3,000' },
  { key: 7, divis: '입퇴원확인서', part: '3,000' },
  { key: 8, divis: '제증명서 사본 (1장당)', part: '1,000' },
  { key: 9, divis: '초진차트', part: '1,000' },
];
const columns5 = [
  {
    key: 1,
    title: '항목',
    dataIndex: 'divis',
    width: '50%',
    align: 'center',
    render: (text) => <div dangerouslySetInnerHTML={{ __html: text }}></div>,
  },
  {
    key: 2,
    title: '가격 (원)',
    dataIndex: 'part',
    align: 'center',
  },
];

const NonBenefit = () => {
  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu1/intro_bg.jpg)',
          }}
        >
          <Row align="middle">
            <Col span={24} align="middle">
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>병원</b>소개
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="top">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>비급여</b>안내
                </h2>
              </Col>
            </Row>
          </div>
          <div className="cont_table" style={{}}>
            <Row justify="center" gutter={[0, 50]}>
              <Col span={16}>
                <h1>1. 초음파</h1>
                <Table
                  columns={columns}
                  dataSource={data}
                  rowKey="key"
                  size="small"
                  pagination={false}
                />
              </Col>
              <Col span={16}>
                <h1>2. 화상 재료대</h1>
                <Table
                  columns={columns2}
                  dataSource={data2}
                  rowKey="key"
                  size="small"
                  pagination={false}
                />
              </Col>
              <Col span={16}>
                <h1>3. 수술료</h1>
                <Table
                  columns={columns3}
                  dataSource={data3}
                  rowKey="key"
                  size="small"
                  pagination={false}
                />
              </Col>
              <Col span={16}>
                <h1>4. 내시경 수면료</h1>
                <Table
                  columns={columns4}
                  dataSource={data4}
                  rowKey="key"
                  size="small"
                  pagination={false}
                />
              </Col>
              <Col span={16}>
                <h1>5. 제증명 수수료</h1>
                <Table
                  columns={columns5}
                  dataSource={data5}
                  rowKey="key"
                  size="small"
                  pagination={false}
                />
              </Col>
              <Col span={16}>
                <h1>6. 약제비</h1>
                <Table
                  columns={columns6}
                  dataSource={data6}
                  rowKey="key"
                  size="small"
                  pagination={false}
                />
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top002m.png)',
          }}
        >
          <div className="descWrapM center">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>병원</b>소개
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>비급여</b>안내
              </h2>
            </Col>
          </Row>
        </div>
        <div className="contTableM">
          <h2>1. 초음파</h2>
          <Table
            columns={columns}
            dataSource={data}
            rowKey="key"
            size="small"
            pagination={false}
          />

          <h2>2. 화상 재료대</h2>
          <Table
            columns={columns2}
            dataSource={data2}
            rowKey="key"
            size="small"
            pagination={false}
          />

          <h2>3. 수술료</h2>
          <Table
            columns={columns3}
            dataSource={data3}
            rowKey="key"
            size="small"
            pagination={false}
          />

          <h2>4. 내시경 수면료</h2>
          <Table
            columns={columns4}
            dataSource={data4}
            rowKey="key"
            size="small"
            pagination={false}
          />

          <h2>5. 제증명 수수료</h2>
          <Table
            columns={columns5}
            dataSource={data5}
            rowKey="key"
            size="small"
            pagination={false}
          />

          <h2>6. 약제비</h2>
          <Table
            columns={columns6}
            dataSource={data6}
            rowKey="key"
            size="small"
            pagination={false}
          />
        </div>
      </MobileView>
    </>
  );
};
export default NonBenefit;

import { Image, message, Button, Checkbox } from 'antd';
import { isMobile } from 'react-device-detect';

const IMAGE_PATH = `${process.env.REACT_APP_ATREE_CLIENT_API_URL}/images/`;

const prefix = isMobile ? 'ant-message-mobile' : 'ant-message-browser';
message.config({
  duration: 0,
});

const getClassName = (key) => {
  let postfix;
  switch (key) {
    case 1:
      postfix = 'first';
      break;
    case 2:
      postfix = 'second';
      break;
    case 3:
      postfix = 'third';
      break;
    default:
  }
  return `${prefix}-${postfix}`;
};
const TODAY_KEY = 'TODAY';
const today = new Date().toISOString().substring(0, 10);
const todayStr = localStorage.getItem(TODAY_KEY);
const json = todayStr ? JSON.parse(todayStr) : {};
if (!Object.keys(json).includes(today)) {
  localStorage.setItem(TODAY_KEY, JSON.stringify({}));
}
export const closeLayer = async () => {
  message.destroy();
};
export const openLayer = async ({
  id,
  image,
  title,
  content,
  callback,
  force,
}) => {
  const todayStr = localStorage.getItem(TODAY_KEY);
  const json = todayStr ? JSON.parse(todayStr) : {};

  if (!force && json[today] && json[today][id]) {
    return;
  }

  let src;
  if (image?.fileList) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(image.fileList[0].originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  } else if (image?.id) {
    src = IMAGE_PATH + image.id;
  }
  const handleChangeToday = ({ target }) => {
    const todayStr = localStorage.getItem(TODAY_KEY);
    const json = todayStr ? JSON.parse(todayStr) : {};
    if (!json[today]) json[today] = {};
    json[today][id] = target.checked;
    localStorage.setItem(TODAY_KEY, JSON.stringify(json));
  };

  const handleClickClose = () => {
    message.destroy(id);
  };

  const handleClick = () => {
    handleClickClose();
    callback();
  };
  message.open({
    key: id,
    content: (
      <div>
        {title && <h2 onClick={handleClick}>{title}</h2>}
        {content && <pre onClick={handleClick}>{content}</pre>}
        <Image src={src} preview={false} onClick={handleClick} />
        <div>
          <Checkbox onChange={handleChangeToday}>오늘 하루 그만 보기</Checkbox>
          <Button type='text' onClick={handleClickClose}>
            닫기
          </Button>
        </div>
      </div>
    ),
    className: getClassName(id),
  });
};

import { useState, useEffect } from 'react';
import { Input, Button, Upload, Form, Switch } from 'antd';
import { isMobile } from 'react-device-detect';
import { InboxOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { postBoard } from '../../api/atree-api';
import BoardSection from '../../components/board/BoardSection';

import FormWrapper from '../../components-design/styled/table/FormWrapper';

const { TextArea } = Input;

const BoardWrite = ({ user }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!user.id) {
      alert('로그인 사용자만 작성할 수 있습니다.');
      navigate('/login');
    }
  }, []);

  const [secret, setSecret] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleFinish = (values) => {
    const formData = new FormData();
    if (values.files) {
      values.files.forEach(({ originFileObj }) => {
        formData.append('files', originFileObj);
      });
    }
    delete values.files;

    for (const [k, v] of Object.entries(values)) {
      formData.append(k, v);
    }
    setUploading(true);
    postBoard(state?.menuKey, formData)
      .then(({ ok, status }) => {
        if (ok) navigate('..');
        else if (status === 401) {
          alert('로그인 정보가 없습니다.');
          navigate('/login');
        }
      })
      .catch((err) => console.log('Error ? ', err))
      .finally(() => setUploading(false));
  };

  const normFile = (e) => {
    return e?.fileList?.reduce((acc, cur) => {
      const bool = acc.some(({ name }) => name === cur.name);
      if (!bool) {
        acc.push(cur);
      }
      return acc;
    }, []);
  };
  const handleChangeSecret = (v) => {
    setSecret(v);
  };
  return (
    <>
      <BoardSection title={state.title} description={state.description} />
      <FormWrapper>
        <Form
          layout='vertical'
          onFinish={handleFinish}
          style={{
            width: isMobile ? '100%' : '1200px',
            margin: '0 auto',
            padding: '2em',
          }}
        >
          <Form.Item
            name='secret'
            label='비밀글'
            valuePropName='checked'
            initialValue={secret}
          >
            <Switch onChange={handleChangeSecret} />
          </Form.Item>
          <Form.Item
            label='작성자'
            name='writer'
            initialValue={user.name}
            rules={[
              {
                required: true,
                message: '작성자를 입력해주세요.',
              },
            ]}
          >
            <Input placeholder='작성자' readOnly />
          </Form.Item>
          <Form.Item
            label='제목'
            name='title'
            rules={[
              {
                required: true,
                message: '제목을 입력해주세요.',
              },
            ]}
          >
            <Input placeholder='제목' />
          </Form.Item>
          <Form.Item
            label='내용'
            name='content'
            rules={[
              {
                required: true,
                message: '내용을 입력해주세요.',
              },
            ]}
          >
            <TextArea rows={20} placeholder='내용' />
          </Form.Item>
          <Form.Item label='첨부파일'>
            <Form.Item
              name='files'
              valuePropName='fileList'
              getValueFromEvent={normFile}
              noStyle
            >
              <Upload.Dragger
                name='files'
                multiple={true}
                beforeUpload={() => false}
                maxCount={5}
              >
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>
                  클릭하거나 드래그하여 파일을 등록하세요.
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <Button type='primary' htmlType='submit' loading={uploading}>
            저장
          </Button>
        </Form>
      </FormWrapper>
    </>
  );
};
export default BoardWrite;

import { Image, Row, Col } from 'antd';
import { MobileView, BrowserView } from 'react-device-detect';

const Breast = () => {
  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu3/bre000.png)',
          }}
        >
          <Row align="middle">
            <Col span={12}></Col>
            <Col span={12}>
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>유방·갑상선</b>클리닉
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>유방암</b>검진
                </h2>
                <p className="desc">
                  <b>유방초음파</b>
                </p>
              </Col>
              <Col style={{ width: '400px' }}>
                <p className="subDesc">
                  유방초음파는 유방암 의심증상이 있거나 유방촬영에서 이상소견이
                  발견되면 시행합니다.
                </p>
                <p className="subDesc">
                  고해상도 초음파 기기를 이용하여 유방질환을 진단하는 검사로
                  방사선 노출이 없으며 유방의 양성결절, 악성결절 및 염증성 병변,
                  낭종 등을 영상 소견으로 구분하는데 유용한 검사입니다.
                </p>
              </Col>
              <Col style={{ width: '600px' }}>
                <Image
                  preview={false}
                  src="/images/menu3/cont1_bre_circle01.png"
                />
              </Col>
            </Row>
          </div>
          <div
            className="cont_care"
            style={{
              backgroundImage: 'url(/images/menu3/cont_bg_03.png)',
            }}
          >
            <Row justify="center" align="middle">
              <Col span={10} align="middle">
                <img
                  alt=""
                  className="descImg"
                  src="/images/menu/desc_eng_w.png"
                />
                <h2>
                  <b>맘모톰</b>이란?
                </h2>
                <p className="desc">
                  <b>맘모톰</b>은 조직검사 뿐만 아니라 <b>유방 양성종양 제거</b>
                  도 가능 한 것으로 <b>진공 보조 유방 생검술</b>이라고 합니다.
                </p>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <div className="imgGroup recRotate">
                  <Image
                    preview={false}
                    src="/images/menu/cont1_bre_circle02.png"
                  />
                  <Image
                    preview={false}
                    src="/images/menu/cont1_bre_circle02_out.png"
                  />
                  <Image
                    preview={false}
                    src="/images/menu/cont1_bre_circle02_in.png"
                  />
                  <div className="txtGroup five">
                    <p className="subDesc txt01">
                      피부에 5mm 이하의 작은 절개로 흉이 거의 없습니다.
                    </p>
                    <p className="subDesc txt02">
                      국소마취로 짧은 시간에 혹을 제거할 수 있습니다.
                    </p>
                    <p className="subDesc txt03">
                      수술 후 지혈을 위해 압박붕대로 감고 6시간 동안 입원하여
                      관찰합니다.
                    </p>
                    <p className="subDesc txt04">
                      다음 날 내원하여 붕대를 풀고 상처를 확인합니다.
                    </p>
                    <p className="subDesc txt05">
                      3일 정도 수술 부위를 물에 닿지 않게 하고 과격한 운동은
                      삼갑니다.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="cont_way"
            style={{
              backgroundImage: 'url(/images/menu3/cont_bg_04.png)',
              backgroundSize: 'cover',
            }}
          >
            <img
              className="vDescImg"
              alt=""
              src="/images/menu/v_desc_eng.png"
            />
            <Row justify="center" align="middle">
              <Col span={4}>
                <h2>
                  <b>유방</b>
                  <br />
                  조직검사
                </h2>
              </Col>
              <Col span={10}>
                <Row align="middle" justify="end">
                  <Col style={{ display: 'flex', gap: '20px' }}>
                    <p className="recCard">
                      <img alt="" src="/images/menu3/cont2_bre_rec00.png" />
                      <span>
                        <b>세포흡인 세포검사</b>
                        주사기로 결절 내용물을 뽑아내는 방법으로 물혹의 경우
                        내용물 전체가 흡인되어 없어지기도 합니다. 처음엔 따끔한
                        느낌이 있지만 즉시 일상생활 복귀가 가능한 비교적 간단한
                        검사입니다.
                      </span>
                    </p>
                    <p className="recCard">
                      <img alt="" src="/images/menu3/cont2_bre_rec01.png" />
                      <span>
                        <b>중심바늘생검 조직검사</b>
                        홈이 있는 굵은 바늘로 조직의 일부를 떼어내는 방식으로
                        98%의 정확도를 가집니다. 국소마취 후 검사가 진행되며,
                        검사 당일은 무리한 움직임을 피하는 것이 좋습니다.
                      </span>
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={14} align="right">
                <p className="desc">
                  <b>유방조직검사</b>는 초음파 소견상 결절의 모양과 크기를 보고
                  주로 예후를 고려했을 때<br />
                  <b>제거가 필요해 보이거나 암이 의심되는 경우</b>
                  시행합니다.
                </p>
              </Col>
            </Row>
          </div>
          <div
            className="cont_way"
            style={{
              backgroundImage: 'url(/images/menu3/cont_bg_06.png)',
              backgroundSize: 'cover',
            }}
          >
            <img
              className="vDescImg"
              alt=""
              src="/images/menu/v_desc_eng.png"
            />
            <Row justify="center" align="middle">
              <Col span={4}>
                <h2>
                  기타
                  <br />
                  <b>유방질환</b>
                </h2>
              </Col>
              <Col span={12}>
                <p className="recBlock dark">
                  <b>유방통</b>
                  <span>
                    가장 흔한 유방질환으로 유방통의 원인은 호르몬 변화(생리),
                    과다한 카페인 섭취 등 일시적인 경우가 대부분입니다. 하지만
                    호르몬 주기와 상관없이 특정 부위에 지속적인 통증이 있다면
                    정밀한 검사가 필요합니다.
                  </span>
                </p>
                <p className="recBlock dark">
                  <b>
                    유방염증
                    <br />
                    (유선염)
                  </b>
                  <span>
                    유방염은 수유기와 비수유기 유방염으로 나뉘며 대부분 세균성
                    감염이 원인입니다. 염증이 진행되면 고열과 오한, 근육통이
                    발생하며 심한 경우 농양이 생기기도 합니다. 대부분 소염제나
                    항생제를 통해 약물로 치료하나, 심한 경우 배농술이 필요하기도
                    합니다.
                  </span>
                </p>
                <p className="recBlock dark">
                  <b>부유방</b>
                  <span>
                    유선조직이 유방이 아닌 부위에 있는 것으로 보통
                    액와부(겨드랑이)에 많이 발달되어 있습니다. 임신/수유기 또는
                    생리 전 겨드랑이 멍울이나 통증으로 인해 알게되며 특별한
                    증상을 일으키지는 않습니다. 만약 이상 병변이 동반되거나
                    외관상 문제가 있다면 절제를 고려하기도 합니다.
                  </span>
                </p>
                <p className="recBlock dark">
                  <b>유두분비물</b>
                  <span>
                    분비물이 양쪽에서 나오는지 한쪽에서 나오는지, 색깔은 어떤지,
                    분비량은 어떤지 등을 파악합니다. 대부분 생리적인 원인이나
                    약물 복용에 의해 발생하지만 종양에 의한 증상일 경우 치료가
                    필요합니다.
                  </span>
                </p>
                <p className="recBlock dark">
                  <b>유방멍울</b>
                  <span>
                    유방멍울은 자가검진이나 마사지 중 발견되는 경우가 많습니다.
                    초음파 검사로 유방암 여부를 판단해야 합니다. 대부분의 멍울은
                    양성이지만 크기에 따라 조직검사 후 제거를 하거나, 추적검사가
                    필요합니다.
                  </span>
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top003m.png)',
          }}
        >
          <div className="descWrapM">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>유방·갑상선</b>클리닉
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
              <h2>
                <b>유방암</b>검진
              </h2>
              <p className="desc">
                <b>유방초음파</b>초음파
              </p>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={14}>
              <p className="subDesc">
                유방초음파는 유방암 의심증상이 있거나 유방촬영에서 이상소견이
                발견되면 시행합니다. 고해상도 초음파 기기를 이용하여 유방질환을
                진단하는 검사로 방사선 노출이 없으며 유방의 양성결절, 악성결절
                및 염증성 병변, 낭종 등을 영상 소견으로 구분하는데 유용한
                검사입니다.
              </p>
            </Col>
            <Col span={10}>
              <img className="cirImg" alt="" src="/images/mobile/cir005m.png" />
            </Col>
          </Row>
        </div>
        <div className="contCareM">
          <Row justify="center" align="middle">
            <Col span={24}>
              <img
                className="descImg"
                alt=""
                src="/images/menu/desc_eng_w.png"
              />
            </Col>
            <Col span={24}>
              <h2>
                <b>맘모톰</b>이란?
              </h2>
            </Col>
            <Col span={20}>
              <p className="subDesc">
                <b>맘모톰</b>은 조직검사 뿐만 아니라 <b>유방 양성종양 제거</b>도
                가능 한 것으로 <b>진공 보조 유방 생검술</b>이라고 합니다.
              </p>
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  <div
                    className="careCir"
                    style={{
                      backgroundImage: 'url(/images/mobile/care_bre_cir01.png)',
                    }}
                  >
                    <div
                      className="careCirOut"
                      style={{
                        backgroundImage: 'url(/images/menu/care_hem_cir02.png)',
                      }}
                    />
                    <div
                      className="careCirRotate"
                      style={{
                        backgroundImage:
                          'url(/images/menu/care_hem_cir03_rotate.png)',
                      }}
                    />
                  </div>
                </Col>
                <Col span={24} style={{ display: 'flex' }}>
                  <div className="careText">
                    <em>1.</em>
                    <p>피부에 5mm 이하의 작은 절개로 흉이 거의 없습니다.</p>
                  </div>
                  <div className="careText">
                    <em>2.</em>
                    <p>
                      피부에 5mm 이하의 작은 절개로 흉이 거의 없습니다.
                      국소마취로 짧은 시간에 혹을 제거할 수 있습니다.
                    </p>
                  </div>
                </Col>
                <Col span={24} style={{ display: 'flex' }}>
                  <div className="careText">
                    <em>3.</em>
                    <p>
                      수술 후 지혈을 위해 압박붕대로 감고 6시간 동안 입원하여
                      관찰합니다.
                    </p>
                  </div>
                  <div className="careText">
                    <em>4.</em>
                    <p>다음 날 내원하여 붕대를 풀고 상처를 확인합니다.</p>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="careText">
                    <em>5.</em>
                    <p>
                      3일 정도 수술 부위를 물에 닿지 않게 하고 과격한 운동은
                      삼갑니다.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="contWayM" style={{ backgroundColor: '#b6d3ff' }}>
          <Row justify="center">
            <Col span={24}>
              <img
                className="descImg"
                alt=""
                src="/images/menu/desc_eng_w.png"
              />
            </Col>
            <Col span={24}>
              <h2>
                <b>유방</b> 조직검사
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <p className="rec01">
                <img alt="" src="/images/menu3/cont2_bre_rec00.png" />
                <span>
                  <b>세포흡인 세포검사</b>
                  주사기로 결절 내용물을 뽑아내는 방법으로 물혹의 경우 내용물
                  전체가 흡인되어 없어지기도 합니다. 처음엔 따끔한 느낌이 있지만
                  즉시 일상생활 복귀가 가능한 비교적 간단한 검사입니다.
                </span>
              </p>
              <p className="rec02">
                <img alt="" src="/images/menu3/cont2_bre_rec01.png" />
                <span>
                  <b>중심바늘생검 조직검사</b>
                  홈이 있는 굵은 바늘로 조직의 일부를 떼어내는 방식으로 98%의
                  정확도를 가집니다. 국소마취 후 검사가 진행되며, 검사 당일은
                  무리한 움직임을 피하는 것이 좋습니다.
                </span>
              </p>
            </Col>
            <Col span={20}>
              <p className="subDesc">
                <b>유방조직검사</b>는 초음파 소견상 결절의 모양과 크기를 보고
                주로 예후를 고려했을 때{' '}
                <b>제거가 필요해 보이거나 암이 의심되는 경우</b>시행합니다
              </p>
            </Col>
          </Row>
        </div>
        <div className="contWayM filter" style={{ backgroundColor: '#a3b5fe' }}>
          <Row justify="center">
            <Col span={24}>
              <img
                className="descImg"
                alt=""
                src="/images/menu/desc_eng_w.png"
              />
            </Col>
            <Col span={24}>
              <h2>
                기타<b>유방질환</b>
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <p className="rec01">
                <span>
                  <b>유방통</b>
                  가장 흔한 유방질환으로 유방통의 원인은 호르몬 변화(생리),
                  과다한 카페인 섭취 등 일시적인 경우가 대부분입니다. 하지만
                  호르몬 주기와 상관없이 특정 부위에 지속적인 통증이 있다면
                  정밀한 검사가 필요합니다.
                </span>
              </p>
              <p className="rec01">
                <span>
                  <b>유방염증(유선염)</b>
                  유방염은 수유기와 비수유기 유방염으로 나뉘며 대부분 세균성
                  감염이 원인입니다. 염증이 진행되면 고열과 오한, 근육통이
                  발생하며 심한 경우 농양이 생기기도 합니다. 대부분 소염제나
                  항생제를 통해 약물로 치료하나, 심한 경우 배농술이 필요하기도
                  합니다.
                </span>
              </p>
              <p className="rec01">
                <span>
                  <b>부유방</b>
                  유선조직이 유방이 아닌 부위에 있는 것으로 보통
                  액와부(겨드랑이)에 많이 발달되어 있습니다. 임신/수유기 또는
                  생리 전 겨드랑이 멍울이나 통증으로 인해 알게되며 특별한 증상을
                  일으키지는 않습니다. 만약 이상 병변이 동반되거나 외관상 문제가
                  있다면 절제를 고려하기도 합니다.
                </span>
              </p>
              <p className="rec01">
                <span>
                  <b>유두분비물</b>
                  분비물이 양쪽에서 나오는지 한쪽에서 나오는지, 색깔은 어떤지,
                  분비량은 어떤지 등을 파악합니다. 대부분 생리적인 원인이나 약물
                  복용에 의해 발생하지만 종양에 의한 증상일 경우 치료가
                  필요합니다.
                </span>
              </p>
              <p className="rec01">
                <span>
                  <b>유방멍울</b>
                  유방멍울은 자가검진이나 마사지 중 발견되는 경우가 많습니다.
                  초음파 검사로 유방암 여부를 판단해야 합니다. 대부분의 멍울은
                  양성이지만 크기에 따라 조직검사 후 제거를 하거나, 추적검사가
                  필요합니다.
                </span>
              </p>
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
};
export default Breast;

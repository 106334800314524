import { MobileView, BrowserView, isMobile } from 'react-device-detect';

const LOGIN_CALLBACK_URL = process.env.REACT_APP_LOGIN_CALLBACK_URL;
const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;
const NAVER_OAUTH_URL = process.env.REACT_APP_NAVER_OAUTH_URL;
const KAKAO_OAUTH_URL = process.env.REACT_APP_KAKAO_OAUTH_URL;
const KAKAO_API_KEY = process.env.REACT_APP_KAKAO_API_KEY;

const getUniqState = () => {
  var stat_str = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    },
  );
  return stat_str;
};

const Login = () => {
  const handleClickNaver = () => {
    const qs = [
      'response_type=code',
      `client_id=${NAVER_CLIENT_ID}`,
      `redirect_uri=${encodeURIComponent(LOGIN_CALLBACK_URL)}/naver`,
      `state=${getUniqState()}`,
    ];
    const href = NAVER_OAUTH_URL + '/authorize?' + qs.join('&');
    window.location.href = href;
  };
  const handleClickKakao = () => {
    const qs = [
      `client_id=${KAKAO_API_KEY}`,
      `redirect_uri=${LOGIN_CALLBACK_URL}/kakao`,
      'response_type=code',
      `state=${getUniqState()}`,
    ];
    const href = KAKAO_OAUTH_URL + '/authorize?' + qs.join('&');
    window.location.href = href;
  };
  // const handleClickGoogle = () => {};

  return (
    <>
      <BrowserView>
        <div className='browserSectionLogin'>
          <h3>로그인</h3>
          <h4>siheung dream Login</h4>
          <div className='loginButton'>
            <div>
              <img
                src='/images/sns/btnG_naver_login.png'
                alt='naver login'
                onClick={handleClickNaver}
              />
              <img
                src='/images/sns/kakao_login_medium_narrow.png'
                alt='kakao login'
                onClick={handleClickKakao}
              />
              <h5>SNS계정으로 로그인하여 이용해 보세요.</h5>
            </div>
          </div>
          {/* <div>
        <a>
          <img
            src='/images/sns/btn_google_signin_light_normal_web@2x.png'
            alt='google login'
            width='100%'
          />
        </a>
      </div> */}
        </div>
      </BrowserView>
      <MobileView>
        <div className='mobileSectionLogin'>
          <h3>로그인</h3>
          <h4>siheung dream Login</h4>
          <div className='loginButton'>
            <div>
              <img
                src='/images/sns/btnG_naver_login.png'
                alt='naver login'
                onClick={handleClickNaver}
              />
              <img
                src='/images/sns/kakao_login_medium_narrow.png'
                alt='kakao login'
                onClick={handleClickKakao}
              />
              <h5>SNS계정으로 로그인하여 이용해 보세요.</h5>
            </div>
          </div>
        </div>
      </MobileView>
    </>
  );
};
export default Login;

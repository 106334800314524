import { BackTop } from 'antd';
import { CaretUpOutlined } from '@ant-design/icons';

import MobileHeader from './MobileHeader';
import BrowserFooter from './BrowserFooter';

const PRIMARY_COLOR = process.env.REACT_APP_TITLE_PRIMARY_COLOR;

const MobileLayout = ({ client, content, menus }) => {
  return (
    <>
      <MobileHeader client={client} menus={menus} />
      {content}
      <BackTop
        duration='0'
        visibilityHeight='110'
        style={{ top: 300, right: 10 }}
      >
        <CaretUpOutlined style={{ fontSize: '50px', color: PRIMARY_COLOR }} />
      </BackTop>
      <BrowserFooter client={client} />
    </>
  );
};
export default MobileLayout;

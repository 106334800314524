import { Row, Col, Image } from 'antd';
import { MobileView, BrowserView } from 'react-device-detect';

const Burn = () => {
  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu5/burn000.png)',
          }}
        >
          <Row align="middle">
            <Col span={12}></Col>
            <Col span={12}>
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>화상</b>클리닉
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>화상</b>이란?
                </h2>
                <p className="desc">
                  열, 전기, 방사능, 또는 화학물질로 인한 <br />
                  <b>피부 및 조직이 손상 된 것</b>을 말합니다.
                </p>
              </Col>
              <Col style={{ width: '400px' }}>
                <p className="subDesc">
                  일반적으로 증상에 따라 1도에서 4도로 구분합니다. 정도를 파악할
                  때는 화상을 입은 부위와 연령적인 요소를 고려하여 화상의 침범
                  깊이와 넓이에 따라 중증도를 결정합니다.
                </p>
                <p className="subDesc">
                  <b>화상의 원인</b>
                  뜨거운 물체나 기체와 접촉 시멘트나 산성 및 알칼리 물질에 의해
                  노출된 경우 (부식) 전기감전에 의한 것과 태양(적외선, 자외선)
                  의 노출
                </p>
              </Col>
              <Col style={{ width: '600px' }}>
                <Image
                  preview={false}
                  src="/images/menu5/cont1_burn_circle01.png"
                />
              </Col>
            </Row>
          </div>
          <div className="cont_step" style={{ backgroundColor: '#8bd1d9' }}>
            <img
              className="vDescImg"
              alt=""
              src="/images/menu/v_desc_eng_w.png"
            />
            <Row justify="center" align="middle">
              <Col span={6} align="middle">
                <h2>
                  <b>화상</b>의 <br />
                  <b>침범 깊이</b>에 <br />
                  따른 분류
                </h2>
              </Col>
              <Col
                span={8}
                style={{
                  gap: '20px',
                }}
              >
                <Row>
                  <Col
                    style={{
                      display: 'flex',
                      gap: '20px',
                    }}
                  >
                    <div className="stepRec right">
                      <span>1도 화상</span>
                      <Image
                        preview={false}
                        src="/images/menu5/burn_cont_001.png"
                      />
                      <p>
                        물집 등은 생기지 않지만 피부가 붉게 변하면서(홍반)
                        열감과 동통을 수반합니다.
                      </p>
                    </div>
                    <div className="stepRec right">
                      <span>2도 화상</span>
                      <Image
                        preview={false}
                        src="/images/menu5/burn_cont_002.png"
                      />
                      <p>
                        물집이 생성되며, 붓고 심한 통증이 수반됩니다. 그리고
                        감염의 위험이 있으며 흉터가 발생됩니다
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ display: 'flex', gap: '20px' }}>
                    <div className="stepTable">
                      <span>
                        <b>
                          <font>2도</font> 표재성
                        </b>
                        화상
                      </span>
                      <p>
                        진피층의 일부만 손상을 받아 보통 2~3주만에 회복
                        가능합니다.
                      </p>
                    </div>
                    <div className="stepTable">
                      <span>
                        <b>
                          <font>2도</font> 심재성
                        </b>
                        화상
                      </span>
                      <p>
                        진피층 대부분이 손상, 감염이 없을 경우 4주 정도면 회복이
                        되나 흉터가 남습니다.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ display: 'flex', gap: '20px' }}>
                    <div className="stepRec right">
                      <span>3도 화상</span>
                      <Image
                        preview={false}
                        src="/images/menu5/burn_cont_003.png"
                      />
                      <p>
                        피부 전층이 손상이 되어 화상부위 피부색이 흰색 또는
                        검은색으로 변하여 가피가 생성되어, 오히려 거의 통증을
                        느끼지 못합니다.
                      </p>
                    </div>
                    <div className="stepRec right">
                      <span>4도 화상</span>
                      <Image
                        preview={false}
                        src="/images/menu5/burn_cont_004.png"
                      />
                      <p>근육 및 신경, 뼈 조직까지 손상된 상태입니다.</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className="cont_way"
            style={{
              backgroundColor: '#d9d9d9',
              backgroundImage: 'url(/images/menu5/ruleofnine.png)',
              backgroundPosition: 'center',
            }}
          >
            <img
              className="vDescImg"
              alt=""
              src="/images/menu/v_desc_eng_w.png"
            />
            <Row justify="center" align="middle">
              <Col span={6}>
                <h2>
                  <b>화상</b>의 <br />
                  <b>표면적넓이</b>에 <br />
                  따른 분류
                </h2>

                <p className="subDescRound">
                  화상 <b>경중도</b> 분류
                </p>
                <p className="desc">
                  <span>경도 화상</span>
                  보통 1도 화상이나 2도 화상이 체표면적 9% 미만을 차지할 때 보통
                  경도로 분류합니다.
                </p>
                <p className="desc">
                  <span>중등도 및 중증 화상</span>
                  손, 발, 얼굴, 또는 생식기에 입은 화상, 체표면적의 10%이상
                  발생한 2도 화상, 체표면적의 1%이상의 3도 화상입니다.
                </p>
              </Col>
              <Col span={8}>
                <Image preview={false} src="/images/menu5/burnbody.png" />
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top005m.png)',
          }}
        >
          <div className="descWrapM">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>화상</b>클리닉
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>화상</b>이란?
              </h2>
              <p className="desc">
                열, 전기, 방사능, 또는 화학물질로 인한 <br />
                <b>피부 및 조직이 손상</b> 된 것을 말합니다.
              </p>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={14}>
              <p className="subDesc">
                일반적으로 증상에 따라 1도에서 4도로 구분합니다. 정도를 파악할
                때는 화상을 입은 부위와 연령적인 요소를 고려하여 화상의 침범
                깊이와 넓이에 따라 중증도를 결정합니다.
              </p>
              <p className="subDesc">
                <span>화상의 원인</span>뜨거운 물체나 기체와 접촉 시멘트나 산성
                및 알칼리 물질에 의해 노출된 경우 (부식) 전기감전에 의한 것과
                태양(적외선, 자외선) 의 노출
              </p>
            </Col>
            <Col span={10}>
              <img className="cirImg" alt="" src="/images/mobile/cir008m.png" />
            </Col>
          </Row>
        </div>
        <div className="contStepM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>화상</b>의 <b>침범 깊이</b>에 따른 분류
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col style={{ display: 'flex' }}>
              <div className="stepRec right">
                <span>1도 화상</span>
                <img alt="" src="/images/menu5/burn_cont_001.png" />
                <p>
                  물집 등은 생기지 않지만 피부가 붉게 변하면서(홍반) 열감과
                  동통을 수반합니다.
                </p>
              </div>
              <div className="stepRec right">
                <span>2도 화상</span>
                <img alt="" src="/images/menu5/burn_cont_002.png" />
                <p>
                  물집이 생성되며, 붓고 심한 통증이 수반됩니다. 그리고 감염의
                  위험이 있으며 흉터가 발생됩니다.
                </p>
              </div>
            </Col>
            <Col style={{ display: 'flex' }}>
              <div className="stepTable">
                <span>
                  <b>
                    <font>2도</font> 표재성
                  </b>
                  화상
                </span>
                <p>
                  진피층의 일부만 손상을 받아 보통 2~3주만에 회복 가능합니다.
                </p>
              </div>
              <div className="stepTable">
                <span>
                  <b>
                    <font>2도</font> 심재성
                  </b>
                  화상
                </span>
                <p>
                  진피층 대부분이 손상, 감염이 없을 경우 4주 정도면 회복이 되나
                  흉터가 남습니다.
                </p>
              </div>
            </Col>
            <Col style={{ display: 'flex' }}>
              <div className="stepRec right">
                <span>3도 화상</span>
                <img alt="" src="/images/menu5/burn_cont_003.png" />
                <p>
                  피부 전층이 손상이 되어 화상부위 피부색이 흰색 또는 검은색으로
                  변하여 가피가 생성되어, 오히려 거의 통증을 느끼지 못합니다.
                </p>
              </div>
              <div className="stepRec right">
                <span>4도 화상</span>
                <img alt="" src="/images/menu5/burn_cont_004.png" />
                <p>근육 및 신경, 뼈 조직까지 손상된 상태입니다.</p>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="contWayM"
          style={{
            backgroundColor: '#d9d9d9',
            backgroundImage: 'url(/images/menu5/ruleofnine.png)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'calc(960 / 750 * 100vw) calc(166.5 / 750 * 100vw)',
            backgroundPosition: 'center',
          }}
        >
          <Row justify="center">
            <Col span={24}>
              <img
                className="descImg"
                alt=""
                src="/images/menu/desc_eng_w.png"
              />
            </Col>
            <Col span={24}>
              <h2>
                <b>화상</b>의 <b>표면적넓이</b>에 따른 분류
              </h2>
            </Col>
            <Col span={24}>
              <img
                className="causeBg01"
                alt=""
                src="/images/menu5/burnbody.png"
              />
            </Col>
            <Col span={24}>
              <p className="subDescRound">
                화상 <b>경중도</b> 분류
              </p>
            </Col>
            <Col span={20}>
              <p className="subDesc">
                <b>경도 화상</b> 보통 1도 화상이나 2도 화상이 체표면적 9% 미만을
                차지할 때 보통 경도로 분류합니다.
              </p>
              <p className="subDesc">
                <b>중등도 및 중증 화상</b> 손, 발, 얼굴, 또는 생식기에 입은
                화상, 체표면적의 10%이상 발생한 2도 화상, 체표면적의 1%이상의
                3도 화상입니다.
              </p>
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
};
export default Burn;

const data = [
  {
    pageType: 'PageTable',
    cols: [
      {
        title: '구분',
        dataIndex: 'title',
      },
      {
        title: '대상',
        dataIndex: 'target',
      },
      {
        title: '주기',
        dataIndex: 'cycle',
      },
    ],
    data: [
      {
        title: '지역가입자',
        target: '세대주 및 20세 이상 세대원.',
        cycle: '2년에 1회',
      },
      {
        title: '직장피부양자',
        target: '20세 이상 피부양자.',
        cycle: '2년에 1회',
      },
      {
        title: '직장가입자',
        target: [
          '사무직 근로자 중 격년제 실시에 다른 대상자',
          '비사무직 대상자 전체',
        ],
        cycle: ['사무직 - 2년에 1회', '비사무직 - 1년에 1회'],
      },
      {
        title: '의료급여수급권자',
        target: [
          '19세~63세 의료급여 수급권자',
          '65세 이상은 노인복지법에 의한 검진실시로 제외',
          '*66세 이상은 “의료급여 생애전환기검진” 실시',
        ],
        cycle: '2년에 1회',
      },
    ],
  },
  {
    pageType: 'PageTable',
    style: { width: '900px', margin: '40px auto 0 auto' },
    cols: [
      {
        title: '구분',
        dataIndex: 'title',
        width: '15%',
        onCell: ({ rowSpan }) => ({ rowSpan }),
      },
      { title: '검진항목', dataIndex: 'target', colSpan: 2 },
      { title: '', dataIndex: 'cycle', colSpan: 0 },
    ],
    data: [
      {
        title: '공통항목',
        target:
          '문진 및 체위검사, 흉부방사선 검사, 혈액검사(혈색소, 공복혈당, AST, ALT, γ-GTP, 혈청크레아티닌, e-GFR), 요검사, 구강검진 등',
        cycle: '2년에 1회',
        rowSpan: 1,
      },
      {
        title: '성,연령별 항목',
        target:
          '혈액검사(이상지질혈증 검사) *총콜레스테롤, HDL콜레스테롤, 트리글리세라이드, LDL콜레스테롤',
        cycle: ['남성24세 이상', '여성40세 이상', '4년마다'],
        rowSpan: 8,
      },
      {
        title: '성,연령별 항목',
        target: 'B형간염',
        cycle: '40세',
        rowSpan: 0,
      },
      {
        title: '성,연령별 항목',
        target: '골다공증',
        cycle: '54세, 66세 여성',
        rowSpan: 0,
      },
      {
        title: '성,연령별 항목',
        target: '인지기능장애',
        cycle: '66세 이상(2년에 1회)',
        rowSpan: 0,
      },
      {
        title: '성,연령별 항목',
        target: '우울증',
        cycle: '20, 30, 40, 50, 60, 70세 (해당 연령대에 1회)',
        rowSpan: 0,
      },
      {
        title: '성,연령별 항목',
        target: '생활습관평가',
        cycle: '40, 50, 60, 70세',
        rowSpan: 0,
      },
      {
        title: '성,연령별 항목',
        target: '노인신체기능',
        cycle: '66, 70, 80세',
        rowSpan: 0,
      },
      {
        title: '성,연령별 항목',
        target: '치면세균막검사',
        cycle: '40세',
        rowSpan: 0,
      },
    ],
  },
  {
    pageType: 'PageContNoticeM',
    title: '검진 전 <b>주의사항</b>',
    items: [
      '검진 전날 저녁 9시 이후에는 금식하시기 바랍니다.',
      '검진 당일 아침 식사는 물론 물, 커피, 우유, 담배, 주스, 껌 등 일체의 음식을 삼가야 합니다.',
      '되도록 오전 중에 검진을 받되, 오후에 검진받으실 분은 검사 전 최소 8시간 이상의 공복상태를 유지해야 합니다. (공복이 아닌 상태에서 검진 시 검사결과가 다르게 나타날 수 있음)',
    ],
  },
  {
    pageType: 'PageDescriptions',
    title: '결과통보 및 비용부담',
    items: [
      {
        label: '결과통보',
        texts: [
          '검진결과는 암검진 수검일 이후 15일 이내에 검진받으신 분의 주소지(우편/메일/모바일)로 발송해 드립니다.',
        ],
      },
      {
        label: '비용부담',
        texts: [
          '공단 90%, 수검자 10% 부담',
          '(단, 대장암 및 자궁경부암은 전액 공단 부담)',
          '*국가암건진사업 대상자, 의료급여수급권자는 수검자 부담 없음',
        ],
      },
    ],
  },
];
export default data;

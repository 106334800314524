import { Image, Row, Col } from 'antd';
import { MobileView, BrowserView } from 'react-device-detect';

const Lipo01 = () => {
  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu4/lipo007.png)',
          }}
        >
          <Row align="middle">
            <Col span={12}></Col>
            <Col span={12}>
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>양성종양</b>센터
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>지방종</b>이란?
                </h2>
                <p className="desc">
                  지방종은<b> 지방세포가 커져 발생하는 양성종양</b>
                  입니다.
                </p>
              </Col>
              <Col style={{ width: '400px' }}>
                <p className="subDesc">
                  얇은 막에 둘러싸여 있으며, 보통 피부 바로 밑에서 만져집니다.
                  가장 흔한 혹 중의 하나로, 수 년간 크기가 변하지 않을 수도
                  있고, 서서히 자랄 수도 있습니다.
                </p>
                <p className="subDesc">
                  지방종은 생명을 위협하는 질환은 아니므로 걱정은 안 하셔도
                  됩니다. 하지만, 크기가 계속 커져 미관상 좋지 않을 경우나
                  불편감을 유발할 경우 제거하는 것이 좋습니다.
                </p>
              </Col>
              <Col style={{ width: '600px' }}>
                <Image
                  preview={false}
                  src="/images/menu4/cont1_lipo_circle01.png"
                />
              </Col>
            </Row>
          </div>
          <div className="cont_way bgQuaternary">
            <img
              className="vDescImg"
              alt=""
              src="/images/menu/v_desc_eng.png"
            />
            <Row justify="center" align="middle">
              <Col span={4}>
                <h2>
                  <b>지방종</b>의
                  <br />
                  증상
                </h2>
              </Col>
              <Col span={10}>
                <Row align="middle" justify="end">
                  <Col style={{ display: 'flex', gap: '20px' }}>
                    <p className="recCard label">
                      <img alt="" src="/images/menu4/cont2_lipo_rec01.png" />
                      <p>Lipoma</p>
                    </p>
                    <p className="recCard label">
                      <img alt="" src="/images/menu4/cont2_lipo_rec02.png" />
                      <p>Lipoma</p>
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={14} align="right">
                <p className="desc">
                  피부 위로 무언가 볼록 올라와 보인다.
                  <br />
                  피부 밑으로 고무공같은 말랑한 혹이 만져진다.
                  <br />
                  혹을 만졌을 때 통증이 거의 없다.
                </p>
                <p className="subDesc">* 드물게 있는 경우도 있음</p>
              </Col>
            </Row>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>피지낭종</b>이란?
                </h2>
                <p className="desc">
                  피지낭종은<b> 피부에 생기는 작은 양성종양</b>
                  입니다.
                </p>
              </Col>
              <Col style={{ width: '400px' }}>
                <p className="subDesc">
                  피지선이 막혀 피지가 배출되지 못해 각종 분비물들이 쌓이면서
                  주머니 형태의 피지낭종이 만들어집니다. 지방종보다는 조금 더
                  피부 가까이 위치하며, 시간이 지나면서 서서히 커질 수 있습니다.
                </p>
                <p className="subDesc">
                  보편적으로 통증이 없지만크기가 커져 미용상 보기 싫거나, 감염이
                  되거나, 낭종이 터지게 되면 치료를 해야 합니다.
                </p>
              </Col>
              <Col style={{ width: '600px' }}>
                <Image
                  preview={false}
                  src="/images/menu4/cont1_lipo_circle02.png"
                />
              </Col>
            </Row>
          </div>
          <div className="cont_way bgQuinary">
            <img
              className="vDescImg"
              alt=""
              src="/images/menu/v_desc_eng.png"
            />
            <Row justify="center" align="middle">
              <Col span={4}>
                <h2>
                  <b>피지낭종</b>의
                  <br />
                  증상
                </h2>
              </Col>
              <Col span={10}>
                <Row align="middle" justify="end">
                  <Col style={{ display: 'flex', gap: '20px' }}>
                    <p className="recCard label">
                      <img alt="" src="/images/menu4/cont2_lipo_rec03.png" />
                      <p>Steatocystoma</p>
                    </p>
                    <p className="recCard label">
                      <img alt="" src="/images/menu4/cont2_lipo_rec04.png" />
                      <p>Steatocystoma</p>
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={14} align="right">
                <p className="desc">
                  피부 밑으로 단단한 느낌의 혹인 만져진다. <br /> 혹 중심에 작은
                  구멍이 보인다. <br />
                  짰을 때 악취와 고름이 나온다.
                </p>
              </Col>
            </Row>
          </div>
          <div
            className="cont_way bgImgDark"
            style={{
              backgroundImage: 'url(/images/menu4/cont_bg_01.png)',
              backgroundSize: 'cover',
            }}
          >
            <img
              className="vDescImg"
              alt=""
              src="/images/menu/v_desc_eng.png"
            />
            <Row justify="center" align="middle">
              <Col span={4}>
                <h2>
                  <b>지방종</b>과<br />
                  <b>피지낭종</b>의
                  <br />
                  차이점
                </h2>
              </Col>
              <Col span={10}>
                <Row align="middle" justify="end">
                  <Col style={{ display: 'flex', gap: '20px' }}>
                    <p className="recCard label">
                      <img alt="" src="/images/menu4/cont2_lipo_rec05.png" />
                      <span>
                        <b>지방종</b>
                        피부 아래 지방세포가 과도하게 증식해 혹처럼 만져지는
                        종양입니다. 몸 어디 에나 생길 수 있으며, 공처럼 말랑
                        하게 만져집니다.
                      </span>
                    </p>
                    <p className="recCard label">
                      <img alt="" src="/images/menu4/cont2_lipo_rec06.png" />
                      <span>
                        <b>피지낭종</b>
                        피부의 상피세포가 진피의 피하지방으로 들어가 주머니를
                        만드는 양성종양입니다. 주로 얼굴, 귀, 목, 겨드랑이 등에
                        발생 합니다.
                      </span>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className="cont_way"
            style={{
              backgroundImage: 'url(/images/menu/how_bg.png)',
              backgroundColor: '#d9d9d9',
            }}
          >
            <Row justify="center">
              <Col span={12} align="middle" style={{ paddingTop: '300px' }}>
                <h2>
                  양성종양은{' '}
                  <b>
                    초음파로 크기와 깊이, <br />
                    혈관 등을 파악
                  </b>
                  한 후 <b>수술로 제거</b>합니다.
                </h2>
                <p className="subDescRound">
                  <b>진단</b> 및 <b>치료</b>
                </p>
                <p className="desc">
                  종양이 아주 깊이 위치한 경우가 아니면 대부분 국소마취로
                  간단하게
                  <br />
                  제거할 수 있으며 제거 후 바로 일상생활이 가능합니다.
                </p>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24} align="middle">
                <img
                  className="descImgEnd"
                  alt=""
                  src="/images/menu/desc_eng_w.png"
                />
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top004m.png)',
          }}
        >
          <div className="descWrapM">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>양성종양</b>센터
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>지방종</b>이란?
              </h2>
              <p className="desc">
                지방종은 <b>지방세포가 커져 발생하는 양성종양</b>입니다.
              </p>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={14}>
              <p className="subDesc">
                얇은 막에 둘러싸여 있으며, 보통 피부 바로 밑에서 만져집니다.
                가장 흔한 혹 중의 하나로, 수 년간 크기가 변하지 않을 수도 있고,
                서서히 자랄 수도 있습니다. 지방종은 생명을 위협하는 질환은
                아니므로 걱정은 안 하셔도 됩니다. 하지만, 크기가 계속 커져
                미관상 좋지 않을 경우나 불편감을 유발할 경우 제거하는 것이
                좋습니다.
              </p>
            </Col>
            <Col span={10}>
              <img className="cirImg" alt="" src="/images/mobile/cir006m.png" />
            </Col>
          </Row>
        </div>
        <div className="contWayM" style={{ backgroundColor: '#ffa066' }}>
          <Row justify="center">
            <Col span={24}>
              <img
                className="descImg"
                alt=""
                src="/images/menu/desc_eng_w.png"
              />
            </Col>
            <Col span={24}>
              <h2 className="white">
                <b>지방종</b>의 증상
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <div className="recLabel">
                <img alt="" src="/images/menu4/cont2_lipo_rec01.png" />
                <p>LIPOMA</p>
              </div>
              <div className="recLabel">
                <img alt="" src="/images/menu4/cont2_lipo_rec02.png" />
                <p>LIPOMA</p>
              </div>
            </Col>
            <Col span={16}>
              <p className="subDesc white">
                피부 위로 무언가 볼록 올라와 보인다.
                <br />
                피부 밑으로 고무공같은 말랑한 혹이 만져진다.
                <br />
                혹을 만졌을 때 통증이 거의 없다.
                <br /> <span>* 드물게 있는 경우도 있음</span>
              </p>
            </Col>
          </Row>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>피지낭종</b>이란?
              </h2>
              <p className="desc">
                피지낭종은 <b>지피부에 생기는 작은 양성종양</b>입니다.
              </p>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={14}>
              <p className="subDesc">
                피지선이 막혀 피지가 배출되지 못해 각종 분비물들이 쌓이면서
                주머니 형태의 피지낭종이 만들어집니다. 지방종보다는 조금 더 피부
                가까이 위치하며, 시간이 지나면서 서서히 커질 수 있습니다.
                보편적으로 통증이 없지만크기가 커져 미용상 보기 싫거나, 감염이
                되거나, 낭종이 터지게 되면 치료를 해야 합니다.
              </p>
            </Col>
            <Col span={10}>
              <img className="cirImg" alt="" src="/images/mobile/cir007m.png" />
            </Col>
          </Row>
        </div>
        <div className="contWayM" style={{ backgroundColor: '#ffa3ab' }}>
          <Row justify="center">
            <Col span={24}>
              <img
                className="descImg"
                alt=""
                src="/images/menu/desc_eng_w.png"
              />
            </Col>
            <Col span={24}>
              <h2 className="white">
                <b>피지낭종</b>의 증상
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <div className="recLabel">
                <img alt="" src="/images/menu4/cont2_lipo_rec03.png" />
                <p>STEATOCYSTOMA</p>
              </div>
              <div className="recLabel">
                <img alt="" src="/images/menu4/cont2_lipo_rec04.png" />
                <p>STEATOCYSTOMA</p>
              </div>
            </Col>
            <Col span={16}>
              <p className="subDesc white">
                피부 밑으로 단단한 느낌의 혹인 만져진다.
                <br />혹 중심에 작은 구멍이 보인다. <br />
                짰을 때 악취와 고름이 나온다
              </p>
            </Col>
          </Row>
        </div>
        <div
          className="contWayM"
          style={{ backgroundImage: 'url(/images/menu4/cont_bg_01.png)' }}
        >
          <Row justify="center">
            <Col span={24}>
              <img
                className="descImg"
                alt=""
                src="/images/menu/desc_eng_w.png"
              />
            </Col>
            <Col span={24}>
              <h2 className="white">
                <b>지방종</b>과 <b>피지낭종</b> 의 차이점
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <p className="rec01">
                <img alt="" src="/images/menu4/cont2_lipo_rec05.png" />
                <span>
                  <b>지방종</b>
                  피부 아래 지방세포가 과도하게 증식해 혹처럼 만져지는
                  종양입니다. 몸 어디 에나 생길 수 있으며, 공처럼 말랑 하게
                  만져집니다.
                </span>
              </p>
              <p className="rec02">
                <img alt="" src="/images/menu4/cont2_lipo_rec06.png" />
                <span>
                  <b>피지낭종</b>
                  피부의 상피세포가 진피의 피하지방으로 들어가 주머니를 만드는
                  양성종양입니다. 주로 얼굴, 귀, 목, 겨드랑이 등에 발생 합니다.
                </span>
              </p>
            </Col>
          </Row>
        </div>
        <div
          className="contWayM"
          style={{
            backgroundColor: '#d9d9d9',
          }}
        >
          <img className="causeBg01" alt="" src="/images/mobile/bg002m.png" />
          <Row justify="center">
            <Col span={20}>
              <h2>
                양성종양은 <b>초음파로 크기와 깊이, 혈관</b>
                등을 파악 한 후 <b>수술로 제거</b>합니다.
              </h2>
            </Col>
            <Col span={24}>
              <p className="subDescRound">
                <b>진단</b> 및 <b>치료</b>
              </p>
            </Col>
            <Col span={20}>
              <p className="subDesc">
                종양이 아주 깊이 위치한 경우가 아니면 대부분 국소마취로 간단하게
                제거할 수 있으며 제거 후 바로 일상생활이 가능합니다.
              </p>
            </Col>
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
};
export default Lipo01;

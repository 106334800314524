import { Row, Col, Image } from 'antd';
import { MobileView, BrowserView } from 'react-device-detect';

const Hemorrhoids = () => {
  return (
    <>
      <BrowserView>
        <div
          className="pageTop animation"
          style={{
            backgroundImage: 'url(/images/menu2/hem000.png)',
          }}
        >
          <Row align="middle">
            <Col span={12}></Col>
            <Col span={12}>
              <p className="desc">
                <b>시흥드림</b>항유외과
              </p>
              <h2>
                <b>대장항문</b>클리닉
              </h2>
              <p className="subDesc">
                20년 경력의 외과전문의가 체계적인 진단과 치료로
                <br />
                당신의 건강을 지켜드립니다.
              </p>
            </Col>
          </Row>
        </div>
        <div className="content">
          <div className="contBox">
            <div className="recRotate toRight">
              <img alt="" src="/images/menu/rec_circle_text.png" />
            </div>
          </div>
          <div className="cont_isIt">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng.png"
                />
                <h2>
                  <b>치질</b>(<b>치핵</b>)이란?
                </h2>
                <p className="desc">
                  <b>치질</b>은 <b>항문에 발생하는 모든 질환</b>을 의미합니다.
                </p>
              </Col>
              <Col style={{ width: '400px' }}>
                <p className="subDesc">
                  이 중 치핵이 가장 흔한 질환이라 치질과 혼동 되어 쓰이지만
                  치핵이 정확한 용어입니다.
                </p>
                <p className="subDesc">
                  항문 안쪽에는 가스나 변이 새지 않도록 막아 주고, 대변이
                  부드럽게 나오도록 충격을 흡수 해주는 항문쿠션조직이 있습니다.
                </p>
                <p className="subDesc">
                  이 항문쿠션 조직이 부풀고 늘어나 항문밖으로 돌출되는 것을
                  치핵이라고 합니다.
                </p>
              </Col>
              <Col style={{ width: '600px' }}>
                <Image
                  preview={false}
                  src="/images/menu2/cont1_hem_circle01.png"
                />
              </Col>
            </Row>
          </div>
          <div className="cont_step">
            <Row justify="center" align="middle">
              <Col span={24} align="middle">
                <img
                  className="descImg"
                  alt=""
                  src="/images/menu/desc_eng_w.png"
                />
                <h2>
                  <b>치핵</b>의 단계
                </h2>
              </Col>
              <Col span={24}>
                <Row justify="center">
                  <Col style={{ display: 'flex', gap: '20px' }}>
                    <div className="stepRec">
                      <span>1도</span>
                      <Image
                        preview={false}
                        src="/images/menu2/hem_cont_001.png"
                      />
                      <p>배변 시 출혈이 있지만 치핵이 탈출하지 않는 경우</p>
                    </div>
                    <div className="stepRec">
                      <span>2도</span>
                      <Image
                        preview={false}
                        src="/images/menu2/hem_cont_002.png"
                      />
                      <p>
                        배변 시 항문 밖으로 탈출한 치핵이 저절로 들어가는 경우
                      </p>
                    </div>
                    <div className="stepRec">
                      <span>3도</span>
                      <Image
                        preview={false}
                        src="/images/menu2/hem_cont_003.png"
                      />
                      <p>
                        배변 시 탈출한 치핵을 손가락 으로 밀어넣어야 들어가는
                        경우
                      </p>
                    </div>
                    <div className="stepRec">
                      <span>4도</span>
                      <Image
                        preview={false}
                        src="/images/menu2/hem_cont_004.png"
                      />
                      <p>
                        배변 시 탈출한 치핵을 손가락 으로 밀어넣어도 들어가지
                        않는 경우
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className="cont_cause"
            style={{
              backgroundImage: 'url(/images/menu2/cause_bg.png)',
              backgroundColor: '#ffe3d3',
            }}
          >
            <img
              className="vDescImg"
              alt=""
              src="/images/menu/v_desc_eng.png"
            />
            <Row justify="center">
              <Col span={16}>
                <Row justify="start" style={{ height: '500px' }}>
                  <Col>
                    <h2>
                      <b>치질</b>(<b>치핵</b>)의
                      <br /> <b>원인</b>
                    </h2>
                  </Col>
                </Row>
                <Row
                  gutter={20}
                  justify="center"
                  align="top"
                  style={{ display: 'flex' }}
                >
                  <Col span={6}>
                    <div className="causeRec">
                      <span>잘못된 자세</span>
                      <p>
                        배변 시 힘을 과하게 주거나 장시간 같은 자세나 쪼그려
                        앉는 자세가 반복되면 항문압이 높아져 치핵이 발생될 수
                        있습니다.
                      </p>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="causeRec">
                      <span>임신</span>
                      <p>
                        여성의 경우 임신 및 출산 시 복부 및 골반의 혈액순환이
                        원활하지 못해 항문 주위 혈관이 정체 되거나 울혈이 발생
                        하기 쉬워 치핵이 생기거나 악화되는 경우가 많습니다.
                      </p>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="causeRec">
                      <span>노화</span>
                      <p>
                        나이가 들어감에 따라 항문 근육의 탄력이 떨어져 항문
                        혈관이 확장되어 치핵이 발생하는 경우가 있습니다.
                      </p>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="causeRec">
                      <span>음주 및 식습관</span>
                      <p>
                        알코올이 혈관을 확장시키고 염증반응도 증가시켜 치핵이
                        쉽게 악화될 수 있으며, 잘못된 식습관으로 섬유질 섭취가
                        줄고, 과체중이 될 경우 치핵이 발생할 수 있습니다.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className="cont_way"
            style={{
              backgroundImage: 'url(/images/menu/how_bg.png)',
              backgroundColor: '#d9d9d9',
            }}
          >
            <Row justify="center">
              <Col span={12} align="middle" style={{ paddingTop: '300px' }}>
                <h2>
                  <b>치질</b>(<b>치핵</b>)의 치료방법
                </h2>
                <p className="subDescRound">
                  <b>보존적</b> 치료
                </p>
              </Col>
            </Row>
            <Row justify="center">
              <Col style={{ display: 'flex' }}>
                <div className="wayCir">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon01.png)',
                    }}
                  ></span>
                  <p>온수좌욕</p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                  <div
                    className="plusIcon"
                    style={{
                      backgroundImage: 'url(/images/menu/way_plus.png)',
                    }}
                  />
                </div>
                <div className="wayCir">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon02.png)',
                    }}
                  ></span>
                  <p>배변 습관 개선 및 식이요법</p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                  <div
                    className="plusIcon"
                    style={{
                      backgroundImage: 'url(/images/menu/way_plus.png)',
                    }}
                  />
                </div>
                <div className="wayCir">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon03.png)',
                    }}
                  ></span>
                  <p>소염진통과 항균작용을 하는 좌약 및 연고제</p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                  <div
                    className="plusIcon"
                    style={{
                      backgroundImage: 'url(/images/menu/way_plus.png)',
                    }}
                  />
                </div>
                <div className="wayCir">
                  <span
                    className="icon"
                    style={{
                      backgroundImage: 'url(/images/menu2/way_ana_icon04.png)',
                    }}
                  ></span>
                  <p>변을 부드럽게 하는 변비약과 혈액순환개선제 등</p>
                  <div
                    className="cirRotate"
                    style={{
                      backgroundImage:
                        'url(/images/menu/way_ana_icon_rotate.png)',
                    }}
                  />
                </div>
              </Col>
              <Col span={24} align="middle">
                <img
                  className="descImgEnd"
                  alt=""
                  src="/images/menu/desc_eng_w.png"
                />
              </Col>
            </Row>
          </div>
          <div
            className="cont_care"
            style={{
              backgroundImage: 'url(/images/menu/care_bg.png)',
            }}
          >
            <Row justify="center">
              <Col span={24} align="middle">
                <img
                  alt=""
                  className="descImg"
                  src="/images/menu/desc_eng_w.png"
                />
                <Row justify="center">
                  <Col
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '30px',
                    }}
                  >
                    <div className="careText">
                      <em>수술적치료 1.</em>
                      <span>
                        점막하
                        <br />
                        <font>
                          <b>치핵</b>절제술
                        </font>
                      </span>
                      <p>
                        항문의 점막을 조금 절제한 후 안쪽으로 치핵을 제거하는
                        방법 으로 수술이 까다롭고 많은 시간이 필요하지만 수술 후
                        점막이 그대로 남아있고 항문에 발생할 수 있는 협착, 출혈
                        등의 합병증이 적음.
                      </p>
                    </div>
                    <div
                      className="careCir"
                      style={{
                        backgroundImage:
                          'url(/images/menu2/care_hem_cir01.png)',
                      }}
                    >
                      <div
                        className="careCirOut"
                        style={{
                          backgroundImage:
                            'url(/images/menu/care_hem_cir02.png)',
                        }}
                      />
                      <div
                        className="careCirRotate"
                        style={{
                          backgroundImage:
                            'url(/images/menu/care_hem_cir03_rotate.png)',
                        }}
                      />
                    </div>
                    <div className="careText">
                      <em>수술적치료 2.</em>
                      <span>
                        <font>
                          <b>PPH</b>
                        </font>
                        <br />
                        (원형<font>자동문합기</font>)
                      </span>
                      <p>
                        원형자동문합기를 이용하여 치상선 아래쪽으로 늘어진 항문
                        점막을 치상선 안쪽으로 끌어당겨 치핵에 들어가는 혈관을
                        차단해서 치핵 조직을 위축시키는 방법 입니다.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div
          className="pageTopM animation"
          style={{
            backgroundImage: 'url(/images/mobile/top001m.png)',
          }}
        >
          <div className="descWrapM">
            <p className="desc">
              <b>시흥드림</b>항유외과
            </p>
            <h2>
              <b>대장항문</b>클리닉
            </h2>
          </div>
        </div>
        <div className="contIsitM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>치질</b>이란?
              </h2>
              <p className="desc">
                <b>치질</b>은 <b>항문에 발생하는 모든 질환</b>을 의미합니다.
              </p>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={14}>
              <p className="subDesc">
                이 중 치핵이 가장 흔한 질환이라 치질과 혼동 되어 쓰이지만 치핵이
                정확한 용어입니다. 항문 안쪽에는 가스나 변이 새지 않도록 막아
                주고, 대변이 부드럽게 나오도록 충격을 흡수 해주는 항문쿠션조직이
                있습니다. 이 항문쿠션 조직이 부풀고 늘어나 항문밖으로 돌출되는
                것을 치핵이라고 합니다.
              </p>
            </Col>
            <Col span={10}>
              <img className="cirImg" alt="" src="/images/mobile/cir001m.png" />
            </Col>
          </Row>
        </div>
        <div className="contStepM">
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>치핵</b>의 단계
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col style={{ display: 'flex' }}>
              <div className="stepRec">
                <span>1도</span>
                <img alt="" src="/images/menu2/hem_cont_001.png" />
                <p>배변 시 출혈이 있지만 치핵이 탈출하지 않는 경우</p>
              </div>
              <div className="stepRec">
                <span>2도</span>
                <img alt="" src="/images/menu2/hem_cont_002.png" />
                <p>배변 시 항문 밖으로 탈출한 치핵이 저절로 들어가는 경우</p>
              </div>
            </Col>
            <Col style={{ display: 'flex' }}>
              <div className="stepRec">
                <span>3도</span>
                <img alt="" src="/images/menu2/hem_cont_003.png" />
                <p>
                  배변 시 탈출한 치핵을 손가락 으로 밀어넣어야 들어가는 경우
                </p>
              </div>
              <div className="stepRec">
                <span>4도</span>
                <img alt="" src="/images/menu2/hem_cont_004.png" />
                <p>
                  배변 시 탈출한 치핵을 손가락 으로 밀어넣어도 들어가지 않는
                  경우
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="contCauseM"
          style={{
            backgroundColor: '#ffe3d3',
          }}
        >
          <img className="causeBg01" alt="" src="/images/mobile/bg001m.jpg" />
          <Row justify="center">
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
            <Col span={24}>
              <h2>
                <b>치질</b>(<b>치핵</b>)의 <b>원인</b>
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col style={{ display: 'flex' }}>
              <div className="causeRec">
                <span>잘못된 자세</span>
                <p>
                  배변 시 힘을 과하게 주거나 장시간 같은 자세나 쪼그려 앉는
                  자세가 반복되면 항문압이 높아져 치핵이 발생될 수 있습니다.
                </p>
              </div>
              <div className="causeRec">
                <span>임신</span>
                <p>
                  여성의 경우 임신 및 출산 시 복부 및 골반의 혈액순환이 원활하지
                  못해 항문 주위 혈관이 정체 되거나 울혈이 발생 하기 쉬워 치핵이
                  생기거나 악화되는 경우가 많습니다.
                </p>
              </div>
            </Col>
            <Col style={{ display: 'flex' }}>
              <div className="causeRec">
                <span>노화</span>
                <p>
                  나이가 들어감에 따라 항문 근육의 탄력이 떨어져 항문 혈관이
                  확장되어 치핵이 발생하는 경우가 있습니다.
                </p>
              </div>
              <div className="causeRec">
                <span>음주 및 식습관</span>
                <p>
                  알코올이 혈관을 확장시키고 염증반응도 증가시켜 치핵이 쉽게
                  악화될 수 있으며, 잘못된 식습관으로 섬유질 섭취가 줄고,
                  과체중이 될 경우 치핵이 발생할 수 있습니다.
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="contWayM"
          style={{
            backgroundColor: '#d9d9d9',
          }}
        >
          <img className="causeBg01" alt="" src="/images/mobile/bg002m.png" />
          <Row justify="center">
            <Col span={24}>
              <h2>
                <b>치질</b>(<b>치핵</b>)의 치료방법
              </h2>
            </Col>
            <Col span={24}>
              <p className="subDescRound">
                <b>보존적</b> 치료
              </p>
            </Col>
          </Row>
          <Row justify="center">
            <Col style={{ display: 'flex' }}>
              <div className="wayCir">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon01.png)',
                  }}
                ></span>
                <p>온수좌욕</p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
                <div
                  className="plusIcon"
                  style={{
                    backgroundImage: 'url(/images/menu/way_plus.png)',
                  }}
                />
              </div>
              <div className="wayCir">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon02.png)',
                  }}
                ></span>
                <p>배변 습관 개선 및 식이요법</p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
              </div>
            </Col>
            <Col style={{ display: 'flex' }}>
              <div className="wayCir">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon03.png)',
                  }}
                ></span>
                <p>소염진통과 항균작용을 하는 좌약 및 연고제</p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
                <div
                  className="plusIcon"
                  style={{
                    backgroundImage: 'url(/images/menu/way_plus.png)',
                  }}
                />
              </div>
              <div className="wayCir">
                <span
                  className="icon"
                  style={{
                    backgroundImage: 'url(/images/menu2/way_ana_icon04.png)',
                  }}
                ></span>
                <p>변을 부드럽게 하는 변비약과 혈액순환개선제 등</p>
                <div
                  className="cirRotate"
                  style={{
                    backgroundImage:
                      'url(/images/menu/way_ana_icon_rotate.png)',
                  }}
                />
              </div>
            </Col>
            <Col span={24}>
              <img className="descImg" alt="" src="/images/menu/desc_eng.png" />
            </Col>
          </Row>
        </div>
        <div className="contCareM">
          <Row justify="center" align="middle">
            <Col span={24}>
              <img
                className="descImg"
                alt=""
                src="/images/menu/desc_eng_w.png"
              />
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Col>
                  <div
                    className="careCir"
                    style={{
                      backgroundImage: 'url(/images/menu2/care_hem_cir01.png)',
                    }}
                  >
                    <div
                      className="careCirOut"
                      style={{
                        backgroundImage: 'url(/images/menu/care_hem_cir02.png)',
                      }}
                    />
                    <div
                      className="careCirRotate"
                      style={{
                        backgroundImage:
                          'url(/images/menu/care_hem_cir03_rotate.png)',
                      }}
                    />
                  </div>
                </Col>
                <Col span={24} style={{ display: 'flex' }}>
                  <div className="careText">
                    <em>수술적치료 1.</em>
                    <span>
                      점막하
                      <br />
                      <font>
                        <b>치핵</b>절제술
                      </font>
                    </span>
                    <p>
                      항문의 점막을 조금 절제한 후 안쪽으로 치핵을 제거하는 방법
                      으로 수술이 까다롭고 많은 시간이 필요하지만 수술 후 점막이
                      그대로 남아있고 항문에 발생할 수 있는 협착, 출혈 등의
                      합병증이 적음.
                    </p>
                  </div>
                  <div className="careText">
                    <em>수술적치료 2.</em>
                    <span>
                      <font>
                        <b>PPH</b>
                      </font>
                      <br />
                      (원형<font>자동문합기</font>)
                    </span>
                    <p>
                      원형자동문합기를 이용하여 치상선 아래쪽으로 늘어진 항문
                      점막을 치상선 안쪽으로 끌어당겨 치핵에 들어가는 혈관을
                      차단해서 치핵 조직을 위축시키는 방법 입니다.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </MobileView>
    </>
  );
};
export default Hemorrhoids;
